import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

import { ReactComponent as Settings360Icon } from '../../assets/icons/gear-fill.svg';
import { ReactComponent as SettingsBatteries } from '../../assets/icons/settings-batteries.svg';
import { ReactComponent as Account } from '../../assets/icons/account.svg';
import { ReactComponent as Templates } from '../../assets/icons/360p.svg';
import { ReactComponent as TutorialsIcon } from '../../assets/icons/file-earmark-play-fill.svg';
import { ReactComponent as Enterprise } from '../../assets/icons/enterprise.svg';

import isChineseEnv from '../../utils/isChineseEnv';

const StyledTemplatesIcon = styled(Templates)`
  width: 2rem;
  height: 2rem;
`;

const SETTINGS_MENU = (user) => [
  // Translated
  {
    label: 'SAssessments',
    Icon: SettingsBatteries,
    to: 'custom-competencies',
    nested: [
      {
        label: 'Assessments and batteries',
        to: 'assessments-and-batteries',
      },
      ...(user?.userType === 3
        ? []
        : [
            {
              label: 'Ideal Profiles',
              to: 'ideal-profiles',
            },
            {
              label: 'Norms',
              to: 'norms',
            },
          ]),
    ],
  },
  {
    label: '360 settings',
    Icon: Settings360Icon,
    to: 'custom-competencies',
    nested: [
      {
        label: 'Competencies',
        to: 'custom-competencies',
      },
      {
        label: '360 Surveys',
        to: '360-assessments',
      },
      {
        label: 'Response Scales',
        to: 'response-scales',
      },
    ],
  },
  {
    label: 'Templates',
    Icon: StyledTemplatesIcon,
    to: 'templates',
    nested: [
      {
        label: 'Email templates',
        to: 'templates',
      },
      {
        label: 'Assessment welcome texts',
        to: 'welcome-texts',
      },
      {
        label: I18n.t('Biodata Configuration'),
        to: 'biodata-config',
      },
    ],
  },
  {
    label: 'Account',
    Icon: Account,
    to: 'templates',
    nested: [
      {
        label: I18n.t('General'),
        to: 'general',
      },
      ...(user?.userType === 3
        ? []
        : [
            {
              label: I18n.t('Credit Updates'),
              to: 'credits-history',
            },
            {
              label: I18n.t('Reports Stats'),
              to: 'reports-usage',
            },
          ]),
      {
        label: I18n.t('Assessments Stats'),
        to: 'assessment-usage',
      },
      {
        label: I18n.t('Activity Log'),
        to: 'activity-log',
      },
    ],
  },
  ...(isChineseEnv
    ? []
    : [
        {
          label: 'Tutorials',
          Icon: TutorialsIcon,
          to: 'tutorials',
          nested: [
            {
              label: 'Watch tutorials',
              to: 'tutorials',
            },
          ],
        },
      ]),
  {
    label: 'About',
    Icon: Enterprise,
    to: 'about',
    nested: [
      {
        label: 'Ownership',
        to: 'about',
      },
      {
        label: 'FAQs',
        to: 'faqs',
      },
    ],
  },
];

export default SETTINGS_MENU;
