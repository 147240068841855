/* eslint-disable import/no-cycle */
import { createAction } from 'redux-actions';
import { I18n } from 'react-redux-i18n';

import apiInstance2 from '../../services/apiService';
import storageService from '../../services/storageService';

import generateQueryString from '../generateQueryString';
import respondentsColumns from '../../table/columns/respondents';
import { fetchTags, fetchUser } from '../user/actions';

import createToastNotification from '../../utils/createToastNotification';
import defaultTableState from '../../constants/defaultTableState';

// ----------------- FILTERS ----------------------
export const setRespondentsSearchValue = createAction('SET_RESPONDENTS_SEARCH_VALUE', (searchValue) => ({
  searchValue,
}));

export const setRespondentsFilterValue = createAction('SET_RESPONDENTS_FILTER_VALUE', (fieldName, value) => ({
  fieldName,
  value,
}));

export const setRespondentsFilterType = createAction('SET_RESPONDENTS_FILTER_TYPE', (fieldName, filterType) => ({
  fieldName,
  filterType,
}));

export const deleteRespondentsAppliedFilter = createAction('DELETE_RESPONDENTS_APPLIED_FILTER', (fieldName) => ({
  fieldName,
}));

export const applyRespondentsFilters = createAction('APPLY_RESPONDENTS_FILTERS');
export const clearRespondentsFilters = createAction('CLEAR_RESPONDENTS_FILTERS');
export const resetRespondentsTableState = createAction('RESET_RESPONDENTS_TABLE_STATE');

export const onTestComplete = createAction('ON_TEST_COMPLETE', (id, test) => ({ id, test }));

// ---------  NETWORK RELATED ----------
export const fetchRespondentsStart = createAction('FETCH_RESPONDENTS_START');
export const fetchRespondentsSuccess = createAction('FETCH_RESPONDENTS_SUCCESS', (data, tableParams, shouldReset) => ({
  data,
  tableParams,
  shouldReset,
}));
export const fetchRespondentsFailure = createAction('FETCH_RESPONDENTS_FAILURE');

export const fetchAvailableReportsStart = createAction('FETCH_AVAILABLE_REPORTS_START');
export const fetchAvailableReportsSuccess = createAction('FETCH_AVAILABLE_REPORTS_SUCCESS', (reportSets, reports) => ({
  reportSets,
  reports,
}));
export const fetchAvailableReportsFailure = createAction('FETCH_AVAILABLE_REPORTS_FAILURE');

export const fetchAssessmentsNormsStart = createAction('FETCH_NORMS_BY_TEST_ID_START');
export const fetchAssessmentsNormsSuccess = createAction('FETCH_NORMS_BY_TEST_ID_SUCCESS', (norms) => ({ norms }));
export const fetchAssessmentsNormsFailure = createAction('FETCH_NORMS_BY_TEST_ID_FAILURE');

export const addRespondentStart = createAction('ADD_RESPONDENT_START');
export const addRespondentSuccess = createAction('ADD_RESPONDENT_SUCCESS', (respondent) => ({ respondent }));
export const addRespondentFailure = createAction('ADD_RESPONDENT_FAILURE');

export const deleteRespondentsStart = createAction('DELETE_RESPONDENTS_START');
export const deleteRespondentsSuccess = createAction('DELETE_RESPONDENTS_SUCCESS', (respondentsIds) => ({
  respondentsIds,
}));
export const deleteRespondentsFailure = createAction('DELETE_RESPONDENTS_FAILURE');

export const deleteRespondentResultsStart = createAction('DELETE_RESPONDENT_RESULTS_START');
export const deleteRespondentResultsSuccess = createAction('DELETE_RESPONDENT_RESULTS_SUCCESS');
export const deleteRespondentResultsFailure = createAction('DELETE_RESPONDENT_RESULTS_FAILURE');

export const setSelectedRespondents = createAction('SET_SELECTED_RESPONDENTS', (respondents) => ({
  respondents,
}));

export const editRespondentStart = createAction('EDIT_RESPONDENT_START');
export const editRespondentFailure = createAction('EDIT_RESPONDENT_FAILURE');
export const editRespondentSuccess = createAction('EDIT_RESPONDENT_SUCCESS', (values) => ({ values }));

export const addTagsToRespondentsStart = createAction('ADD_TAGS_TO_RESPONDENTS_START');
export const addTagsToRespondentsSuccess = createAction('ADD_TAGS_TO_RESPONDENTS_SUCCESS');
export const addTagsToRespondentsFailure = createAction('ADD_TAGS_TO_RESPONDENTS_FAILURE');

export const removeTagsFromRespondentsStart = createAction('REMOVE_TAGS_FROM_RESPONDENTS_START');
export const removeTagsFromRespondentsSuccess = createAction('REMOVE_TAGS_FROM_RESPONDENTS_SUCCESS');
export const removeTagsFromRespondentsFailure = createAction('REMOVE_TAGS_FROM_RESPONDENTS_FAILURE');

export const addTagsToRespondentStart = createAction('ADD_TAGS_TO_RESPONDENT_START');
export const addTagsToRespondentSuccess = createAction('ADD_TAGS_TO_RESPONDENT_SUCCESS');
export const addTagsToRespondentFailure = createAction('ADD_TAGS_TO_RESPONDENT_FAILURE');

export const deleteTagsStart = createAction('DELETE_TAGS_START');
export const deleteTagsSuccess = createAction('DELETE_TAGS_SUCCESS', (tags) => ({ tags }));
export const deleteTagsFailure = createAction('DELETE_TAGS_FAILURE');

export const addRespondentsToGroupStart = createAction('ADD_RESPONDENTS_TO_GROUP_START');
export const addRespondentsToGroupSuccess = createAction('ADD_RESPONDENTS_TO_GROUP_SUCCESS');
export const addRespondentsToGroupFailure = createAction('ADD_RESPONDENTS_TO_GROUP_FAILURE');

export const createQuestionnaireSessionStart = createAction('CREATE_QUESTIONNAIRE_SESSION_START');
export const createQuestionnaireSessionSuccess = createAction('CREATE_QUESTIONNAIRE_SESSION_SUCCESS');
export const createQuestionnaireSessionFailure = createAction('CREATE_QUESTIONNAIRE_SESSION_FAILURE');

export const changeShouldResetRespondentsTableBoolState = createAction(
  'CHANGE_SHOULD_RESET_RESPONDENTS_TABLE_BOOL_STATE',
  (state) => ({ state }),
);

export const fetchRespondentByIdStart = createAction('FETCH_RESPONDENT_BY_ID_START');
export const fetchRespondentByIdSuccess = createAction('FETCH_RESPONDENT_BY_ID_SUCCESS');
export const fetchRespondentByIdFailure = createAction('FETCH_RESPONDENT_BY_ID_FAILURE');

export const fetchRespondentInvitesStart = createAction('FETCH_RESPONDENT_INVITES_START');
export const fetchRespondentInvitesSuccess = createAction('FETCH_RESPONDENT_INVITES_SUCCESS', (invites) => ({
  invites,
}));
export const fetchRespondentInvitesFailure = createAction('FETCH_RESPONDENT_INVITES_FAILURE');

export const getRespondentsCountStart = createAction('GET_RESPONDENTS_COUNT_START');
export const getRespondentsCountFailure = createAction('GET_RESPONDENTS_COUNT_FAILURE');
export const getRespondentsCountSuccess = createAction('GET_RESPONDENTS_COUNT_SUCCESS', (data) => ({ data }));

export const removeRespondentsFromGroupStart = createAction('REMOVE_RESPONDENTS_FROM_GROUP_START');
export const removeRespondentsFromGroupSuccess = createAction('REMOVE_RESPONDENTS_FROM_GROUP_SUCCESS');
export const removeRespondentsFromGroupFailure = createAction('REMOVE_RESPONDENTS_FROM_GROUP_FAILURE');

export const unassignRespondentFromGroupsStart = createAction('UNASSIGN_RESPONDENT_FROM_GROUPS_START');
export const unassignRespondentFromGroupsSuccess = createAction('UNASSIGN_RESPONDENT_FROM_GROUPS_SUCCESS');
export const unassignRespondentFromGroupsFailure = createAction('UNASSIGN_RESPONDENT_FROM_GROUPS_FAILURE');

export const fetchAnswerSheetTestsInfoStart = createAction('FETCH_ANSWER_SHEET_TESTS_INFO_START');
export const fetchAnswerSheetTestsInfoSuccess = createAction('FETCH_ANSWER_SHEET_TESTS_INFO_SUCCESS', (data) => ({
  data,
}));
export const fetchAnswerSheetTestsInfoFailure = createAction('FETCH_ANSWER_SHEET_TESTS_INFO_FAILURE');

export const setRespondentToEdit = createAction('SET_RESPONDENT_TO_EDIT', (data) => ({ data }));

export const updateRespondentRow = createAction('UPDATE_RESPONDENT_ROW', (data) => ({ respondent: data }));

export const fetchRespondents = (tableState = defaultTableState, { callback, shouldReset }) => async (dispatch) => {
  try {
    dispatch(fetchRespondentsStart());
    const finalObj = { ...tableState };
    if (finalObj.sortBy) {
      const sortingObj = finalObj.sortBy[0];
      const ColumnIndex = respondentsColumns.findIndex(
        (item) =>
          (typeof item.Header === 'function' ? item.stringHeader : item.Header) === sortingObj.id ||
          item.name === sortingObj.id,
      );
      const Order = sortingObj.desc ? 1 : 0;
      finalObj.sortBy = { ColumnIndex, Order };
    }
    const queryString = generateQueryString(finalObj, respondentsColumns);
    const response = await apiInstance2.get(`/api/v2/respondents?${queryString}`);
    if (response.status === 200) {
      dispatch(fetchRespondentsSuccess(response.data, tableState, shouldReset));
      if (callback) callback();
    }
  } catch (e) {
    dispatch(fetchRespondentsFailure(e));
    if (callback) callback(e);
  }
};

export const fetchRespondentsForGroup = (groupId, tableState = defaultTableState, { callback, shouldReset }) => async (
  dispatch,
) => {
  try {
    dispatch(fetchRespondentsStart());
    const finalObj = { ...tableState };
    if (finalObj.sortBy) {
      const sortingObj = finalObj.sortBy[0];
      const ColumnIndex = respondentsColumns.findIndex(
        (item) => item.Header === sortingObj.id || item.name === sortingObj.id,
      );
      const Order = sortingObj.desc ? 1 : 0;
      finalObj.sortBy = { ColumnIndex, Order };
    }
    const queryString = generateQueryString(finalObj, respondentsColumns);
    const response = await apiInstance2.get(`/api/v2/groups/${groupId}/respondents`);
    if (response.status === 200) {
      dispatch(fetchRespondentsSuccess(response.data, tableState, shouldReset));
      if (callback) callback();
    }
  } catch (e) {
    dispatch(fetchRespondentsFailure(e));
    if (callback) callback(e);
  }
};

export const fetchRespondentById = (id, cb) => async (dispatch) => {
  dispatch(fetchRespondentByIdStart());
  try {
    const response = await apiInstance2.get(`/api/v2/respondents/${id}`);
    if (response.status === 200) {
      dispatch(fetchRespondentByIdSuccess());
      dispatch(setRespondentToEdit(response.data));
      if (cb) cb();
    }
  } catch (err) {
    dispatch(fetchRespondentByIdFailure(err.message));
    if (cb) cb(err);
  }
};

export const fetchRespondentInvites = (respondentId, showExpired, cb) => async (dispatch) => {
  dispatch(fetchRespondentInvitesStart());
  try {
    const response = await apiInstance2.get(
      `/api/v2/respondents/${respondentId}/invites?skipCompleted=true&skipExpired=${!showExpired}`,
    );
    if (response.status === 200) {
      dispatch(fetchRespondentInvitesSuccess(response.data));
      if (cb) cb();
    }
  } catch (err) {
    dispatch(fetchRespondentInvitesFailure(err.message));
    if (cb) cb(err);
  }
};

export const addRespondent = (respondent) => async (dispatch) => {
  dispatch(addRespondentStart());
  try {
    const response = await apiInstance2.post(`/api/v2/respondents`, respondent);
    if (response.status === 200) {
      dispatch(addRespondentSuccess());
      createToastNotification({ message: I18n.t('respondentCreationToast') });
    }
  } catch (err) {
    dispatch(addRespondentFailure(err.message));
  }
};

export const editRespondent = (id, data, cb) => async (dispatch) => {
  dispatch(editRespondentStart());
  try {
    const response = await apiInstance2.patch(`/api/v2/respondents/${id}`, data);
    if (response.status === 200) {
      dispatch(editRespondentSuccess(data));
      createToastNotification({ message: I18n.t('respondentUpdateToast') });
      cb();
    }
  } catch (err) {
    dispatch(editRespondentFailure(err.message));
    cb(err);
  }
};

export const deleteRespondents = (respondentsIds, cb) => async (dispatch) => {
  dispatch(deleteRespondentsStart());
  try {
    const response = await apiInstance2.delete(`/api/v2/respondents`, { data: respondentsIds });
    if (response.status === 200) {
      dispatch(deleteRespondentsSuccess(respondentsIds));
      createToastNotification({ message: I18n.t('respondentsDeletionToast') });
    }
  } catch (err) {
    dispatch(deleteRespondentsFailure(err.message));
  } finally {
    cb();
  }
};

export const deleteRespondentResults = (data, respondentId, cb) => async (dispatch) => {
  dispatch(deleteRespondentResultsStart());
  try {
    const response = await apiInstance2.delete(`/api/v2/results`, { data });
    if (response.status === 200) {
      dispatch(deleteRespondentResultsSuccess());
      createToastNotification({ message: I18n.t('resultsDeletionToast') });
      if (respondentId) dispatch(fetchRespondentById(respondentId));
      cb();
    }
  } catch (err) {
    dispatch(deleteRespondentResultsFailure(err.message));
    cb(err);
  }
};

export const fetchAvailableReports = (tests, language, respondentsIds, cb) => async (dispatch) => {
  dispatch(fetchAvailableReportsStart());
  const respondentsQueryPart = respondentsIds.reduce(
    (acc, item, i) => `${acc}respondentIds=${encodeURIComponent(item)}${i === respondentsIds.length - 1 ? '' : '&'}`,
    '',
  );
  const testsQueryPart = tests.reduce(
    (acc, item, i) => `${acc}testIds=${encodeURIComponent(item)}${i === tests.length - 1 ? '' : '&'}`,
    '',
  );
  const query = `${testsQueryPart}&${respondentsQueryPart}&langId=${language ? language.code : 'EN'}`;
  try {
    const response = await apiInstance2.get(`/api/v2/reports/available?${query}`);
    if (response.status === 200) {
      dispatch(fetchAvailableReportsSuccess(response.data.reportSets, response.data.reports));
      dispatch(fetchUser());
      if (cb) cb();
    }
  } catch (err) {
    dispatch(fetchAvailableReportsFailure(err.message));
    if (cb) cb();
  }
};
export const clearAvailableReports = createAction('CLEAR_AVAILABLE_REPORTS');

export const fetchAssessmentsNorms = (testIds) => async (dispatch) => {
  dispatch(fetchAssessmentsNormsStart());
  try {
    const query = testIds.reduce(
      (acc, item, i) => `${acc}testIds=${encodeURIComponent(item)}${i === testIds.length - 1 ? '' : '&'}`,
      '',
    );
    const response = await apiInstance2.get(`/api/v2/tests/norms?${query}`);
    if (response.status === 200) {
      dispatch(fetchAssessmentsNormsSuccess(response.data));
    }
  } catch (err) {
    dispatch(fetchAssessmentsNormsFailure(err.message));
  }
};

export const addTagsToRespondents = (data, cb) => async (dispatch) => {
  dispatch(addTagsToRespondentsStart());
  try {
    const response = await apiInstance2.post(`/api/v2/respondents/tags`, data);
    if (response.status === 200) {
      dispatch(addTagsToRespondentsSuccess());
      dispatch(fetchTags());
      createToastNotification({ message: I18n.t('Tag(s) have been updated') });
      cb();
    }
  } catch (err) {
    dispatch(addTagsToRespondentsFailure(err.message));
    cb(err);
  }
};

export const removeTagsFromRespondents = (data, cb) => async (dispatch) => {
  dispatch(removeTagsFromRespondentsStart());
  try {
    const response = await apiInstance2.delete(`/api/v2/respondents/tags`, { data });
    if (response.status === 200) {
      dispatch(removeTagsFromRespondentsSuccess());
      dispatch(fetchTags());
      createToastNotification({ message: I18n.t('Tag(s) have been deleted') });
      cb();
    }
  } catch (err) {
    dispatch(removeTagsFromRespondentsFailure(err.message));
    cb(err);
  }
};

export const addTagsToRespondent = (respondentId, tags, cb) => async (dispatch) => {
  dispatch(addTagsToRespondentStart());
  const tagNames = tags.map((item) => item.name);
  try {
    const response = await apiInstance2.post(`/api/v2/respondents/${respondentId}/tags`, tagNames);
    if (response.status === 200) {
      dispatch(addTagsToRespondentSuccess());
      createToastNotification({ message: I18n.t('tagsAdditionToast') });
      dispatch(fetchRespondentById(respondentId));
      dispatch(fetchTags());
      cb();
    }
  } catch (err) {
    dispatch(addTagsToRespondentFailure(err.message));
    cb(err);
  }
};

export const deleteTags = (respondentId, tags) => async (dispatch) => {
  dispatch(deleteTagsStart());
  const tagNames = tags.map((item) => item.name);
  try {
    const response = await apiInstance2.delete(`/api/v2/respondents/${respondentId}/tags`, { data: tagNames });
    if (response.status === 200) {
      dispatch(deleteTagsSuccess(tags));
      createToastNotification({ message: I18n.t('Tag(s) have been deleted') });
    }
  } catch (err) {
    dispatch(deleteTagsFailure(err.message));
  }
};

export const addRespondentsToGroup = (respondents, groupId, cb) => async (dispatch, getState) => {
  dispatch(addRespondentsToGroupStart());
  const body = respondents.map((item) => item.respondentID);
  const { lastTableParams } = getState().respondents;
  const {
    location: { pathname },
  } = getState().router;
  try {
    const response = await apiInstance2.post(`/api/v2/groups/${groupId}/respondents`, body);
    if (response.status === 200) {
      dispatch(addRespondentsToGroupSuccess());
      createToastNotification({ message: I18n.t('addedToGroupToast') });
      if (pathname.includes('groups')) {
        const id = pathname.match(/\w+$/)[0];
        dispatch(fetchRespondentsForGroup(id, lastTableParams));
      } else {
        dispatch(fetchRespondents(lastTableParams));
      }
      cb();
    }
  } catch (err) {
    dispatch(addRespondentsToGroupFailure(err.message));
    cb(err);
  }
};

export const createQuestionnaireSession = (data, cb) => async (dispatch, getState) => {
  dispatch(createQuestionnaireSessionStart());
  const {
    user: { user },
  } = getState();
  const body = { ...data, accountID: user.accountID, userID: user.userID, isAdministered: true };
  try {
    const response = await apiInstance2.post(`/api/v2/invites/administer-assessment`, body);
    if (response.status === 200) {
      let urlToRedirect = '';
      dispatch(createQuestionnaireSessionSuccess());
      const token = storageService.getItem('accessToken');
      const accountId = String(user.accountID).padStart(5, '0');
      const inviteId = String(response.data.inviteID).padStart(7, '0');
      if (response.data.url) {
        urlToRedirect = response.data.url;
      } else {
        urlToRedirect = `${window._env_.REACT_APP_QUESTIONNAIRE_URL}/login/${accountId}${inviteId}/${response.data.password}/${data.langID}?token=${token}`;
      }

      storageService.setItem('shouldLogoutOnLoad', 'true');
      storageService.removeItem('accessToken');
      storageService.removeItem('refreshToken');
      document.location.assign(urlToRedirect);
      // dispatch(logout());
      cb();
    }
  } catch (err) {
    dispatch(createQuestionnaireSessionFailure(err.message));
    createToastNotification({
      title: 'Error',
      message: I18n.t('questionnaireSessionErrorToast'),
      type: 'error',
      options: {
        autoClose: 5000,
      },
    });
    cb(err);
  }
};

export const getRespondentsCount = () => async (dispatch) => {
  dispatch(getRespondentsCountStart());

  try {
    const response = await apiInstance2.get('/api/v2/respondents/count');
    if (response.status === 200) {
      dispatch(getRespondentsCountSuccess(response.data));
    }
  } catch (err) {
    dispatch(getRespondentsCountFailure(err.message));
  }
};

export const removeRespondentsFromGroup = (groupId, respondentsIds, cb) => async (dispatch) => {
  dispatch(removeRespondentsFromGroupStart());
  try {
    const response = await apiInstance2.delete(`/api/v2/groups/${groupId}/respondents`, { data: respondentsIds });
    if (response.status === 200) {
      dispatch(removeRespondentsFromGroupSuccess());
      cb();
    }
  } catch (err) {
    dispatch(removeRespondentsFromGroupFailure(err.message));
    cb(err);
  }
};

export const unassignRespondentFromGroups = (respondentId, groupsIds, cb) => async (dispatch) => {
  dispatch(unassignRespondentFromGroupsStart());
  try {
    const response = await apiInstance2.delete(`/api/v2/respondents/${respondentId}/groups`, { data: groupsIds });
    if (response.status === 200) {
      dispatch(unassignRespondentFromGroupsSuccess());
      dispatch(fetchRespondentById(respondentId));
      cb();
    }
  } catch (err) {
    dispatch(unassignRespondentFromGroupsFailure(err.message));
    cb(err);
  }
};

export const fetchAnswerSheetTestsInfo = () => async (dispatch) => {
  dispatch(fetchAnswerSheetTestsInfoStart());

  try {
    const response = await apiInstance2.get('/api/v2/results/administer/tests');
    if (response.status === 200) {
      dispatch(fetchAnswerSheetTestsInfoSuccess(response.data));
    }
  } catch (err) {
    dispatch(fetchAnswerSheetTestsInfoFailure(err.message));
  }
};

export const getRespondentById = (id) => async (_, getState) => {
  const currentState = getState();
  const respondents = currentState?.respondents?.respondents;

  if (!respondents) return undefined;

  return respondents.find((respondent) => respondent.respondentID === id);
};
