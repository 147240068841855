const countriesMap = {
  US: 'United States',
  CN: 'China',
  IN: 'India',
  JP: 'Japan',
  DE: 'Germany',
  GB: 'United Kingdom',
  FR: 'France',
  IT: 'Italy',
  BR: 'Brazil',
  CA: 'Canada',
  RU: 'Russia',
  AU: 'Australia',
  ZA: 'South Africa',
  KR: 'South Korea',
  MX: 'Mexico',
  ES: 'Spain',
  ID: 'Indonesia',
  SA: 'Saudi Arabia',
  TR: 'Turkey',
  AR: 'Argentina',
  NG: 'Nigeria',
  EG: 'Egypt',
  VN: 'Vietnam',
  TH: 'Thailand',
  PK: 'Pakistan',
  MY: 'Malaysia',
  PH: 'Philippines',
  SG: 'Singapore',
  NL: 'Netherlands',
  CH: 'Switzerland',
  SE: 'Sweden',
  NO: 'Norway',
  FI: 'Finland',
  DK: 'Denmark',
  PL: 'Poland',
  UA: 'Ukraine',
  BE: 'Belgium',
  GR: 'Greece',
  IE: 'Ireland',
  IL: 'Israel',
  AE: 'United Arab Emirates',
  IR: 'Iran',
  IQ: 'Iraq',
  CL: 'Chile',
  CO: 'Colombia',
  PE: 'Peru',
  VE: 'Venezuela',
  PT: 'Portugal',
  CZ: 'Czech Republic',
  HU: 'Hungary',
  AT: 'Austria',
  AF: 'Afghanistan',
  AL: 'Albania',
  AM: 'Armenia',
  AZ: 'Azerbaijan',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BF: 'Burkina Faso',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BN: 'Brunei',
  BO: 'Bolivia',
  BT: 'Bhutan',
  BW: 'Botswana',
  BZ: 'Belize',
  CD: 'Congo (DRC)',
  CF: 'Central African Republic',
  CG: 'Congo (Republic',
  CI: 'Côte dIvoire',
  CR: 'Costa Rica',
  CU: 'Cuba',
  CY: 'Cyprus',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EE: 'Estonia',
  ER: 'Eritrea',
  ET: 'Ethiopia',
  FJ: 'Fiji',
  GA: 'Gabon',
  GD: 'Grenada',
  GE: 'Georgia',
  GH: 'Ghana',
  GM: 'Gambia',
  GT: 'Guatemala',
  GW: 'Guinea - Bissau',
  GY: 'Guyana',
  HN: 'Honduras',
  HT: 'Haiti',
  IS: 'Iceland',
  JM: 'Jamaica',
  JO: 'Jordan',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Cambodia',
  KI: 'Kiribati',
  KM: 'Comoros',
  KN: 'Saint Kitts and Nevis',
  KW: 'Kuwait',
  KZ: 'Kazakhstan',
  LA: 'Laos',
  LB: 'Lebanon',
  LC: 'Saint Lucia',
  LI: 'Liechtenstein',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Lithuania',
  LV: 'Latvia',
  LY: 'Libya',
  MC: 'Monaco',
  MD: 'Moldova',
  ME: 'Montenegro',
  MG: 'Madagascar',
  MH: 'Marshall Islands',
  MK: 'North Macedonia',
  ML: 'Mali',
  MM: 'Myanmar',
  MN: 'Mongolia',
  MR: 'Mauritania',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Maldives',
  MW: 'Malawi',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NE: 'Niger',
  NP: 'Nepal',
  NR: 'Nauru',
  OM: 'Oman',
  PG: 'Papua New Guinea',
  QA: 'Qatar',
  RW: 'Rwanda',
  SB: 'Solomon Islands',
  SC: 'Seychelles',
  SD: 'Sudan',
  SI: 'Slovenia',
  SK: 'Slovakia',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  SS: 'South Sudan',
  ST: 'São Tomé and Príncipe',
  SV: 'El Salvador',
  SY: 'Syria',
  SZ: 'Eswatini',
  TD: 'Chad',
  TG: 'Togo',
  TJ: 'Tajikistan',
  TL: 'Timor - Leste',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TO: 'Tonga',
  TV: 'Tuvalu',
  TZ: 'Tanzania',
  UG: 'Uganda',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  WS: 'Samoa',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe'
};

export default countriesMap;


