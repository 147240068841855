const getDateFromNow = (days, time) => {
  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + days);

  if (time) {
    switch (time) {
      case 'day_end':
        futureDate.setHours(23, 59, 0, 0);
        break;
      default:
    }
  }
  return futureDate;
};

export default getDateFromNow;
