import { I18n } from 'react-redux-i18n';
import { ReactComponent as AddToGroupIcon } from '../../assets/icons/add-to-group.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as InviteToQuestionnaireIcon } from '../../assets/icons/invite-to-questionnaire.svg';
import { ReactComponent as RequestReportIcon } from '../../assets/icons/request-report.svg';
import { ReactComponent as AdministerAssessmentIcon } from '../../assets/icons/sidebar-administer.svg';
import { ReactComponent as AnswerSheetIcon } from '../../assets/icons/sidebar-answersheet.svg';
import { ReactComponent as CreateGroupFromSelectionIcon } from '../../assets/icons/sidebar-group-from-selection.svg';
import { ReactComponent as AddTagIcon } from '../../assets/icons/sidebar-manage-tags.svg';
import { ReactComponent as GoToDetailsIcon } from '../../assets/icons/sidebar-respondent-details.svg';

import * as actions from '../actionsList';

const respondentsBulkActions = (isGroupRespondents, user) => {
  const actionsList = [
    {
      name: actions.ADMINISTER_ASSESSMENT,
      label: I18n.t('Administer assessment'),
      Icon: AdministerAssessmentIcon,
      isDisabled: (respondents, isBulkFlowActive) => isBulkFlowActive || respondents.length > 1,
    },

    {
      name: actions.CREATE_INVITE,
      label: I18n.t('Invite respondent(s) to assessment'),
      Icon: InviteToQuestionnaireIcon,
      isDisabled: (respondents, isBulkFlowActive) => isBulkFlowActive,
    },

    {
      name: actions.ANSWER_SHEET,
      label: I18n.t('Administer answer sheet'),
      Icon: AnswerSheetIcon,
      isDisabled: (respondents, isBulkFlowActive) => isBulkFlowActive,
    },
    {
      name: actions.REQUEST_REPORT,
      label: I18n.t('Request report(s)'),
      Icon: RequestReportIcon,
      isDisabled: (respondents, isBulkFlowActive) => {
        console.log('REQUEST_REPORT', respondents);
        
        const tests = respondents.reduce((acc, item) => [...acc, ...item.doneTests], []);
        const areReportsUnavailable = respondents.every((item) => !item.isReportsAvailable);
        return isBulkFlowActive || !tests.length || areReportsUnavailable;
      },
    },
    {
      name: actions.EDIT_RESPONDENT,
      label: I18n.t('Go to respondent details'),
      Icon: GoToDetailsIcon,
      isDisabled: (respondents, isBulkFlowActive) => isBulkFlowActive || respondents.length > 1,
    },
    {
      name: actions.MANAGE_TAGS,
      label: I18n.t('Manage tags for selected respondent(s)'),
      Icon: AddTagIcon,
    },
    {
      name: actions.CREATE_GROUP_FROM_SELECTION,
      label: I18n.t('Add new group from selection'),
      Icon: CreateGroupFromSelectionIcon,
    },
    {
      name: actions.ADD_TO_GROUP,
      label: I18n.t('Add to existing group'),
      Icon: AddToGroupIcon,
    },
    {
      name: isGroupRespondents ? actions.REMOVE_RESPONDENTS_FROM_GROUP : actions.DELETE_RESPONDENT,
      label: isGroupRespondents
        ? I18n.t('Delete selected respondents from group')
        : I18n.t('Delete selected respondents'),
      Icon: DeleteIcon,
      isDisabled: (respondents, isBulkFlowActive) => isBulkFlowActive,
    },
  ];

  return user?.userType === 3 ? actionsList.filter((item) => item.name !== actions.REQUEST_REPORT) : actionsList;
};

export default respondentsBulkActions;
