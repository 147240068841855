import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { array } from 'prop-types';
import { ReactComponent as ArrowUp } from '../../../assets/icons/blue-arrow-up-big.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/blue-arrow-down-big.svg';

import AnimateHeightWrapper from '../../reusable/AnimateHeight';
import addOrRemove from '../../../utils/addOrRemove';

const FAQDropDown = ({ questions }) => {
  const [openedPanels, setOpenedPanels] = useState([]);

  const onPanelClick = (id) => {
    setOpenedPanels(addOrRemove(openedPanels, id));
  };

  const TitleIcon = ({ opened }) => {
    return <TitleIconButton>{opened ? <ArrowUp /> : <ArrowDown />}</TitleIconButton>;
  };

  return (
    <div>
      {questions.map((question) => (
        <Panel key={question.id}>
          <PanelTitleStyled root selectable onClick={() => onPanelClick(question.id)}>
            <TitleIconWrapper>
              <TitleIcon opened={openedPanels.includes(question.id)} />
            </TitleIconWrapper>
            <AccordionTitleBody>
              <AccordionTitleWrapper>
                <AccordionTitle>{question.title}</AccordionTitle>
              </AccordionTitleWrapper>
            </AccordionTitleBody>
          </PanelTitleStyled>
          <ChildWrapper isClosed={!openedPanels.includes(question.id)}>
            <PanelBody
              height={openedPanels.includes(question.id) ? 'auto' : 10}
              opened={openedPanels.includes(question.id)}
            >
              <p dangerouslySetInnerHTML={{ __html: question.description }} />
            </PanelBody>
          </ChildWrapper>
        </Panel>
      ))}
    </div>
  );
};

FAQDropDown.propTypes = {
  questions: array.isRequired,
};

const ChildWrapper = styled.div`
  border: 1px solid #e0e0e0;
  border-top: 0;
  border-radius: 0 0 6px 6px;
  margin: 0 7px;

  ${(props) => props.isClosed && 'border: none; opacity: 0;'};

  ${(props) =>
    !props.isClosed &&
    css`
        margin-bottom: 10px;
      }
    `}
`;

const Panel = styled.div``;

const PanelTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px 15px 45px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  position: relative;
  border-radius: 0.6rem;
  
  &:after {
    content: '';
    position: absolute;
    left: -15px;
    top: 9px;
    width: 9px;
    height: 28px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity .3s ease;
  }
  
  &:hover {
    &:after {
      opacity: 1;
    }
  }
  
  ${(props) => props.pLeft && `padding-left: ${props.pLeft}px;`}
  ${(props) => props.root && `font-weight: bold;`}
  ${(props) => props.selectable && `cursor: pointer;`}
  ${(props) =>
    props.noBorder &&
    css`
      border: none !important;

      & + & {
        border-top: 1px solid ${(props) => props.theme.colors.grey6} !important;
      }
    `}
`;

const PanelBody = styled(AnimateHeightWrapper)`
  height: 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey6};
  
  &:last-of-type {
    border-bottom: none;
  }
    
  & > div > ${PanelTitle}:last-child {
    border-bottom: none;
  }
 
  ${(props) =>
    props.opened &&
    css`
        display: block;
        border-top: 1px solid ${props.theme.colors.grey6};
        padding: 20px;
        margin-bottom: 10px;
        text-align: justify;
      }
    `}

}
`;

const PanelTitleStyled = styled(PanelTitle)`
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background: ${(props) => props.theme.colors.grey1};
`;

const TitleIconWrapper = styled.span`
  position: absolute;
  top: 12px;
  left: 8px;
`;

const TitleIconButton = styled.span`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};

  &:hover {
    background-color: ${(props) => props.theme.colors.lightBlue1};
  }
`;

const AccordionTitleBody = styled.div`
  flex-grow: 1;
`;

const AccordionTitleWrapper = styled.div`
  padding-right: 46px;
  display: flex;
  align-items: center;
`;

const AccordionTitle = styled.span`
  font-weight: 600;
  line-height: 17px;
  font-size: ${(props) => props.theme.fontSizes.normal};
  font-weight: 600;
  margin-left: 0;
  margin-right: auto;
`;

export default FAQDropDown;
