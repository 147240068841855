import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import copy from 'copy-to-clipboard';
import { I18n } from 'react-redux-i18n';
import styled from 'styled-components';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { LicenseManager } from '@ag-grid-enterprise/core';
import invitesColumns from '../../table/columns/invites';
import PageWrapper from '../PageWrapper';
import TableTabs from '../reusable/TableTabs';
import {
  setSelectedInvites,
  fetchInvites,
  setActiveInvitesTab,
  setInvitesFilterValue,
  setInvitesFilterType,
  applyInvitesFilters,
  deleteInvites,
  updateExpiryAssessmentInvites,
} from '../../store/invites/actions';
import { selectTabs, selectInvitesLanguageOptions } from '../../store/invites/selectors';
import * as actions from '../../table/actionsList';
import invitesBulkActions from '../../table/bulkActions/invites';
import BulkActionsSidebar from '../BulkActionsSidebar';
import useTableFilter from '../../hooks/useTableFilter';
import useBulkSidebarState from '../../hooks/useBulkSidebarState';
import ConfirmationModal from '../reusable/ConfirmationModal';
import createToastNotification from '../../utils/createToastNotification';
import useModals from '../../hooks/useModals';

import ResetExpiryDate from './ResetExpiryDate';
import isChineseEnv from '../../utils/isChineseEnv';
import inviteTypes from '../../constants/inviteTypes';
import inviteStatuses from '../../constants/inviteStatuses';

ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, SetFilterModule]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

export const createCopiedNotification = () => {
  createToastNotification({
    message: I18n.t('clipboardCopyToast'),
    options: {
      type: 'success',
      className: 'toast-success-copy',
      position: 'bottom-center',
    },
  });
};

const Invites = ({ location }) => {
  const languageOptions = useSelector(selectInvitesLanguageOptions);
  const invites = useSelector((state) => state.invites.invites);
  const selectedInvites = useSelector((state) => state.invites.selectedInvites);
  const user = useSelector((state) => state.user.user);
  const tabs = useSelector(selectTabs);
  const activeTab = useSelector((state) => state.invites.activeTab);
  const filter = useSelector((state) => state.invites.filter);
  const dispatch = useDispatch();

  const [expiryDate, setExpiryDate] = useState(new Date());

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location.pathname]);

  const [isLoading, setLoadingStatus] = useState(true);

  const fetchData = useCallback(({ tableData, shouldReset, showSpinner, tableCallback }) => {
    if (showSpinner) setLoadingStatus(true);
    dispatch(
      fetchInvites(tableData, {
        callback: () => {
          setLoadingStatus(false);
          tableCallback && tableCallback();
        },
        shouldReset,
      }),
    );
  }, []);

  const [shouldResetPage, setShouldResetPageStatus] = useState(false);

  // INVITES DELETIONS
  const [isDeletionModalVisible, setDeletionModalState] = useState(false);
  const openDeletionModal = () => setDeletionModalState(true);
  const closeDeletionModal = () => setDeletionModalState(false);

  const onDeleteInviteConfirmation = () => {
    dispatch(
      deleteInvites(
        selectedInvites.map((item) => ({ inviteID: item.inviteID, inviteType: item.inviteType })),
        () => {
          closeDeletionModal();
        },
      ),
    );
  };

  const onSelectChange = useCallback((data) => {
    dispatch(setSelectedInvites(data));
  }, []);

  // Controlling bulk actions sidebar state
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedInvites });

  const modals = [actions.RESET_DEADLINE];
  const { modalsState, openModal, closeModal } = useModals(modals);

  // BULK ACTIONS
  const bulkActionsHandlers = {
    [actions.RESET_DEADLINE]: () => openModal(actions.RESET_DEADLINE),
    [actions.COPY_LINK]: () => {
      copy(selectedInvites[0].downloadLink);
      createCopiedNotification();
    },
    [actions.DELETE_INVITE]: openDeletionModal,
  };

  const bulkActions = useMemo(() => {
    return invitesBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedInvites) : item.isDisabled,
    }));
  }, [selectedInvites, user]);

  // columns settings
  const columns = useMemo(() => {
    return invitesColumns(languageOptions);
  }, [user, languageOptions]);

  // Table filtering
  const setFilterType = (name, value) => dispatch(setInvitesFilterType(name, value));
  const setFilterValue = (name, value) => dispatch(setInvitesFilterValue(name, value));
  const { inputsWithHandlers, appliedFiltersWithColumnNames } = useTableFilter({
    filter,
    columns,
    setFilterType,
    setFilterValue,
  });
  const onFilterApply = () => {
    dispatch(applyInvitesFilters());
  };
  const onTabClick = (tab) => {
    setShouldResetPageStatus(true);
    dispatch(setActiveInvitesTab(tab));
  };

  const onValueChange = (value) => {
    setExpiryDate(value);
  };

  useEffect(() => {
    setExpiryDate(selectedInvites[0] ? new Date(selectedInvites[0].expiryDate) : new Date());

    return () => {};
  }, [selectedInvites.length]);

  const handleExpiryDateUpdates = (type, invites) => {
    const data = {
      inviteType: type,
      inviteIDs: invites.map((invite) => invite.inviteID),
      expiryDate: expiryDate.toISOString(),
    };
    dispatch(updateExpiryAssessmentInvites(data), closeModal(actions.RESET_DEADLINE));
  };

  const onExpiryDateUpdates = () => {
    const assessmentInvites = selectedInvites.filter((invitation) => invitation.inviteType === 0);
    const projectInvites = selectedInvites.filter((invitation) => invitation.inviteType === 2);

    const assessmentInviteType = 0;
    const projectInviteType = 2;

    if (assessmentInvites.length > 0) handleExpiryDateUpdates(assessmentInviteType, assessmentInvites);
    if (projectInvites.length > 0) handleExpiryDateUpdates(projectInviteType, projectInvites);
  };

  const agGridDefaultSettings = {
    pagination: true,
    paginationPageSize: 50,
    paginationPageSizeSelector: [10, 20, 50],
    rowHeight: '58',
    headerHeight: '58',
    domLayout: 'autoHeight',
    gridOptions: { popupParent: document.body },
    onSelectionChanged: (event) => {
      const selectedData = [];
      event.api.forEachNodeAfterFilter((node) => {
        if (node.isSelected()) {
          selectedData.push(node.data);
        }
      });
      dispatch(setSelectedInvites(selectedData));
    },
  };

  const agGriddefaultColumnDefinition = {
    sortable: true,
  };

  const statusesStyles = {
    0: {
      title: 'Not sent',
      background: '#F9F9F9',
      color: '#828282',
      borderColor: '#CDCDCD',
    },
    1: {
      title: 'Scheduled',
      background: '#DBF6FF',
      color: '#008AC0',
      borderColor: '#B7EBFF',
    },
    2: {
      title: 'Sent',
      background: '#EAF7EF',
      color: '#27AE60',
      borderColor: '#c3e9d2',
    },
    3: {
      title: 'Failed',
      background: '#FFECEB',
      color: '#FF574C',
      borderColor: '#FFB1AB',
    },
  };

  const StatusCell = ({ data }) => {
    const styles = statusesStyles[data.invitationStatus];
    const backupText = inviteStatuses[data.invitationStatus];
    return (
      <StyledStatusWrapper>
        <StyledStatus style={styles && { ...styles }}>
          {styles ? I18n.t(styles.title) : I18n.t(backupText)}
        </StyledStatus>
      </StyledStatusWrapper>
    );
  };

  const [columnDefinition] = useState([
    {
      headerName: I18n.t('PersonName'),
      cellDataType: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
      valueGetter: ({ data }) =>
        isChineseEnv ? `${data.familyName}${data.firstName}` : `${data.firstName} ${data.familyName}`,
    },
    {
      field: 'email',
      headerName: I18n.t('Email Address'),
      cellDataType: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
    },
    {
      field: 'assessment',
      headerName: I18n.t('Assessment'),
      cellDataType: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
    },
    {
      field: 'langID',
      headerName: I18n.t('Language'),
      cellDataType: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
    },
    {
      headerName: I18n.t('Type'),
      cellDataType: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
      valueGetter: ({ data }) => I18n.t(inviteTypes[data.inviteType]),
    },
    {
      headerName: I18n.t('Invite status'),
      cellDataType: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
      valueGetter: ({ data }) => inviteStatuses[data.invitationStatus],
      cellRenderer: ({ data }) => <StatusCell data={data} />,
    },
    {
      field: 'invitationDate',
      headerName: I18n.t('Invitation Date'),
      cellDataType: 'date',
      flex: 1,
      filter: 'agDateColumnFilter',
      sort: 'desc',
      valueGetter: (params) => {
        return new Date(params.data.invitationDate);
      },
    },
    {
      field: 'expiryDate',
      headerName: I18n.t('Expiry Date'),
      cellDataType: 'date',
      flex: 1,
      filter: 'agDateColumnFilter',
      valueGetter: (params) => {
        return new Date(params.data.expiryDate);
      },
    },
  ]);

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
    };
  }, []);

  const handleFilterChanged = (params) => {
    params.api.deselectAll();
    params.api.refreshHeader();
  };

  useEffect(() => {
    fetchData({ shouldReset: true, showSpinner: true });
  }, [activeTab]);

  return (
    <PageWrapper title={I18n.t('Invites')} style={{ overflowY: 'auto' }}>
      <BulkActionsSidebar actions={bulkActions} isOpen={isSidebarOpen} onClose={closeSidebar} />
      <TableTabs tabs={tabs} clickHandler={onTabClick} activeTab={activeTab} />
      <div className="ag-theme-quartz ag-grid-custom-styles">
        <AgGridReact
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          rowData={isLoading ? null : invites}
          rowSelection={rowSelection}
          onFilterChanged={handleFilterChanged}
        />
      </div>
      <ConfirmationModal
        isVisible={isDeletionModalVisible}
        title={I18n.t('Delete invite')}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selectedInvites.length > 1 ? I18n.t('selected invites?') : I18n.t('this invite?')
        }`}
        onClose={closeDeletionModal}
        onConfirm={onDeleteInviteConfirmation}
        caution
      />
      {modalsState[actions.RESET_DEADLINE] && (
        <ResetExpiryDate
          onClose={() => closeModal(actions.RESET_DEADLINE)}
          onConfirm={onExpiryDateUpdates}
          expiryDate={expiryDate}
          // expiryDate={new Date(selectedInvites[0].expiryDate)}
          onValueChange={onValueChange}
        />
      )}
    </PageWrapper>
  );
};

Invites.defaultProps = {
  location: {},
};

const StyledStatusWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const StyledStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 8rem;
  width: auto;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
  border: 1px solid;
  height: 30px;
`;

export default Invites;
