const accountTabs = [
  // {
  //   name: 'personalInfoOptions',
  //   label: 'Personal Information Options',
  //   path: '/settings/respondent-personal-info',
  // },
  {
    name: 'biodataConfiguration',
    label: 'Biodata Configuration',
    path: '/settings/biodata-config',
  },
];

export default accountTabs;
