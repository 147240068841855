const translations = {
  About: '關於',
  Launch: '登錄',
  Tutorial: '使用指南',
  SignIn: '登錄',

  // Login Page (Login.js)
  Email: '電子郵箱',
  Submit: '保存',
  LoginNext: '输入用户名并继续',
  LoginSubmit: '继续',
  SwitchUser: '切换用户',
  ForgotPassword: '忘记密码',
  Password: '密碼',
  'Email is required': '請輸入電子郵箱',
  'Password is required': '請輸入密碼',
  'Should be a proper email address': '必須是有效的的電子郵寄地址',

  // (AddCompetencyCategory.js)
  Save: '保存',
  'Enter Name': '輸入名稱',
  'Add competency category': '添加勝任力類別',
  'Add description for the category': '添加描述',

  // (CompetencyGroupNavbar.js)
  'Main info': '基本資訊',

  // (AddCompetencyGroup.js)
  Add: '添加',
  Next: '下一步',
  'Name is required': '名稱為必填項',
  'Add 360 assessment': '添加360度評估',
  'Custom competencies': '自訂勝任力',
  'Inbuilt competencies': '標準勝任力',

  // (AddCompetencyItem.js)
  Edit: '編輯',
  'Competency description': '勝任力描述',

  // (AddCompetencyQuestion.js)
  'Add question': '添加問題',

  // (AddCompetencyQuestionForm.js)
  'Item text': '輸入問題',
  'Free field': '開放題',
  'Multiple choice': '選擇題',
  'Add another language': '添加另一種語言',

  // (AddCreditsFailureModal.js)
  'Credits addition error': '信用點添加失敗',
  'Try again': '請再試一次',

  //  (AddCreditsSuccessModal.js)
  Done: '完成',
  'Adding credits': '添加信用點',
  'Credits successfully added!': '信用點添加成功！',
  'Now your credit balance is:': '當前信用點剩餘：',

  // (AddCredits.js)
  Cancel: '取消',
  'Add credits': '添加信用點',
  'Your credits balance': '信用點剩餘：',
  'How many credits do you want to add?': '您要添加多少信用點？',
  'Purchase ID': '授權碼',
  'Error occurred during an attempt to add credits to your account': '添加信用點時出現錯誤',
  'Credits amount': '數量',

  // Edit Respondent (Index.js)
  Groups: '分組',
  Invites: '已發送邀請',
  invites: '已發送邀請',
  Reports: '報告',
  Assessments: '已完成測評',
  IAssessments: '测评',
  SAssessments: '测评工具设置',
  'Respondent Information': '受測者信息',

  // Edit Respondent (AddTagModal.js)
  'Add tags': '添加標籤',
  'Select tags': '選擇標籤',

  // Edit Respondent (Information.js)
  Sex: '性別',
  BioTitle: '職位名稱',
  Title: '名稱',
  Sector: '職級',
  Company: '公司名稱',
  Industry: '行業',
  Education: '教育程度',
  Reference: '參考資訊',
  Ethnicity: '籍貫',
  'Job area': '職能',
  'First Name': '名',
  'Family Name': '姓',
  'Family name': '姓',
  'Save changes': '保存更改',
  'Date of Birth': '出生日期',
  'First Language': '母語',
  'Additional Info': '附加資訊',
  'Main Information': '主要資訊',

  // Edit Respondent (RespondentGroups.js)
  group: '分組',
  Group: '分組',
  'Remove from groups': '從分組中移除受測者',
  'Are you sure you want to remove respondent from selected group(s)': '您確定要從分組中移除所選的受測者嗎？',

  // Edit Respondent (RespondentInvites.js)
  invite: '測評邀請',
  'Invite Deletion': '刪除測評邀請',

  // Edit Respondent (RespondentReports.js)
  'Report deletion': '報告刪除',
  'Are you sure you want to delete selected report?': '您確定要刪除所選報告嗎？',

  // Edit Respondent (RespondentTests.js)
  'Test deletion': '刪除測評',
  'Are you sure you want to delete selected test?': '您確定要刪除所選測評嗎？',

  // Edit Respondent (TagsManagement.js)
  Tags: '標籤',
  '+ Add tag': '+ 添加標籤',

  // Groups (AddNewGroup.js)
  Create: '創建',
  'Group name': '分組名稱',
  'Add new group': '添加新分組',
  'Group description': '分組描述',

  // Groups (GroupHeader.js)
  Type: '類型',
  'Creation date': '創建日期',
  'Members count': '組成員',
  member: '成員',

  // Groups (Groups.js)
  List: '受測者名單',
  Respondents: '受測者',
  respondents: '受測者',
  'Delete group': '刪除分組',
  'Are you sure you want to delete this group(s)': '您確定要刪除分組嗎？',

  // Manage active links
  'Manage active links': '管理活動連結',
  'Manage active group links': '管理活動組連結',

  // Groups request report
  'Select common assessment / battery': '請選擇測評答案以生成報告',
  reports: '報告列表',

  // Groups generate open group link
  link: '連結',
  Generate: '生成',
  'The link has been successfully generated': '開放組連結已生成',

  // Invites (Invites.js)
  'Delete invite': '刪除測評邀請',
  'selected invites?': '所選的測評邀請嗎？',
  'this invite?': '這個測評邀請嗎？',

  // LaunchScreen (LaunchScreen.js)
  Language: '語言',
  language: '語言',

  // Notifications (Notifications.js)
  Results: '結果',
  notification: '通知',
  Notifications: '通知',

  // Projects (AddProjectsModal.js)
  Min: '最少評估人數',
  Info: '信息',
  Raters: '評估者',
  Deadline: '截止日期',
  'Peer Alias': '同事',
  Description: '描述',
  'Project Name': '項目名稱',
  'Project name': '項目名稱',
  'All standard': '標準版',
  'Edit Project': '編輯專案',
  'Manager Alias': '上級',
  'Project Alias': '評估者資訊',
  'Direct Report Alias': '下屬',
  'Choose Project Type': '選擇項目類型',
  '360 assessment has to be fully supported in at least 1 language': '需要為360度評估專案的所有問題配置同一種語言',
  '360 Survey': '360度評估',
  '360 Surveys': '360度評估模型',
  'Other Alias': '其他人',
  deadlineWarning: '生成報告的日期.',
  createAssessmentBattery: '如需要選擇多個測評工具，請在設置中創建自訂成套測驗，然後從我的最愛類別中選擇它',
  manualInviteDesc: '每次您向項目添加新受測者時，都會發送邀請.',
  autoInviteDesc: '每次您向項目添加新受測者時，都會發送邀請.',
  scheduledInviteDesc: '邀請將在選定日期發. 如果您在此之後添加新受測者，則在您添加邀請時會自動發送',
  daysLeft: '%{days} 剩餘天數',

  // Projects (projectList.js)
  'Add Assessment Project': '添加測評項目',
  'Add assessment project': '添加測評項目',
  'Add 360 Appraisal Project': '添加360度評估專案',
  'Manage your assessment projects here Plan and implement the right assessment approach and tests Effectively and consistently administrate, communicate and manage the whole assessment process using this user friendly tool':
    '在這裡管理您的測評專案。選擇和部署合適的測評方式和工具。使用這個簡單易用的功能來高效和一致地管理整個測評過程。',

  'Manage your entire 360 appraisal process here Easy to use, allowing appraisals to be set up in minutes and results generated instantly Choose from Psytech existing competency framework or fully customise your 360 project':
    '在這裡管理您地360度評估專案。使用這個簡單易用的功能來輕鬆創建評估項目和生成結果。您可以使用系統內嵌的PsyTech標準勝任力框架，也可以根據需要來自訂勝任力模型。',

  // Projects (Projects.js)
  All: '所有項目',
  Project: '項目',
  Projects: '項目',
  'this project?': '這個項目？',

  '360 Appraisal': '360度評估',
  'Delete project': '刪除項目',
  'selected projects?': '所選的項目嗎？',
  'Are you sure you want to delete': '您確定要刪除',

  // Raters (Raters.js)
  rater: '評估者',
  Rater: '評估者',
  'Delete raters': '刪除評估者',
  'Loading raters': '載入中……',
  'selected raters?': '所選的評估者？',
  'this rater?': '這個評估者？',
  Session: '項目組',
  'Session for': '360度評估專案組',

  // Raters (AddRatersModal.js)
  Role: '角色',
  Other: '其他',
  other: '其他',
  Peers: '同事',
  Confirm: '確認',
  Managers: '上級',
  'No email': '沒有電子郵箱資訊',
  'Add raters': '添加評估者',
  'Direct reports': '下屬',
  'Search existing records': '在受測者名單中搜索',
  'Make sure selected record has a valid email address added': '請確保所選記錄已添加有效的電子郵箱資訊',

  // Raters Invites (columns.js)
  Name: '名稱',
  PersonName: '姓名',
  'Expiry Date': '到期日期',
  'Invite status': '邀請狀態',
  'Email Address': '電子郵箱',
  'Hide expired': '隱藏過期邀請',

  // Raters Invites (Invites.js)
  'chosen invite(s)?': '所選的邀請',
  'selected invite(s)?': '所選的邀請',
  'Loading invites': '載入中……',

  // Raters Reports (Reports.js)
  Pending: '生成中',
  Unread: '未查看',
  Viewed: '已查看',
  Error: '錯誤：',
  'Support ticket raised': '已提交支援請求資訊',
  'Loading reports': '載入中……',

  // Raters Results (Results.js)
  result: '結果',

  // Reports Reports (Reports.js)
  'Are you sure you want to delete selected report(s)?': '您確定要刪除所選報告嗎？',

  // RequestReport360 (RequestReport360.js)
  'Report Request for': '請求報告',
  'Report Request': '報告請求',
  credits: '信用點數：',
  'Total Credits:': '信用點總數：',
  'Total Credits': '信用點總數',
  Request: '請求',
  Self: '被評估者',

  // Respondents AddRespondentModal (AddRespondentForm.js)
  'Company Name': '公司名稱',
  'Job Area': '職能',
  Remove: '從該組移出',

  // Respondents AddRespondentModal (AddRespondentModal.js)
  'Add respondent': '添加受測者',
  'Main Details': '主要資訊',
  'Extra Details': '附加資訊',
  'Group Membership': '分組資訊',

  // Respondents AddRespondentModal (GroupsTab.js)
  'Select groups': '選擇分組',

  // Respondents AdministerAssessment (index.js)
  Start: '開始',
  Administer: '施測人：',
  to: '受測者：',
  questionnaires: '問卷',
  Questionnaires: '問卷',
  reasoning: '推理能力測驗',
  Reasoning: '推理能力測驗',
  solutions: '解決方案',
  Solutions: '解決方案',
  batteries: '成套測驗',
  Batteries: '成套測驗',
  favourites: '自訂',
  Favourites: '自訂',
  // Respondents (AnswersStep.js)
  'Test:': '測評：',

  // Respondents AnswerSheet (index.js)
  Previous: '上一步',
  'Administer answer sheet for': '答題紙輸入：',
  answers: '答題紙',
  Respondent: '受測者',

  // Respondents (AssessmentStep.js)
  'Welcome text': '歡迎辭',
  'Expiry date': '到期日期',

  // Invite (index.js)
  'Invite respondents': '邀請受測者',
  Invite: '邀請：',
  'Create invite and go next': '創建測評邀請並繼續',
  Finish: '完成：',
  'You are sending an invitation for:': '您正在發送測評至：',
  in: ' ',
  invitation: '邀請',
  OR: '或',

  // Invite (InvitationStep.js)
  Immediately: '即刻發出',
  Later: '稍後發出',
  'Email address is missing or not valid in respondent record': '受測者記錄中缺少電子郵寄地址或無效',
  missingEmailError: '受測者的電子郵寄地址無效，請檢查。',
  'Copy link': '複製測評連結',
  Copied: '已複製',
  'Copy and share generated link': '複製並分享測評連結',
  'Send invitation link via email to:': '向以下郵箱發送測評邀請：',
  'Send invitation link via email for respondents': '通過電子郵件為受測者發送邀請連結 %{respondentsCount}',
  'Email template': '電子郵件範本',
  'When do you want to send an invite?': '您想要何時發送測評邀請？',
  'Email invite': '郵件邀請',
  'Email sent': '郵件已發送',

  // RequestReportModal (index.js)
  'Request report': '請求報告',
  'Request report for': '請求報告對象：',
  for: '對象：',
  'Request Reports': '請求報告',
  'Common tests: ': '相同測評',
  'At least 1 assessment must be common': '必須至少有1項相同測評',
  'Selected respondents': '所選受測者',
  tests: '測評：',
  'Assessment/Battery': '測評工具',
  emptyLanguageReports: '抱歉，我們找不到任何適用於此語言的報告',
  'Total credits:': '所需信用點:',
  'Total credits': '所需信用點',

  // RequestReportModal (ReportsList.js)
  'Available Reports': '可選測評報告：',
  Norm: '常模：',
  Options: '選項：',
  'Ideal Profile': '理想畫像：',

  // Respondents (AddToGroup.js)
  'Add to group': '添加至分組',
  'Add respondent(s) to group': '將受測者添加至分組',

  // Respondents (ManageTagsModal.js)
  'Manage respondents tags': '管理受測者標籤',
  'Select from existing tags or add new': '從現有標籤中選擇或添加新標籤',
  'Add tag to selected respondent(s)': '添加標籤',
  'Remove tag from selected respondent(s)': '刪除標籤',

  // Respondents  (RespondentDropdown.js)
  'Add new respondent': '添加單個受測者',
  'Import respondents from file': '從文件導入受測者',
  'Edit respondent': '編輯受測者',
  'Delete respondent': '刪除受測者',

  // Respondents  (Respondents.js)
  'Delete respondents from group': '從分組中刪除受測者',
  'Loading respondents': '載入中……',
  'Are you sure you want to remove': '你確定要將',
  'from the group?': '從當前組中移除嗎？',
  'All respondents are selected': '%{respondentsCount} 所有受測者均被選中',
  'Clear selection': '清除選擇',
  'All respondents on this page are selected': '%{respondentsCount} 此頁面上的所有受測者均被選中',
  'Select all respondents in this result': '%{respondentsCount} 選擇此結果中的所有受測者',

  // Reusable Accordion (Accordion.js)
  INBUILT: '標準勝任力',

  // Reusable Accordion (CompetencyGroupsAccordion.js)
  'Collapse all': '收起全部',
  'Expand all': '展開全部',

  // Reusable  Filter (DateInputGroup.js)
  'Date range': '日期範圍',

  // Reusable  Filter (Filter.js)
  Filter: '篩選',
  'Clear all': '清除全部',
  Apply: '應用',

  // Reusable Filter (TextInputGroup.js)
  'Filter type': '篩選類型',

  // Reusable Filter (Search.js)
  Search: '搜索',

  // Reusable Badges (CustomBadge.js)
  CUSTOM: '自訂',

  // Reusable TextEditor (Editor.js)
  'Insert variable': '插入變數',

  // Reusable  (ConfirmationModal.js)
  No: '否',
  Yes: '是',

  // Reusable  (DatePicker.js)
  'Select the date': '選擇日期',

  // Reusable  (DateTimeSelect.js)
  'Time:': '時間：',
  Time: '時間',

  // Reusable  (ErrorMessage.js)
  'Oops, something went wrong': '載入中......',
  'Please try again': '如長時間未相應，請重新刷新頁面',
  Ok: '確認',

  // Reusable (Quantity.js)
  Quantity: '數量',

  // Reusable (StatusCell.js)
  'In Progress': '進行中',
  'In progress': '進行中',
  'On Hold': '暫停',
  Canceled: '已取消',
  Completed: '已完成',
  completed: '已完成',

  // Session SessionInvite (index.js)
  'Send invite to raters': '發送邀請給評估者',
  'Send invite to raters who have not yet completed': '發送邀請給尚未完成的評估者',
  'Send invite to raters who have not yet been invited': '發送邀請給尚未邀請的評估者',
  'Send invite to raters who have already been invited but haven&apos;t yet completed':
    '重新發送邀請給已邀請但尚未完成的評估者',
  'Send invite to self with a request to specify their own raters': '邀請本人自行指定評估者',
  'Welcome text for Self Raters': '為本人指定測驗歡迎辭',
  'Email template for Self Raters': '為本人指定電子郵件邀請範本',
  'Welcome text for Other Raters': '為其他角色指定測驗歡迎辭',
  'Email template for Other Raters': '為其他角色指定電子郵件邀請範本',
  Send: '發送',

  // Session SessionInvite (SessionInvitationStep.js)
  'Email body': '電子郵件正文',
  'Email subject': '電子郵件主題',
  'Invitation date': '邀請日期',
  'Email body for Self Raters': '給自評者的電子郵件正文',
  'Email body for Other Raters': '給他評者的電子郵件正文',
  'Email subject for Other Raters': '給他評者的電子郵件主題',
  'Email subject for Self Raters': '給自評者的電子郵件主題',

  // Session (AddSessionModal.js)
  'Sorry, session with selected respondent already exists.': '您所選的被評估者已在評估組中。',
  'Add session': '添加評估組',
  'Session SELF': '被評估者',
  'Search existing record to select session SELF': '從受測者名單中選擇被評估者',

  // Session (SessionHeader.js)
  'All Standard': '標準版',
  '360 Assessment:': '360度評估',
  'Type:': '類型：',
  'Status:': '狀態：',
  'Deadline:': '截止日期：',

  // Session (Sessions.js)
  Sessions: '評估組',
  session: '評估組',
  'Loading sessions': '載入中……',
  'Delete session': '刪除評估組',
  'Delete sessions': '刪除評估組',
  'this session?': '這個評估組嗎？',
  'Are you sure you want to delete this project': '您確定要刪除這個項目嗎？',
  'Sessions list': '項目組列表',

  // Session header (SessionHeader.js)
  'days left': '天剩餘',
  'Send invites': '發送邀請',
  'Outstanding ratings': '未完成評估',
  'Send reminder': '發送提醒',
  'Sessions completed': '已完成項目組',
  'Send invite': '發送邀請',

  // Settings AddResponseScale (AddResponseScale.js)
  'Loading...': '載入中……',

  // Settings (AddScaleForm.js)
  'Add response scale': '添加應答選項',
  'Edit response scale': '編輯應答選項',

  // Settings Components   (EditTitle.js)
  'Edit Name': '編輯名稱',
  'Edit 360 assessment': '編輯360度評估',

  // Settings Components  (QuestionItem.js)
  Question: '問題：',
  'No translation available for': '缺少對應的翻譯給',

  // Settings Components  (ReviewCompetencies.js)
  Competencies: '勝任力',
  CONFIRM: '確認',

  // Settings utils (ResponseScale.util.js)
  'Name is the required field': '名稱為必填',
  'All response scales should be filled': '所有評分選項為必填',

  // Settings (CustomCompetencies.js)
  'Are you sure you want to remove selected': '您確定要移除所選',
  Collapse: '收起列表',
  Expand: '打開列表',
  '360 settings': '360度評估設置',

  // Settings (Settings.js)
  Settings: '設置',

  // (BulkActionsSidebar.js)
  'Bulk actions': '菜單',
  Actions: '菜單',

  // 360 surveys
  'Add new 360 survey': '新建360度評估模型',
  'Add 360 survey': '新建360度評估模型',
  'Edit 360 survey': '編輯360度評估模型',
  'Duplicate 360 survey': '複製360度評估模型',
  'Delete selected 360 survey(s)': '刪除360度評估模型',
  Sorting: '排序方式',
  // Sidebar.js
  'Are you sure you want to log out?': '您確定要退出登錄嗎？',
  'Credits balance:': '信用點餘額',

  // Settings  (Settings.const.js)
  'Assessments and batteries': '測評和成套測評',
  'Ideal Profiles': '理想剖析圖',
  Norms: '常模',
  '360 Assessments': '360度評估',
  Templates: '範本',
  'Email templates': '電子郵件範本',
  Account: '帳戶',
  'General info': '基本資訊',
  'Credits History': '信用點記錄',
  'Reports Usage': '報告生成情況',
  'Assessments Stats': '測評使用情況',
  'Response Scales': '應答選項',
  Tutorials: '教程',
  'Watch tutorials': '观看教程',

  // Settings (AssessmentsAndBatteries.js)
  assessment: '測評',
  Assessment: '測評',
  'this battery?': '這個成套測評？',
  'Delete battery': '刪除成套測評',
  'Delete batteries': '刪除成套測評',
  'Assessments setting': '測評設置',
  'selected batteries?': '所選成套測評？',
  // Settings (AddNormMode.js)
  'Add New Norm': '添加新常模',
  'Assessment on which the norm profile is based': '常模對應的測評',
  Frequency: '從分組中提取',
  SD: '手動輸入',
  'Sample Size': '樣本量',
  'Sample Size:': '樣本量:',
  'Sample size must be greater than or equal to 1': '樣本量必須大於或等於1',
  'Select group': '請選擇組',
  'Norm name': '常模名稱',
  'Assessment on which the norm is based': '請指定測評工具',

  // Settings (CompetencyGroups.js)
  'Copy of': '副本',
  'Competency Group': '勝任力組',
  'Delete assessment': '刪除測評',
  'selected assessments': '所選測評',
  'this assessment': '這個測評',
  'Duplicate assessment': '複製測評',
  'Are you sure you want to duplicate': '您確定要複製',
  'Loading competencies': '載入中',

  // Settings (CompetencyGroupsDetails.js)
  Structure: '結構',
  'Response scale': '應答選項',
  'Loading Competency Details': '載入中……',
  'Categories:': '勝任力類別',
  'Competencies:': '勝任力',
  'Numbers of items': '問題數',
  'Multiple choice:': '選擇題',
  'Free text:': '開放題',
  'Estimated time': '預估時間',
  minutes: '分鐘',
  'Available languages': '支援語言',
  'Make sure at least one language is fully supported in assessment': '請確保這個測評完全支援至少一種語言',

  Questions: '問題',
  'Competency deletion': '刪除勝任力',
  'competencies?': '勝任力嗎？',
  'competency?': '勝任力嗎？',
  'Assessment deletion': '刪除測評',
  'Are you sure you want to deleted selected the assessment?': '您確定要刪除所選測評嗎？',

  // Settings Templates (WelcomeText.js)
  'Welcome texts': '歡迎辭',
  'selected welcome texts?': '所選歡迎辭嗎？',
  'this welcome text?': '這個歡迎辭嗎？',

  // Settings Templates (WelcomeTextScreen.js)
  'Add new welcome text': '添加新歡迎辭',
  'Template name': '範本名稱',
  'welcome text': '歡迎辭',
  'Delete welcome texts': '刪除歡迎辭',

  // Settings Templates (templatesTabs.js)
  'Assessment welcome texts': '測評歡迎辭',
  template: '範本',
  'Delete templates': '刪除範本',
  'selected templates?': '所選範本嗎？',
  'this template?': '這個範本嗎？',

  // Settings Templates (TemplatesScreen.js)
  'Field is required': '必填項',
  'Template subject': '範本主題',

  // Settings ResponseScales (ResponseScales.js)
  'Response Scale': '應答選項',
  'Delete scale(s)': '刪除選項',
  'Are you sure you want to delete this scale(s)?': '您確定要刪除這個選項嗎？',
  Responses: '應答選項',
  Languages: '語言',
  'Score right to left': '計分方式為從右（高）到左（低）',
  indexedResponse: '選項%{index}',

  // Settings ResponseScales (bulkActions.js)
  'Delete selected response scale(s)': '刪除所選評分選項',
  // Settings Norms (NormsHeader.js)
  'Norm Type:': '常模類型：',
  'Creation Date:': '創建日期：',

  // Settings Norms (NormsScales.js)
  'Norm Table': '常模對照表',
  'Frequency Histograms': '長條圖',
  'Bio Data': '人口統計學資訊',
  Stenine: '標準九分',
  Stentable: '標準十分',

  // Settings Norms (Norms.js)
  'Add new norm': '添加新常模',
  'Delete norm(s)': '刪除常模',
  'Assessment and Batteries': '測評和成套測評',
  'selected norms?': '所選常模嗎？',
  'this norm?': '這個常模嗎？',
  'Creation Date': '創建日期',

  // Settings Norms (HistogramView.js)
  FREQUENCY: '頻次',
  'RAW SCORE': '原始分',
  'Raw Score': '原始分',

  // Settings ManageBattery (index.js)
  'Battery name': '成套測評名稱',
  'Search existing assessments': '搜索成套測評',
  'Add battery': '添加成套測評',

  // Settings IdealProfile (IdealProfileScales.js)
  'Ideal profile type:': '理想剖析圖類型：',
  'Unsaved data': '未保存的資料：',
  'The current ideal profile has not been saved. Would you like to save it?': '當前理想剖析圖尚未保存，您想要保存嗎？',
  addIdealProfileGroups: '組',

  // Sidebar.js
  Logout: '退出登錄',

  // Settings IdealProfile (IdealProfile.js)
  'Delete ideal profiles': '刪除理想剖析圖',
  'selected ideal profiles?': '所選理想剖析圖嗎？',
  'this ideal profile?': '這個理想剖析圖嗎？',

  // Settings AddIdealProfile.js
  'Add ideal profile': '添加理想剖析圖',
  'Ideal profile name': '理想剖析圖名稱',
  'I will manually select the values': '手動配置剖析圖值',
  'I will use respondent data to determine the values': '基於受測者資料配置剖析圖值',
  Test: '測評',
  // (advancedFilterOptions.js)
  'Is equal to': '等於',
  'Is not equal to': '不等於',
  'Starts with': '為開頭',
  Contains: '包含',
  contains: '包含',
  'Does not contain': '不包含',
  'Ends with': '為結尾',
  'Lesser than': '小於',
  'Greater than': '大於',
  '1 day': '1天內',
  '3 days': '3天內',
  '1 week': '1周內',
  '2 weeks': '2周內',
  '1 month': '1個月內',
  '1 year': '1年內',

  // groupsTypes.js
  Unsupervised: '無人監督模式',
  Supervised: '有人監督模式',
  // idealProfileTypes.js
  'Manually configured': '手動配置',
  'Based on respondent data': '基於受測者資料配置',

  // inviteCreationCategories.js
  'Personality tests': '人格',
  'Interests Values Attitudes': '興趣/價值觀/態度',
  Value: '價值觀',
  'Preferences/Styles': '偏好/風格',
  'Custom battery': '自訂成套測評',
  'General Solutions': '通用解決方案',
  'Screening Solutions': '篩選解決方案',
  Solution: '解決方案',
  Battery: '成套測評',
  'Every 4 days': '每4天',
  'Every 3 days': '每3天',
  'Every 2 days': '每2天',
  'Every day': '每天 ',
  "Don't send": '不發送',
  Immediate: '即刻發送',

  // inviteStatuses.js
  'Not sent': '未發送',
  Scheduled: '已預約',
  Sent: '已發送',
  Failed: '發送失敗',
  'Failed status 2': '發送失敗地位2',

  // raterRoles.js
  Peer: '同事',
  'Direct report': '下屬',

  // respondentSelectOptions.js
  'No Qualification': '無教育程度資訊',
  'Secondary School': '初中及以下',
  'Industry or Trade Training': '工業或貿易培訓',
  'Professional Qualification': '高中',
  'Tertiary Institute Diploma/Certificate': '大中專',
  'University Bachelor Degree': '本科',
  'Postgraduate Diploma/Certificate': '研究生文憑/證書',
  'Bachelor Honours': '榮譽學士',
  'Masters Degree': '碩士',
  'Doctorate Degree': '博士',
  Aborigine: '北京',
  African: '天津',
  'African American': '上海',
  Afrikaner: '重慶',
  Afrikaans: '',
  Albanian: '安徽',
  Algerian: '福建',
  American: '甘肅',
  Argentinian: '廣東',
  'Australian Aborigine': '廣西',
  Arab: '貴州',
  Asian: '海南',
  Austrian: '河北',
  Australian: '河南',
  Bangladeshi: '黑龍江',
  Balinese: '湖北',
  'Black British': '湖南',
  'Black Canadian': '吉林',
  Belgian: '江蘇',
  'Black Carribean': '江西',
  'Black Indian': '遼寧',
  'Black African': '內蒙古',
  Bolivian: '寧夏',
  Bengali: '青海',
  'Black Other': '山東',
  Bosnian: '山西',
  Brazilian: '陝西',
  British: '四川',
  Belarusian: '西藏',
  Bulgarian: '新疆',
  Burmese: '雲南',
  Canadian: '浙江',
  Castilian: '澳門',
  Catalan: '香港',
  Celtic: '臺灣',
  Chinese: '其它亞洲國家和地區',
  'Channel Islander': '歐洲',
  Cossack: '北美洲',
  Chilean: '南美洲',
  Cambodian: '大洋洲',
  Chechen: '非洲',
  Colombian: '哥倫比亞人',
  'Costa Rican': '哥斯達黎加人',
  Croatian: '克羅地亞人',
  Corsican: '科西嘉人',
  Cantonese: '廣東人',
  Cuban: '古巴人',
  Cypriot: '塞浦路斯人',
  'Cypriot Greek': '塞浦路斯希臘人',
  Czech: '捷克人',
  Danish: '丹麥人',
  Dutch: '荷蘭人',
  Eurasian: '歐亞人',
  Ecuadorian: '厄瓜多爾人',
  Egyptian: '埃及人',
  English: '',
  Estonian: '愛沙尼亞人',
  Ethiopian: '埃塞俄比亞人',
  European: '歐洲人',
  Fijian: '斐濟人',
  Filipino: '菲律賓人',
  Flemish: '佛蘭芒人',
  Finnish: '芬蘭人',
  French: '法國人',
  Gaelic: '蓋爾人',
  Georgian: '喬治亞人',
  German: '德國人',
  Greek: '希臘人',
  Hungarian: '匈牙利人',
  Hispanic: '西班牙裔人',
  'Hong Kong Chinese': '中國人（香港地區）',
  Icelandic: '冰島人',
  Indonesian: '印尼人',
  Irani: '伊朗人',
  Indian: '印度人',
  'Iranian/Persian': '伊朗/波斯人',
  Iraqi: '伊拉克人',
  Irish: '愛爾蘭人',
  'Iranian Arab': '伊朗阿拉伯人',
  Iranian: '伊朗人',
  Italian: '意大利人',
  Jamaican: '牙買加人',
  Jewish: '猶太人',
  'Jewish/Israeli': '猶太/以色列人',
  Jordanian: '約旦人',
  Japanese: '日本人',
  Kenyan: '肯尼亞人',
  Kiwi: '新西蘭人',
  Kongo: '剛果人',
  Korean: '韓國人',
  Latin: '拉丁人',
  Lebanese: '黎巴嫩人',
  Lithuanian: '立陶宛人',
  Libyan: '利比亞人',
  'Latin American': '拉丁美洲人',
  Latino: '拉丁裔人',
  Luxembourger: '盧森堡人',
  Latvian: '拉脫維亞人',
  Macedonian: '馬其頓人',
  'Middle Eastern': '中東人',
  Malaitian: '馬萊西亞人',
  Maori: '毛利人',
  Monacan: '摩納哥人',
  Moroccan: '摩洛哥人',
  Maltese: '馬爾他人',
  Mexican: '墨西哥人',
  Malay: '馬來人',
  'Mixed Race': '混血人',
  'New Zealand European': '紐西蘭歐洲人',
  Nigerian: '尼日利亞人',
  Norwegian: '挪威人',
  Nepalese: '尼泊爾人',
  'New Zealander': '紐西蘭人',
  'Other Ethnicity': '其他種族',
  Paraguayan: '巴拉圭人',
  'Pacific Islander': '太平洋島民',
  Persian: '波斯人',
  Peruvian: '秘魯人',
  'Persian/Iranian': '波斯/伊朗人',
  Pakistani: '巴基斯坦人',
  Palestinian: '巴勒斯坦人',
  Polish: '波蘭人',
  'Papua New Guinean': '巴布亞新幾內亞人',
  'Puerto Rican': '波多黎各人',
  Portuguese: '葡萄牙人',
  Polynesian: '波利尼西亞人',
  Romanian: '羅馬尼亞人',
  Russian: '俄羅斯人',
  Samoan: '薩摩亞人',
  'South African Coloured': '南非混血人',
  Scottish: '蘇格蘭人',
  Swede: '瑞典人',
  'South African European': '南非歐洲人',
  Serbian: '塞爾維亞人',
  Swedish: '瑞典人',
  'South American Indian': '南美印第安人',
  Sicilian: '西西里人',
  Slovak: '斯洛伐克人',
  Slav: '斯拉夫人',
  Spanish: '西班牙人',
  'Sri Lankan': '斯里蘭卡人',
  'Southeast Asian': '東南亞人',
  Swiss: '瑞士人',
  Swahili: '斯瓦希里語人',
  Swazi: '斯威士蘭人',
  Taiwanese: '中國人（臺灣地區）',
  Thai: '泰國人',
  Turkish: '土耳其人',
  Tamil: '泰米爾人',
  Tongan: '湯加人',
  Rwandan: '盧旺達人',
  Tunisian: '突尼斯人',
  Udi: '烏迪人',
  Ugandan: '烏干達人',
  Ukrainian: '烏克蘭人',
  Uruguayan: '烏拉圭人',
  Vietnamese: '越南人',
  Welsh: '威爾斯人',
  White: '白人',
  'West Indian': '西印度人',
  Zimbabwean: '辛巴威人',
  Zulu: '祖魯人',
  Manager: '經理',
  '- Chief executive': '- 首席執行官',
  '- Administrative or commercial manager': '- 行政或商務經理',
  '- Production or specialized services manager': '- 生產或專業服務經理',
  '- Hospitality, retail or other services manager': '- 酒店，零售或其他服務經理',
  '- Senior manager': '- 高級經理',
  '- Middle manager': '- 中級經理',
  '- Junior manager/supervisor': '- 初級經理/主管',
  '- Other': '- 其他',
  Professional: '專業人員',
  '- Science or engineering professional': '- 科學/工程',
  '- Health professional': '- 醫療衛生專業人員',
  '- Teaching professional': '- 教育/教學專業人員',
  '- Business or administration professional': '- 商業/管理專業人員',
  '- Information and communications technology professional': '- 資訊和通信技術專業人員',
  '- Legal, social or cultural professional': '- 法律/社會/文化專業人員',
  'Technician or associate professional': '技術人員或專業人員助理',
  '- Science or engineering associate professional': '- 科學/工程技術人員',
  '- Health associate professional': '- 醫療衛生技術人員',
  '- Business or administration associate professional': '- 商業/管理技術人員',
  '- Legal, social, cultural or related associate professional': '- 法律/社會/文化技術人員',
  '- Information and communications technician': '- 資訊和通信技術技術人員',
  'Clerical support worker': '支持人員',
  '- General or keyboard clerk': '- 一般支持人員',
  '- Customer services clerk': '- 客服支持人員',
  '- Numerical or material recording clerk': '- 記錄人員',
  'Service or sales worker': '服務及銷售人員',
  '- Personal service worker': '- 個人服務人員',
  '- Sales worker': '- 銷售人員',
  '- Personal care worker': '- 個人護理人員',
  '- Protective services worker': '- 安保人員',
  'Skilled agricultural, forestry or fishery worker': '農林漁業從業人員',
  '- Market-oriented skilled agricultural worker': '- 農業從業人員（市場導向）',
  '- Market-oriented skilled forestry, fishing or hunting worker': '- 林漁業從業人員（市場導向）',
  '- Subsistence farmer, fisher, hunter or gatherer': '- 務農、畜牧、捕魚業者',
  'Craft or related trades worker': '工藝及有關人員',
  '- Building or related trades worker, excluding electrician': '- 建築有關人員',
  '- Metal, machinery or related trades worker': '- 金屬和機械有關人員',
  '- Handicraft or printing worker': '- 手工藝和印刷有關人員',
  '- Electrical or electronic trades worker': '- 電子和電器有關人員',
  '- Food processing, wood working, garment or other craft or related trades':
    '- 食品加工/木材/服飾和其他手工藝有關人員',
  'Plant or machine operator, or assembler': '機械機床操作員和裝配工',
  '- Stationary plant or machine operator': '- 機械機床操作員',
  '- Assembler': '- 組裝員',
  '- Driver or mobile plant operator': '- 駕駛和移動機械操作員',
  'Elementary occupation': '非技術人員',
  '- Cleaner or helper': '- 清潔有關人員',
  '- Agricultural, forestry or fishery labourer': '- 農林漁業勞動者',
  '- Labourer in mining, construction, manufacturing or transport': '- 採礦/建築/製造/運輸有關人員',
  '- Food preparation assistant': '- 食品準備有關人員',
  '- Street or related sales or service worker': '- 街頭工作有關人員',
  '- Refuse worker or other elementary worker': '- 垃圾處理有關人員',
  'Armed forces occupation': '軍人',
  '- Commissioned armed forces officer': '- 部隊軍官',
  '- Non-commissioned armed forces officer': '- 非士官',
  '- Armed forces occupation, other rank': '- 部隊其他職位',
  'Local Government/Authority': '高級經理',
  'Private, Corporate ': '中級經理',
  'Public service, Fed/State Govt': '初級經理或主管',
  'State Owned Corporation': '普通員工',
  Unknown: '不適用職級分類',
  'Administrative and Support Service Activities': '衛生、社會保障和社會福利業',
  'Agriculture, Forestry and Fishing': '農、林、牧、漁業',
  'Arts, Entertainment and Recreation': '文化、體育和娛樂業',
  'Banking & Finance': '金融業',
  'Business Services': '租賃和商務服務業',
  'Architecture and Construction': '建築業',
  'Education and Training': '教育和培訓',
  'Utilities (Gas, Water, Electric, etc)': '公共事業（天然氣、水、電等）',
  Engineering: '工程製造',
  'Hospitality and Tourism': '酒店和旅遊',
  'Human Resources': '人力資源',
  'Health Sector': '健康和衛生',
  'Information and Technology': '資訊技術',
  Insurance: '保險',
  'Law Enforcement': '執法部門',
  Manufacturing: '製造業',
  'Mining and Quarrying': '採礦和採石',
  'Military & Defence': '軍事和國防',
  'Oil and Gas/Energy Exploration': '石油、天然氣和能源勘探',
  'Pharmaceutical Industry': '製藥',
  'Public Administration': '公共管理和行政',
  'Real Estate Activities': '房地產業',
  'Scientific and Technical Activities': '科學研究、技術開發業',
  Security: '安保',
  'Social Work': '水利、環境和公共設施管理業',
  Telecommunications: '通訊業',
  'Transportation and Storage': '交通運輸、倉儲和郵政業',
  Media: '媒體、傳媒',
  'Wholesale and Retail Trade': '批發和零售業',
  Arabic: '',
  'Brazilian Portuguese': '',
  'Chinese-Simplified': '中文（簡體）',
  'Chinese-Traditional': '中文（繁體）',
  Hebrew: '希伯来语',
  'Hindi-Urdu': '印地语-乌尔都语',
  isiNdebele: '',
  isiXhosa: '',
  isiZulu: '',
  'Latin American Spanish': '拉丁美洲西班牙语',
  Malayalam: '马拉雅拉姆语',
  Punjabi: '旁遮普语',
  Sepedi: '塞佩迪语 ',
  'Southern Thai': '南泰语',
  Sesotho: '塞索托语',
  Setswana: '塞茨瓦纳语',
  siSwati: '斯瓦特语',
  'Tagalog (Filipino)': '塔加洛语（菲律宾语',
  Turkmen: '土库曼语',
  Tshivenda: '茨维瑞达语',
  Xitsonga: '恩教沙语',
  Male: '男',
  Female: '女',
  'Not Specified': '未說明',

  // errorMessages.js
  'Email address is not valid': '無效的電子郵箱',

  // table bulkActions assessments.js
  'Edit battery': '編輯成套測驗',
  // table singleRowActions competency.js
  'Add competency': '添加勝任力',
  'Edit competency category': '編輯勝任力大類',
  'Delete competency category': '刪除勝任力大類',
  'Edit competency': '編輯勝任力',
  'Delete competency': '刪除勝任力',
  'Edit question': '編輯問題',
  'Delete question': '刪除問題',

  // table singleRowActions competencyGroup.js
  'Delete competency group': '刪除勝任力組',
  'Go to competency groups details': '查看勝任力組信息',

  // table singleRowActions  group.js
  'Invite respondents to questionnaire': '邀請受測者完成測評',
  'Administer answer sheet': '答題紙',
  'Administer managed group': '開放組',
  'Create an ideal profile based on selected group': '根據所選分組創建理想剖析圖',
  'Create a Norm based on selected group': '根據所選分組創建常模',
  'View or edit': '查看或編輯',
  Delete: '刪除',

  // table singleRowActions invites.js
  Remind: '提示：',

  // table singleRowActions projects.js
  'Add to favorites': '添加到我的最愛',
  View: '視圖',
  'Delete Project': '刪除項目',

  // table singleRowActions raters.js
  'Delete rater': '刪除評分者',

  // table singleRowActions reports.js
  Open: '打開',
  Download: '下載',

  // table singleRowActions respondents.js
  'Administer questionnaire': '監督測驗',
  'Invite to questionnaire': '遠程邀請測驗',
  'Add tag': '添加標籤',

  // table singleRowActions session.js
  'Invite raters who have not yet complete': '向未完成的評估者發送邀請',
  'Invite who have not yet been invited': '向未邀請的評估者發送邀請',
  'Send reminder to raters who have already been invited but have not yet completed': '向未完成的評估者發送提醒',
  'Invite to specify own rater': '邀請被評估人自行指定評估者',
  'Send invitation to self': '向被評估人發送邀請',
  'Edit session': '編輯評估組',

  // table bulkActions assessments.js
  'Add custom battery': '添加成套測評',
  'Edit assessment': '編輯成套測評',
  'Delete Assessment(s)': '刪除成套測評',

  // table bulkActions groups.js
  'Create a Norm based on the selected group(s)': '根據所選分組創建常模',
  createIdealProfileBasedOnSselectedGroup: '根據所選分組創建理想剖析圖',
  'Create an Ideal profile based on the selected group(s)': '根據所選分組創建理想剖析圖',
  'Delete selected group(s)': '刪除所選分組',
  'Generate an open group link': '創建開放組連結',

  // table bulkActions idealProfiles.js
  'Edit ideal profile': '編輯理想剖析圖',

  // table bulkActions invites.js
  'Copy invite link to clipboard': '將邀請連結複製到剪貼板',
  'Delete invites': '刪除測評邀請',

  // table bulkActions projects.js
  'Edit project details': '編輯專案資訊',
  'Delete projects(s)': '刪除項目',
  // table bulkActions raters.js
  'Send invite to selected rater(s)': '向所選評估者發送邀請',
  'Remove selected rater(s) from session': '從評估組中刪除所選評估者',

  // table bulkActions reports.js
  'Delete selected report(s)': '刪除所選報告',
  'Download selected report(s)': '下載所選報告',
  'View selected report(s)': '查看所選報告',

  // table bulkActions respondentGroups.js
  'Remove respondent from selected group(s)': '從所選分組中移除受測者',
  // table bulkActions respondents.js
  'Administer assessment': '監督測驗',
  'Administer assessment for': '監督測驗',
  'Invite respondent(s) to assessment': '邀請受測者參與評估',
  'Request report(s)': '請求報告',
  'Go to respondent details': '查看受測者信息',
  'Manage tags for selected respondent(s)': '管理選定受測者的標籤',
  'Add new group from selection': '添加至新分組',
  'Add to existing group': '添加到現有組',
  'Delete selected respondents from group': '從分組中刪除受測者',
  'Delete selected respondents': '刪除所選受測者',
  'this respondent': '所選的受測者',
  'selected respondents': '所選的受測者',

  // table bulkActions respondentsTest.js
  'Delete selected result(s)': '刪除所選結果',

  // table bulkActions sessions.js
  'Invite to specify own raters': '邀請被評估者自行指定評估人',
  'Delete Session(s)': '刪除評估組',

  // table bulkActions templates.js
  'Go to template details': '查看範本資訊',
  'Delete selected template(s)': '刪除所選範本',
  // table bulkActions welcomeText.js
  'Go to welcome text details': '查看歡迎辭',
  'Delete selected welcome text(s)': '刪除所選歡迎辭',
  // table entitiesDropdownActions groupDetails.js
  'Edit title': '編輯標題',
  'Duplicate 360 assessment': '複製360度評估',
  'Delete 360 assessment': '刪除360度評估',
  // table entitiesDropdownActions sessions.js
  'Invite raters who have not yet completed': '邀請未完成的評估者',

  // table tableActions competencyGroupsDetails.js
  'Review competencies': '查看勝任力',

  // table tableActions customGroups.js
  'Add new 360 assessment': '添加新360度評估',

  // table tableActions raters.js
  'Add rater': '添加評估者',
  // table tableActions respondents.js
  'Import respondents': '批量添加受測者',

  // table tableActions templates.js
  'Add new template': '添加新範本',
  'Edit template': '編輯範本',
  'Edit welcome text': '編輯歡迎辭',
  // QuestionOrder.js
  Default: '默認排序',
  Custom: '自訂排序',
  Random: '隨機排序',
  // tables columns sessions.js
  Invited: '已邀請',
  'Invite Not Sent': '邀請未發送',
  'Invite not sent': '邀請未發送',

  'Show expired': '顯示已過期邀請',
  'Sorry we could not find any': '抱歉，我們找不到任何 %{entityName}',
  // 'Sorry, we could not find any': '暫無記錄 %{entityName}',
  'Sorry, we could not find any respondents': '暫無組成員',
  'Sorry, we could not find any reports': '暫無記錄',
  'Sorry, we could not find any results': '暫無記錄',
  'Sorry, we could not find any invites': '暫無記錄',
  'Sorry, we could not find any records': '暫無記錄',
  'Sorry, we could not find any projects': '暫無記錄',
  'Sorry, we could not find any tests': '暫無記錄',
  'Sorry, we could not find any groups': '暫無記錄',
  'Sorry, we could not find any notifications': '暫無記錄',
  'Sorry, we could not find any raters': '暫無記錄',
  'Sorry, we could not find any templates': '暫無記錄',
  'Sorry, we could not find any sessions': '暫無記錄',
  'Sorry, we could not find any welcome texts': '暫無記錄',
  'Sorry, we could not find any competency groups': '暫無記錄',
  'Sorry, we could not find any norms': '暫無記錄',
  'Sorry, we could not find any response scales': '暫無記錄',
  'Number of items': '問題數',
  Categories: '勝任力類別',
  'User name': '用戶名',
  'Update amount': '數量',
  Members: '成員',
  members: '成員',
  'Invitation Date': '邀請日期',
  'Respondent Name': '受測者姓名',
  Report: '報告',
  'Date submitted': '提交日期',
  Status: '狀態',
  'Not invited': '未邀請',
  'Not completed': '未完成',
  'Completed Date': '完成日期',
  'Direct Report': '下屬',
  Progress: '完成進度',
  'Assessments Done': '完成的測評',
  'Add new project': '添加新項目',
  Date: '日期',
  'Loading entities': '載入中……',
  Created: '創建',
  'No options': '暫無記錄',
  createOption: '新建標籤',
  '**Default': '**默認（無歡迎辭）',
  // Settings Account ChangePassword.js

  'Passwords must match': '密碼必須保持一致',
  'Change password': '更改密碼',
  'Confirm new password': '確認新密碼',
  'New password': '新密碼',
  Change: '確認更改',
  'Pins must match': 'PIN碼必須保持一致',
  'Change pin': '更改PIN碼',
  'Confirm new pin': '確認新PIN碼',
  'New pin': '新PIN碼',
  'Total:': '已添加信用點總數：',
  'Loading credits history...': '載入中……',
  'From date': '開始日期',
  'To date': '截止日期',
  Pin: 'PIN碼',
  'Receive email notifications': '啟用系統提醒郵件',
  'Complete Status': '完成狀態',
  'Invite Status': '邀請狀態',
  'Not Invited': '未邀請',
  'Sessions count': '評估組數量',

  // ImportSessions
  'Import sessions': '批量添加評估組',
  prepare: '準備導入文件',
  preview: '預覽',
  import: '導入',
  Import: '導入',
  'Prepare a csv file yourself or': '您可以自行創建csv文檔，或者',
  'download the sample import file': '點此連結下載樣例csv文檔',
  'Make sure the data order is set out correctly': '請確保csv文檔格式正確（可參考樣例文檔）',
  'Upload the file': '上傳文件',
  'Drag and drop file here or browse the file': '請將準備好的csv文檔拖到此處，或點擊下面的按鈕手動選擇檔',
  'Upload the csv file to import respondents': '上傳csv文檔批量添加受測者',
  'The first line contains field headers': '數據包含標題',
  'Choose file': '選擇檔',

  // AccountTabs
  general: '基本設置',
  'credit updates': '信用點統計',
  'Reports Stats': '報告使用統計',
  'reports stats': '報告使用統計',
  'assessments stats': '測評工具使用統計',

  // ReportUsage
  total: '合計: %{total}',
  'Report name': '報告',
  'Usage count': '數量',
  'Assessment name': '測評工具',
  Total: '總計',
  'Loading requested reports': '載入中',
  'Loading used assessments': '載入中',

  // Toasts & system notifications
  'Tag(s) have been deleted': '標籤已刪除',
  'Loading items': '載入中',
  'Saving results': '保存中',
  'Ideal profile has been created': '理想剖析圖已創建',
  'Copy of 360 survey has been created': '360度評估已複製',
  groupCreationToast: '新組已添加',
  'Tag(s) have been updated': '標籤已更新',
  'Welcome text has been saved': '歡迎辭範本已保存',
  'Welcome text has been updated': '歡迎辭範本已更新',

  // Toasts
  respondentsDeletionToast: '受测者已删除',
  resultsSuccessToast: '%{respondentInfo}数据已保存',
  surveyDeletionCancelToast: '已被360度评估项目调用的评估无法删除',
  creditBalanceUpdateToast: '信用点已添加',
  correctDataToast: '请确保已正确添加所有数据，',
  downloadLimitToast: '不能同时下载超过 50 个报告',
  categoryUpdateToast: '类别已更新',
  newCategoryToast: '添加了新类别',
  createdRequestToast: '新报告请求',
  respondentCreationToast: '已添加受测者',
  respondentUpdateToast: '受访者已更新',
  clipboardCopyToast: '已成功复制到剪贴板',
  addedAssessmentToast: '已添加评估',
  deletedAssessmentToast: '评估已被删除',
  completedAssessmentToast: '%{respondentName} 完成 %{assessment}',
  importRespondentsErrorToast: '导入受测者过程中出现问题',
  importSessionsErrorToast: '导入会话时出错',
  competencyTitleUpdateToast: '标题已更新',
  competencyUpdateToast: '胜任力已更新',
  newCompetencyToast: '已添加新胜任力',
  questionUpdateToast: '问题已更新',
  questionAdditionToast: '问题已添加',
  groupLinkErrorToast: '生成组链接时出错，请重试',
  reportsDeletionToast: '报告已被删除',
  resultsSavingErrorToast: '保存结果时出错 %{respondentName} %{assessment}',
  reportRequestCreationToast: '已创建报告请求',
  assessmentDataFailToast: '无法加载评估数据',
  scaleUpdatedToast: '应答量表已更新',
  scaleCreatedToast: '应答量表已保存',
  scaleDeletionToast: '应答量表已被删除',
  surveyUpdatedToast: '360度全景调查已更新',
  normsDeletionToast: '常模已被删除',
  'Deletion blocked': '删除已阻止',
  groupCreationErrorToast: '组创建期间出错',
  groupsDeletionToast: '组已被删除',
  reportsGenerationToast: '已成功请求报告',
  invitesDeletionToast: '邀请已删除',
  normCreationToast: '常模已被创建',
  normCreationErrorToast: '无法创建常模，请重试',
  projectDeletionToast: '项目已被删除',
  projectsDeletionToast: '项目已被删除',
  projectCreationToast: '项目已创建',
  projectUpdateToast: '项目已更新',
  ratersDeletionToast: '评估者已被删除',
  categoryDeletionToast: '胜任力能力类别已删除',
  resultsDeletionToast: '结果已被删除',
  tagsAdditionToast: '已添加标记',
  addedToGroupToast: '已成功添加到组',
  questionnaireSessionErrorToast: '无法创建问卷会话，请稍后重试',
  sessionAdditionToast: '已添加会话',
  ratersAdditionToast: '已添加会话评估者',
  competencyDeletionToast: '能力已被删除',
  questionDeletionToast: '问题已被删除',
  assessmentsDeletionToast: '评估已被删除',
  profilesDeletionToast: '理想剖析图已被删除',
  profileUpdateToast: '理想剖析图已更新',
  templatesDeletionToast: '模板已被删除',
  templateAdditionToast: '模板已保存',
  templateUpdateToast: '模板已更新',
  welcomeTextsDeletionToast: '欢迎模板已被删除',
  batteryAdditionToast: '成套测验已创建',
  batteryUpdateToast: '成套测验已更新',
  batteriesDeletionToast: '成套测验已删除',
  batteryDeletionToast: '成套测验已被删除',
  passwordUpdateSuccessToast: '密码已成功更新',
  pinUpdateSuccessToast: 'PIN已成功更新',

  // General
  Continue: '继续',

  'Activity Log': '活動日誌',
  'Credit updates': '信用點購買記錄',
  'Credit Updates': '信用點購買記錄',
  General: '常規',
  here: '這裡',
  History: '歷史記錄',
  New: '新增',
  'no messages': '暫無消息',
  'no new messages': '暫無新消息',
  'Norm Name': '常模名稱',
  reportFormatUpdateFailureToast: '報告格式未更新',
  reportFormatUpdateSuccessToast: '報告格式已成功更新',
  'reports requested': '已生成的報告',
  'tests completed': '測評完成',
  'the previous notification area has been moved': '上一個通知區域已移動',
  'Selected respondent(s) can’t be invited, since one or several of them have already been invited':
    '無法邀請選定的受測者，因為其中一個或多個受測者已被邀請',
  'Copy invite link': '複製邀請連結',
  'Invite link can be copied only for not completed projects': '邀請連結只能複製未完成的專案',
  'Invite link can be copied for 1 selected respondent at a time': '一次可以為1個選定的受測者複製邀請連結',
  'Invite link is not available, please send or download invites in the csv first':
    '邀請連結不可用，請先在csv中發送或下載邀請',
  'Send reminders': '發送提醒',
  'One or several selected respondents haven’t been invited yet': '一個或多個選定的受測者尚未被邀請',
  'Download reports': '下載報告',
  'Download report option can be available for 1 selected respondent at a time':
    '一次可以為1個選定的受測者提供下載報告選項',
  'One or several selected respondents don’t have available reports': '一個或多個選定的受測者沒有可用的報告',
  'Go to respondent profile': '轉到受測者資料',
  'Go to respondent profile option can be available for 1 selected respondent at a time':
    '“轉到受測者個人資料”選項一次僅可用於1個選定的受測者',
  'Remove respondent(s)': '刪除受測者',
  "Selected respondents can't be deleted since one or several of them already passed the assessment":
    '無法刪除選定的受測者，因為其中一個或多個已完成評估',
  'Administer Managed Group Session': '管理開放組',
  'Generate Open Group Link': '生成開放組連結',
  'Create Norm from a Group': '從組創建常模',
  'Create an Ideal Profile from a Group': '從組創建理想剖析圖',
  'Invite Respondents for Assessment': '邀請受測者進行評估',
  'Delete selected Group': '刪除所選組',
  Authorize: '授權',
  'Project rules': '專案規則',
  'Invites sending': '正在發送邀請',
  'Select assessment, language and email template you want to use': '選擇要使用的評估、語言和電子郵件範本',
  'Choose whether invites should go out on the scheduled date, get sent once respondent gets added to the project or manually with pre-selected test and template':
    '選擇是否應在預定日期發出邀請，在將受測者添加到項目後發送邀請，或者使用預先選擇的測試和範本手動發送邀請',
  'Reports generation': '生成報告',
  'Reports selected during project creation will be automatically generated after respondent submits their results':
    '在專案創建過程中選擇的報告將在受測者提交測評結果後自動生成',
  'Direct feedback report may be activated, if available for the selected test too (check for details with your local distributor)':
    '如果所選測頻工具有可用的回饋報告，則會啟動（請與當地經銷商聯繫瞭解詳細資訊',
  'Credits usage': '信用點使用情況',
  'To ensure you have enough credits to get reports generated automatically, we are freezing the required cost per respondent once they get added to the project':
    '為了確保您有足夠的信用點來自動生成報告，一旦將受測者添加到專案中，系統將凍結為他們生成報告所需的信用點數量',
  Back: '返回',
  'Country of origin': '適用國家地區',
  Mixed: '多國',
  Scale: '量表',
  Mean: '平均分',
  Histogram: '長條圖',
  age: '年齡',
  gender: '性別',
  ethnicity: '種族',
  education: '教育程度',
  occupation: '職業',
  industry: '行業',
  'first language': '母語',
  firstlanguage: '母語',
  sector: '職級',
  jobArea: '工作領域',
  Sten: '標準十分',
  Stanine: '標準九分',
  'Less than': '小於',
  'Unknown label': '未分類',
  'Send immediate feedback report to respondent': '立即向受測者發送回饋報告',
  'Send immediate feedback info':
    '評估完成後，立即通過電子郵件將回饋報告發送給受測者。這些報告是根據特定專案定制的，目的是遵循專業回饋準則。請核實如何收取信用點。',
  'General information': '一般資訊',
  summary: '概括',
  Summary: '概括',
  Inactive: '不活躍',
  Category: '類別',
  'Expire after': '過期時間為',
  'How to send invites': '如何發送邀請',
  Weekly: '每週',
  Daily: '日常的',
  Monthly: '每月',
  'Do not remind': '不提醒',
  days: '天',
  expired: '已到期',
  'End project': '結束專案',
  'Direct feedback report': '直接回饋報告',
  Manually: '手動',
  Automatically: '自動地',
  Schedule: '行程',
  Select: '選擇',
  'Invite template': '邀請模板',
  'Total cost': '總成本',
  'Cost per respondent': '每位受訪者的成本',
  'Current credits balance': '當前積分餘額',
  'Create project': '創建專案',
  'I confirm that I have read this summary and I want to proceed': '我確認我已閱讀此摘要並且我想繼續',
  Reminder: '提醒',
  'Estimated project cost': '預計專案成本',
  'Project cost': '工程造價',
  'Invite expires on': '邀請到期日',
  'Invites are sent manually': '邀請是手動發送的',
  'Use button on the right to manually send invites': '使用右側的按鈕手動發送邀請',
  'Invites are sent automatically': '邀請會自動發送',
  'Invites are automatically sent when you add respondents to the project': '當您將受訪者新增至專案時，會自動發送邀請',
  'Invites are scheduled': '邀請已安排',
  'Invites will be automatically sent on selected date': '邀請將在選定日期自動發送',
  'Project settings': '項目設定',
  invited: '邀請',
  'invites sent': '已發送邀請',
  'Invites will be sent on': '邀請函將會寄至',
  'Invites were sent on': '邀請已發送至',
  'Respondents added after scheduled date will be invited automatically': '在預定日期之後新增的受訪者將被自動邀請',
  'Invite via email': '透過電子郵件邀請',
  'Download invite links in a csv file': '下載 csv 檔案中的邀請鏈接',
  'Reports are generated after completing the assessment': '評估完成後產生報告',
  'Add respondents': '新增受訪者',
  'There are no respondents added': '沒有增加受訪者',
  "Sorry, we couldn't find any respondents": '抱歉，我們找不到任何回覆者',
  'You may start adding respondents via add or import respondents actions':
    '您可以透過新增或匯入受訪者操作開始新增受訪者',
  'Add respondents groups or respondents': '新增受訪者組或受訪者',
  'Select group or respondents to be added to this project': '選擇要新增至此項目的群組或受訪者',
  'credits per respondent will be frozen from your balance to ensure the project completion':
    '每位受訪者的積分將從您的餘額中凍結，以確保專案完成',
  'Respondents have been added': '已新增受訪者',
  Active: '積極的',
  Unavailable: '不可用',
  'There are no reports available for selected test and language': '沒有針對所選測試和語言的可用報告',
  'Please make sure you selected correct language or contact your administrator for help':
    '請確保您選擇了正確的語言或聯絡您的管理員尋求協助',
  'This field is required': '此欄位是必需的',
  'Report format': '報告格式',
  'Change report format': '更改報告格式',
  'Invite will be sent to respondents with valid email address only': '邀請將僅發送給具有有效電子郵件地址的受訪者',
  Percent: '百分',
  Undeclared: '* UNDC: 未申報',
  'Details for': '詳細資訊 %{categorieName} 類別',
  RawDetails: '原始資料詳情',
  '中文(简体) / Chinese (Simplified)': '中文(简体)',
  '中文(繁體) / Chinese (Traditional)': '中文(繁體)',
  '中文(香港) / Chinese(Traditional) HK': '中文(香港)',
  'To select multiple assessments, please create a battery in settings and select it from within the Favourites category':
    '如需要選擇多個測評工具組合，請先在設置中創建一個成套測驗，然後從我的最愛中選擇該成套測驗',
  'Administer group session': '實施開放組評估',
  'Assessment info': '測評信息',
  'Respondent to create new record': '從受測者資料創建',
  'Create norm based on the selected group(s)': '從所選的組創建常模',
  'Norm name is required': '請輸入常模名稱',
  'Assessment is required': '請選擇測評工具',
  'Ideal profile name is required': '請輸入理想剖析圖名稱',
  'Norm is required': '請選擇常模',
  'Assessment on which the ideal profile is based': '理想剖析圖所用測評工具',
  'Select norm': '請選擇常模',
  'missing emails': '無電子郵件',
  'Email invites': '電子郵件邀請',
  'You are about to end a project': '即將完成此項目',
  'All active invites will be deactivated and unused credits released. It will be not possible to revert the action':
    '所有未完成的邀請都將被停用，未使用的信用點將被釋放。本操作無法還原。',
  'You are about to send reminder to the selected respondents': '將向選定的受測者發送提醒',
  'Press “Yes” to continue': '按“是”繼續',
  'Delete 360 survey': '刪除360模型',
  Ethics: '倫理',
  'Biodata Configuration': '生物數據配置',
  'Loading biodata templates': '加载个人资料模板',
  'Add Bio Data template': '添加个人资料模板 ',
  'Add Name': '添加姓名',
  'Add description': '添加描述',
  selecteditcategoryvalues: '选择要显示给受访者的类别和值',
  'Add values to category': '为类别添加值 ',
  biodatadefaulttemplate: '设置为默认模板',
  'Delete selected biodata template(s)': '删除所选模板（们）',
  'Biodata Template has been created': '個人資料模板已建立',
  'Biodata Template has been updated': '"個人資料模板已更新',
  'Biodata template(s) deleted': '個人資料模板已刪除',
  'Default Biodata Template has been updated': '預設個人資料模板已更新',
  'Update default biodata template': '更新預設個人資料模板',
  'Are you sure you want to set this template as default?': '您確定要將此模板設為預設嗎？',
  'Delete biodata templates': '刪除個人資料模板',
  'the selected biodata templates?': ' 所選的個人資料模板?',
  'this biodata template?': ' 這個個人資料模板?',
  'There are no respondents added to this group': '尚未向此組添加任何受測者',
  'Ok, got it': '瞭解',
  'The administered group session has been created': '已創建有人監督開放組',
  'Managed group session in progress': '正在進行的開放組',
  'Session link': '開放組連結',
  'No respondents to display': '無受測者',
  "You may add respondents to the group from the existing respondents' list, or they can create new records during the session authorization via the session link":
    '可以從現有受測者列表中將受測者添加到組中，也可以在開放組授權期間通過連結創建新記錄',
  'Link copied successfully': '已成功複製連結',
  'Respondent sessions': '受測者組',
  'End session': '關閉開放組',
  'Do you want to close the session? Assessments that have started may still be completed but no new respondents may be added':
    '是否要關閉開放組？已開始的測評仍可完成，但無法添加新的受測者。',
  'You are about to launch a managed group session. While the session is active, actions with the group will be limited':
    '將要啟用有人監督開放組。當啟用後，部分組操作將受到限制。',
  Expired: '已過期',
  'Reset assessment deadline': '重設評估截止日期',
  downloadInvitesReport: '下載 CSV',
  Id: '身分证',
  DOB: '出生日期',
  'There are no invites in this session yet': '尚无邀请信',
  'There are no results in this session yet': '尚无数据结果',
  'There are no reports in this session yet': '尚无报告',
  'You are about to send an invite to all respondents in the project': '将向项目中的所有受测者发送邀请',
  'Press “Yes” to proceed with the action': '按“是”继续操作',
  'You are about to send invites to selected respondents': '将要向选定的受测者发送邀请',
  'There are no sessions in this project': '此项目中还未加入任何组',
  'You may start creating sessions via add or import sessions actions': '通过添加或导入功能创建组',
  'Number of raters': '评估者数量',
  'Import complete': '导入已完成',
  'Records imported': '已导入的记录',
  Azerbaijani: '阿塞拜疆语',
  'Chinese(Simplified)': '中文（简体）',
  'Chinese(Traditional)': '中文（繁体）',
  'Chinese(Traditional) HK': '中文（繁体）香港',
  'American Spanish': '美式西班牙语',
  Hindi: '印地语',
  Slovenian: '斯洛文尼亚语',
  'Portuguese-Brazilian': '葡萄牙语-巴西语',
  Urdu: '乌都语',
  Montenegrian: '黑山',
  Mongolian: '蒙古语',
  'English(United States)': '英文（美国）',
  'Administer answer sheet for selected respondents': '为选定的受测者启动答题卡输入',
  'Invite respondent(s)': '邀请受测者',
  'Remove respondents': '删除受访者',
  'Are you sure you want to remove selected respondents?': '您确定要删除选定的受访者吗？',
  Update: '更新',
  updateAssessmentInvitationToast: '实际邀请',
  updateProjectInvitationToast: '实际邀请',
  biodataTooltip: '生物数据模板允许从受访者那里收集定制的传记数据。在“设置”区域中创建生物数据模板',
  DEFAULT: '默认',
  'Bio data template': '生物数据模板',
  defaultCompleteBioTemplate: '** 所有生物数据模板（包含所有类别）**',
  defaultEmptyBioTemplate: '** 空白生物数据模板（不包含任何类别） **',
  selectNormForReport: '選擇報告的標準',
  updateGroupToast: '群組已更新',
  updateGroupErrorToast: '更新群組時發生錯誤',
  'Edit Group Details': '編輯群組詳細資料',
  'Duplicate Group': '複製群組',
  fullName: '全名',
  email: '電子郵件',
  inviteURL: '邀請連結',
  testID: '測試',
  csvReportName: '邀請報告 %{date}.csv',
  hideAllNormsWarning: '您將隱藏所有規範',
  hideAllNormsWarningDescription: '這可能會在生成報告時導致意外結果',
};
export default translations;
