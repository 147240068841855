import React from 'react';
import { func, bool, string, oneOfType, arrayOf, node } from 'prop-types';
import Rodal from 'rodal';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

import CloseIcon from './CloseIcon';

const NewModal = ({
  children,
  isVisible,
  title,
  onClose,
  animation,
  isTitleHidden,
  className,
  isPaddingless,
  isHeaderHidden,
}) => {
  return (
    <Rodal
      visible={isVisible}
      onClose={onClose}
      animation={animation}
      showCloseButton={false}
      customStyles={{
        width: 'auto',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        overflow: 'visible',
      }}
    >
      <Container className={className} isPaddingless={isPaddingless}>
        {!isHeaderHidden && (
          <Header isTitleHidden={isTitleHidden} isPaddingless={isPaddingless}>
            {!isTitleHidden && <Title>{I18n.t(title)}</Title>}
            <CloseIcon clickHandler={onClose} />
          </Header>
        )}
        {children}
      </Container>
    </Rodal>
  );
};

NewModal.propTypes = {
  isVisible: bool.isRequired,
  onClose: func.isRequired,
  children: oneOfType([arrayOf(node), node]).isRequired,
  animation: string,
  className: string,
  title: string,
  isTitleHidden: bool,
  isPaddingless: bool,
  isHeaderHidden: bool,
};

NewModal.defaultProps = {
  animation: 'fade',
  className: '',
  isTitleHidden: false,
  isPaddingless: false,
  title: '',
  isHeaderHidden: false,
};

const Container = styled.div`
  margin: auto 0;
  min-width: 60rem;
  max-height: 80rem;
  padding: ${(props) => (props.isPaddingless ? 0 : '2.4rem')};
  flex-direction: column;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 1.2rem;
  box-shadow: 0 3px 32px rgba(0, 0, 0, 0.14);
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.isTitleHidden ? 'flex-end' : 'space-between')};
  margin-bottom: 2.4rem;
  padding: ${(props) => (props.isPaddingless ? '2.4rem 2.4rem 0 2.4rem' : 0)};
`;

const Title = styled.h3`
  font-size: 2rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.darkBlue2};
`;

export default NewModal;
