const translations = {
  About: 'About',
  Launch: 'Launch',
  Tutorial: 'Tutorial',
  SignIn: 'Sign in',

  // Login Page (Login.js)
  Email: 'Email',
  Submit: 'Submit',
  LoginNext: 'Login',
  LoginSubmit: 'Continue',
  SwitchUser: 'Change user',
  ForgotPassword: 'Forgot password',
  Password: 'Password',
  'Email is required': 'Email is required',
  'Password is required': 'Password is required',
  'Should be a proper email address': 'Should be a proper email address',

  // (AddCompetencyCategory.js)
  Save: 'Save',
  'Enter Name': 'Enter Name',
  'Add competency category': 'Add competency category',
  'Add description for the category': 'Add description for the category',

  // (CompetencyGroupNavbar.js)
  'Main info': 'Main info',

  // (AddCompetencyGroup.js)
  Add: 'Add',
  Next: 'Next',
  'Name is required': 'Name is required',
  'Add 360 assessment': 'Add 360 assessment',
  'Custom competencies': 'Custom competencies',
  'Inbuilt competencies': 'Inbuilt competencies',

  // (AddCompetencyItem.js)
  Edit: 'Edit',
  'Competency description': 'Competency description',

  // (AddCompetencyQuestion.js)
  'Add question': 'Add question',

  // (AddCompetencyQuestionForm.js)
  'Item text': 'Item text',
  'Free field': 'Free field',
  'Multiple choice': 'Multiple choice',
  'Add another language': 'Add another language',

  // (AddCreditsFailureModal.js)
  'Credits addition error': 'Credits addition error',
  'Try again': 'Try again',

  //  (AddCreditsSuccessModal.js)
  Done: 'Done',
  'Adding credits': 'Adding credits',
  'Credits successfully added!': 'Credits successfully added!',
  'Now your credit balance is:': 'Now your credit balance is:',

  // (AddCredits.js)
  Cancel: 'Cancel',
  'Add credits': 'Add credits',
  'Your credits balance': 'Your credits balance',
  'How many credits do you want to add?': 'How many credits do you want to add?',
  'Purchase ID': 'Purchase ID',
  'Error occurred during an attempt to add credits to your account':
    'Error occurred during an attempt to add credits to your account',
  'Credits amount': 'Credits amount',

  // Edit Respondent (Index.js)
  Groups: 'Groups',
  Invites: 'Invites',
  Reports: 'Reports',
  Assessments: 'Assessments',
  IAssessments: 'Assessments',
  SAssessments: 'Assessments',
  'Respondent Information': 'Respondent Information',

  // Edit Respondent (AddTagModal.js)
  'Add tags': 'Add tags',
  'Select tags': 'Select tags',

  // Edit Respondent (Information.js)
  Sex: 'Sex',
  BioTitle: 'Title',
  Title: 'Title',
  Sector: 'Sector',
  Company: 'Company',
  Industry: 'Industry',
  Education: 'Education',
  Reference: 'Reference',
  Ethnicity: 'Ethnicity',
  'Job area': 'Job area',
  'First Name': 'First Name',
  'Family Name': 'Family Name',
  'Save changes': 'Save changes',
  'Date of Birth': 'Date of Birth',
  'First Language': 'First Language',
  'Additional Info': 'Additional Info',
  'Main Information': 'Main Information',

  // Edit Respondent (RespondentGroups.js)
  group: 'group',
  Group: 'group',
  'Remove from groups': 'Remove from groups',
  'Are you sure you want to remove respondent from selected group(s)':
    'Are you sure you want to remove respondent from selected group(s)',

  // Edit Respondent (RespondentInvites.js)
  invite: 'invite',
  'Invite Deletion': 'Invite Deletion',

  // Edit Respondent (RespondentReports.js)
  'Report deletion': 'Report deletion',
  'Are you sure you want to delete selected report?': 'Are you sure you want to delete selected report?',

  // Edit Respondent (RespondentTests.js)
  'Test deletion': 'Test deletion',
  'Are you sure you want to delete selected test?': 'Are you sure you want to delete selected test?',

  // Edit Respondent (TagsManagement.js)
  Tags: 'Tags',
  '+ Add tag': '+ Add tag',
  'Members count': 'Members count',
  member: 'member',

  // Groups (AddNewGroup.js)
  Create: 'Create',
  'Group name': 'Group name',
  'Add new group': 'Add new group',
  'Group description': 'Group description',

  // Groups (GroupHeader.js)
  Type: 'Type',
  'Creation date': 'Creation date',

  // Groups (Groups.js)
  List: 'List',
  Respondents: 'Respondents',
  'Delete group': 'Delete group',
  'Are you sure you want to delete this group(s)': 'Are you sure you want to delete this group(s)',

  // Manage active links
  'Manage active links': 'Manage active links',
  'Manage active group links': 'Manage active group links',

  // Groups request report
  'Select common assessment / battery': 'Select common assessment / battery',
  reports: 'reports',

  // Groups generate open group link
  link: 'link',
  Generate: 'Generate',
  'The link has been successfully generated': 'The link has been successfully generated',

  // Invites (Invites.js)
  'Delete invite': 'Delete invite',
  'selected invites?': 'selected invites?',
  'this invite?': 'this invite?',

  // LaunchScreen (LaunchScreen.js)
  Language: 'Language',
  language: 'Language',

  // Notifications (Notifications.js)
  Results: 'Results',
  notification: 'notification',
  Notifications: 'Notifications',

  // Projects (AddProjectsModal.js)
  Min: 'Min',
  Info: 'Info',
  Raters: 'Raters',
  Deadline: 'Expiry Date',
  'Peer Alias': 'Peer Alias',
  Description: 'Description',
  'Project Name': 'Project Name',
  'All standard': 'All standard',
  'Edit Project': 'Edit Project',
  'Manager Alias': 'Manager Alias',
  'Project Alias': 'Project Alias',
  'Direct Report Alias': 'Direct Report Alias',
  'Choose Project Type': 'Choose Project Type',
  '360 assessment has to be fully supported in at least 1 language':
    '360 assessment has to be fully supported in at least 1 language',
  '360 Survey': '360 Survey',
  '360 Surveys': '360 Surveys',
  'Other Alias': 'Other Alias',
  deadlineWarning: 'Date, when reports should be generated.',
  createAssessmentBattery:
    'To select multiple assessments, please create a battery in settings and select it from within the Favourites category',
  manualInviteDesc: 'Invites can be sent manually from respondents grid on project page.',
  autoInviteDesc: 'Invite will be sent each time you add a new respondent to the project.',
  scheduledInviteDesc:
    'Invites will be sent on selected date. If you will add new respondents after this\n' +
    'date, invites will be sent automatically when you add them.',
  daysLeft: '%{days} days left',

  // Projects (projectList.js)
  'Add Assessment Project': 'Add Assessment Project',
  'Add 360 Appraisal Project': 'Add 360 Appraisal Project',
  'Manage your assessment projects here Plan and implement the right assessment approach and tests Effectively and consistently administrate, communicate and manage the whole assessment process using this user friendly tool':
    'Manage your assessment projects here. Plan and implement the right assessment approach and tests. Effectively and consistently administrate, communicate and manage the whole assessment process using this user friendly tool.',
  'Manage your entire 360 appraisal process here Easy to use, allowing appraisals to be set up in minutes and results generated instantly Choose from Psytech existing competency framework or fully customise your 360 project': `Manage your entire 360 appraisal process here. Easy to use, allowing appraisals to be set up in minutes and results generated instantly. Choose from Psytech's existing competency framework or fully customise your 360 project.`,

  // Projects (Projects.js)
  All: 'All',
  Project: 'Project',
  Projects: 'Projects',
  'this project?': 'this project?',

  '360 Appraisal': '360 Appraisal',
  'Delete project': 'Delete project',
  'selected projects?': 'selected projects?',
  'Are you sure you want to delete': 'Are you sure you want to delete',

  // Raters (Raters.js)
  rater: 'rater',
  Rater: 'Rater',
  'Delete raters': 'Delete raters',
  'Loading raters': 'Loading raters',
  'selected raters?': 'selected raters?',
  'this rater?': 'this rater?',

  // Raters (AddRatersModal.js)
  Role: 'Role',
  Other: 'Other',
  Peers: 'Peers',
  Confirm: 'Confirm',
  Managers: 'Managers',
  'No email': 'No email',
  'Add raters': 'Add raters',
  'Direct reports': 'Direct reports',
  'Search existing records': 'Search existing records',
  'Make sure selected record has a valid email address added':
    'Make sure selected record has a valid email address added',
  Session: 'Session',
  'Session for': 'Session for',

  // Raters Invites (columns.js)
  Name: 'Name',
  PersonName: 'Name',
  'Expiry Date': 'Expiry Date',
  'Invite status': 'Invite status',
  'Email Address': 'Email Address',
  'Hide expired': 'Hide expired',

  // Raters Invites (Invites.js)
  'chosen invite(s)?': 'chosen invite(s)?',
  'selected invite(s)?': 'selected invite(s)?',
  'Loading invites': 'Loading invites',
  missingEmailError: 'Email address is missing or not valid in respondent(s) record(s)',

  // Raters Reports (Reports.js)
  Pending: 'Pending',
  Unread: 'Unread',
  Viewed: 'Viewed',
  Error: 'Error',
  'Support ticket raised': 'Error. Support ticket raised.',
  'Loading reports': 'Loading reports',

  // Raters Results (Results.js)
  result: 'result',

  // Reports Reports (Reports.js)
  'Are you sure you want to delete selected report(s)?': 'Are you sure you want to delete selected report(s)?',

  // RequestReport360 (RequestReport360.js)
  'Report Request for': 'Report Request for',
  'Report Request': 'Report Request',
  credits: 'credits',
  'Total Credits:': 'Total Credits:',
  Request: 'Request',
  Self: 'Self',

  // Respondents AddRespondentModal (AddRespondentForm.js)
  'Company Name': 'Company Name',
  'Job Area': 'Job Area',
  Remove: 'Remove',

  // Respondents AddRespondentModal (AddRespondentModal.js)
  'Add respondent': 'Add respondent',
  'Main Details': 'Main Details',
  'Extra Details': 'Extra Details',
  'Group Membership': 'Group Membership',

  // Respondents AddRespondentModal (GroupsTab.js)
  'Select groups': 'Select group',

  // Respondents AdministerAssessment (index.js)
  Start: 'Start',
  Administer: 'Administer',
  to: 'to',
  questionnaires: 'questionnaires',
  reasoning: 'reasoning',
  solutions: 'solutions',
  Solutions: 'Solutions',
  batteries: 'batteries',
  favourites: 'favourites',
  other: 'other',

  // Respondents (AnswersStep.js)
  'Test:': 'Test:',

  // Respondents AnswerSheet (index.js)
  Previous: 'Previous',
  'Administer answer sheet for': 'Administer answer sheet for',
  answers: 'answers',
  Respondent: 'Respondent',

  // Respondents (AssessmentStep.js)
  'Welcome text': 'Welcome text',
  'Expiry date': 'Expiry date',

  // Invite (index.js)
  'Invite respondents': 'Invite respondents',
  Invite: 'Invite',
  'Create invite and go next': 'Create invite and go next',
  Finish: 'Finish',
  'You are sending an invitation for:': 'You are sending an invitation for:',
  in: 'in',
  invitation: 'invitation',
  OR: 'OR',

  // Invite (InvitationStep.js)
  Immediately: 'Immediately',
  Later: 'Later',
  'Email address is missing or not valid in respondent record':
    'Email address is missing or not valid in respondent record',
  'Copy link': 'Copy link',
  Copied: 'Copied',
  'Copy and share generated link': 'Copy and share generated link',
  'Send invitation link via email to:': 'Send invitation link via email to:',
  'Send invitation link via email for respondents':
    'Send invitation link via email for %{respondentsCount} respondents',
  'Email template': 'Email template',
  'When do you want to send an invite?': 'When do you want to send an invite?',
  'Email invite': 'Email invite',
  'Email sent': 'Email sent',

  // RequestReportModal (index.js)
  'Request report': 'Request report',
  'Request report for': 'Request report for',
  for: 'for',
  'Request Reports': 'Request Reports',
  'Common tests: ': 'Common tests: ',
  'At least 1 assessment must be common': 'At least 1 assessment must be common',
  'Selected respondents': 'Selected respondents',
  tests: 'tests',
  'Assessment/Battery': 'Assessment/Battery',
  'Total credits:': 'Total credits',
  emptyLanguageReports: 'Sorry, we could not find any reports available for this language',

  // RequestReportModal (ReportsList.js)
  'Available Reports': 'Available Reports',
  Norm: 'Norm',
  Options: 'Options',
  'Ideal Profile': 'Ideal Profile',

  // Respondents (AddToGroup.js)
  'Add to group': 'Add to group',
  'Add respondent(s) to group': 'Add respondent(s) to group',

  // Respondents (ManageTagsModal.js)
  'Manage respondents tags': 'Manage respondents tags',
  'Select from existing tags or add new': 'Select from existing tags or add new',
  'Add tag to selected respondent(s)': 'Add tag to selected respondent(s)',
  'Remove tag from selected respondent(s)': 'Remove tag from selected respondent(s)',

  // Respondents  (RespondentDropdown.js)
  'Add new respondent': 'Add new respondent',
  'Import respondents from file': 'Import respondents from file',
  'Edit respondent': 'Edit respondent',
  'Delete respondent': 'Delete respondent',

  // Respondents  (Respondents.js)
  'Delete respondents from group': 'Remove respondents from group',
  'Loading respondents': 'Loading respondents',
  'Are you sure you want to remove': 'Are you sure you want to remove',
  'from the group?': 'from the group?',
  'All respondents are selected': 'All %{respondentsCount} respondents are selected',
  'Clear selection': 'Clear selection',
  'All respondents on this page are selected': 'All %{respondentsCount} respondents on this page are selected',
  'Select all respondents in this result': 'Select all %{respondentsCount} respondents in this result',

  // Reusable Accordion (Accordion.js)
  INBUILT: 'INBUILT',

  // Reusable Accordion (CompetencyGroupsAccordion.js)
  'Collapse all': 'Collapse all',
  'Expand all': 'Expand all',

  // Reusable  Filter (DateInputGroup.js)
  'Date range': 'Date range',

  // Reusable  Filter (Filter.js)
  Filter: 'Filter',
  'Clear all': 'Clear all',
  Apply: 'Apply',

  // Reusable Filter (TextInputGroup.js)
  'Filter type': 'Filter type',

  // Reusable Filter (Search.js)
  Search: 'Search',

  // Reusable Badges (CustomBadge.js)
  CUSTOM: 'CUSTOM',

  // Reusable TextEditor (Editor.js)
  'Insert variable': 'Insert variable',

  // Reusable  (ConfirmationModal.js)
  No: 'No',
  Yes: 'Yes',

  // Reusable  (DatePicker.js)
  'Select the date': 'Select the date',

  // Reusable  (DateTimeSelect.js)
  'Time:': 'Time:',
  Time: 'Time',

  // Reusable  (ErrorMessage.js)
  'Oops, something went wrong': 'Oops, something went wrong',
  'Please try again': 'Please try again',
  Ok: 'Ok',

  // Reusable (Quantity.js)
  Quantity: 'Quantity',

  // Reusable (StatusCell.js)
  'In Progress': 'In Progress',
  'On Hold': 'On Hold',
  Canceled: 'Canceled',
  Completed: 'Completed',
  completed: 'Completed',

  // Session SessionInvite (index.js)
  'Send invite to raters': 'Send invite to raters',
  'Send invite to raters who have not yet completed': 'Send invite to raters who have not yet completed',
  'Send invite to raters who have not yet been invited': 'Send invite to raters who have not yet been invited',
  'Send invite to raters who have already been invited but haven&apos;t yet completed':
    'Send invite to raters who have already been invited but haven&apos;t yet completed',
  'Welcome text for Self Raters': 'Welcome text for Self Raters',
  'Email template for Self Raters': 'Email template for Self Raters',
  'Welcome text for Other Raters': 'Welcome text for Other Raters',
  'Email template for Other Raters': 'Email template for Other Raters',
  Send: 'Send',

  // Session SessionInvite (SessionInvitationStep.js)
  'Email body': 'Email body',
  'Email subject': 'Email subject',
  'Invitation date': 'Invitation date',
  'Email body for Self Raters': 'Email body for Self Raters',
  'Email body for Other Raters': 'Email body for Other Raters',
  'Email subject for Other Raters': 'Email subject for Other Raters',
  'Email subject for Self Raters': 'Email subject for Self Raters',

  // Session SpecifyOwnRatersInvite (index.js)
  'Send invite to self with a request to specify their own raters':
    'Send invite to self with a request to specify their own raters',

  // Session (AddSessionModal.js)
  'Sorry, session with selected respondent already exists.': 'Sorry, session with selected respondent already exists.',
  'Add session': 'Add session',
  'Session SELF': 'Session SELF',
  'Search existing record to select session SELF': 'Search existing record to select session SELF',

  // Session (SessionHeader.js)
  'All Standard': 'All Standard',
  '360 Assessment:': '360 Assessment:',
  'Type:': 'Type:',
  'Status:': 'Status:',
  'Deadline:': 'Deadline:',

  // Session (Sessions.js)
  Sessions: 'Sessions',
  session: 'session',
  'Loading sessions': 'Loading sessions',
  'Delete session': 'Delete session',
  'Delete sessions': 'Delete sessions',
  'this session?': 'this session?',
  'Are you sure you want to delete this project': 'Are you sure you want to delete this project',
  'Sessions list': 'Sessions list',

  // Session header (SessionHeader.js)
  'days left': 'days left',
  'Send invites': 'Send invites',
  'Outstanding ratings': 'Outstanding ratings',
  'Send reminder': 'Send reminder',
  'Sessions completed': 'Sessions completed',
  'Send invite': 'Send invite',

  // Settings AddResponseScale (AddResponseScale.js)
  'Loading...': 'Loading...',
  indexedResponse: 'Response %{index}',

  // Settings (AddScaleForm.js)
  'Add response scale': 'Add response scale',
  'Edit response scale': 'Edit response scale',

  // Settings Components   (EditTitle.js)
  'Edit Name': 'Edit Name',
  'Edit 360 assessment': 'Edit 360 assessment',

  // Settings Components  (QuestionItem.js)
  Question: 'Question',
  'No translation available for': 'No translation available for',

  // Settings Components  (ReviewCompetencies.js)
  'Loading competencies': 'Loading competencies',
  Competencies: 'Competencies',
  CONFIRM: 'CONFIRM',

  // Settings utils (ResponseScale.util.js)
  'Name is the required field': 'Name is the required field',
  'All response scales should be filled': 'All response scales should be filled',

  // Settings (CustomCompetencies.js)
  'Are you sure you want to remove selected': 'Are you sure you want to remove selected items?',
  Collapse: 'Collapse',
  Expand: 'Expand',
  '360 settings': '360 settings',

  // Settings (Settings.js)
  Settings: 'Settings',

  // (BulkActionsSidebar.js)
  'Bulk actions': 'Bulk actions',

  // 360 surveys
  'Add new 360 survey': 'Add new 360 survey',
  'Add 360 survey': 'Add 360 survey',
  'Edit 360 survey': 'Edit 360 survey',
  'Duplicate 360 survey': 'Duplicate 360 survey',
  'Delete selected 360 survey(s)': 'Delete selected 360 survey(s)',
  Sorting: 'Sorting',

  // Sidebar.js
  'Are you sure you want to log out?': 'Are you sure you want to log out?',
  'Credits balance:': 'Credits balance:',

  // Settings  (Settings.const.js)
  'Assessments and batteries': 'Assessments and batteries',
  'Ideal Profiles': 'Ideal Profiles',
  Norms: 'Norms',
  '360 Assessments': '360 Assessments',
  'Response Scales': 'Response Scales',
  Templates: 'Templates',
  'Email templates': 'Email templates',
  Account: 'Account',
  'General info': 'General info',
  General: 'General',
  'Credits History': 'Credits History',
  'Credit updates': 'Credit updates',
  'Reports Usage': 'Reports Usage',
  'Reports Stats': 'Reports Stats',
  'Assessments Stats': 'Assessments Stats',
  'Activity Log': 'Activity Log',
  Tutorials: 'Tutorials',
  'Watch tutorials': 'Watch tutorials',

  // Settings (AssessmentsAndBatteries.js)
  assessment: 'assessment',
  Assessment: 'Assessment',
  'this battery?': 'this battery?',
  'Delete battery': 'Delete battery',
  'Delete batteries': 'Delete batteries',
  'Assessment settings': 'Assessment Settings',
  'selected batteries?': 'selected batteries?',
  // Settings (AddNormMode.js)
  'Add New Norm': 'Add New Norm',
  'Norm Name': 'Norm Name',
  'Assessment on which the norm profile is based': 'Assessment on which the norm profile is based',
  Frequency: 'Frequency',
  SD: 'SD',
  'Sample Size': 'Sample Size',
  'Sample size must be greater than or equal to 1': 'Sample size must be greater than or equal to 1',
  'Select group': 'Select group',
  'Assessment on which the norm is based': 'Assessment on which the norm is based',

  // Settings (CompetencyGroups.js)
  'Copy of': 'Copy of',
  'Competency Group': 'Competency Group',
  'Delete assessment': 'Delete assessment',
  'selected assessments': 'selected assessments',
  'this assessment': 'this assessment',
  'Duplicate assessment': 'Duplicate assessment',
  'Are you sure you want to duplicate': 'Are you sure you want to duplicate',

  // Settings (CompetencyGroupsDetails.js)
  Structure: 'Structure',
  'Response scale': 'Response scale',
  'Loading Competency Details': 'Loading Competency Details',
  'Categories:': 'Categories:',
  'Competencies:': 'Competencies:',
  'Numbers of items': 'Numbers of items',
  'Multiple choice:': 'Multiple choice:',
  'Free text:': 'Free text:',
  'Estimated time': 'Estimated time',
  minutes: 'minutes',
  'Available languages': 'Available languages',
  'Make sure at least one language is fully supported in assessment':
    'Make sure at least one language is fully supported in assessment',
  Questions: 'Questions',
  'Competency deletion': 'Competency deletion',
  'competencies?': 'competencies?',
  'competency?': 'competency?',
  'Assessment deletion': 'Assessment deletion',
  'Are you sure you want to deleted selected the assessment?':
    'Are you sure you want to deleted selected the assessment?',
  // Settings Templates (WelcomeText.js)
  'Welcome texts': 'Welcome texts',
  'selected welcome texts?': 'selected welcome texts?',
  'this welcome text?': 'this welcome text?',

  // Settings Templates (WelcomeTextScreen.js)
  'Add new welcome text': 'Add new welcome text',
  'Template name': 'Template name',
  'welcome text': 'welcome text',
  'Delete welcome texts': 'Delete welcome texts',

  // Settings Templates (templatesTabs.js)
  'Assessment welcome texts': 'Assessment welcome texts',
  template: 'template',
  'Delete templates': 'Delete templates',
  'selected templates?': 'selected templates?',
  'this template?': 'this template?',

  // Settings Templates (TemplatesScreen.js)
  'Field is required': 'Field is required',
  'Template subject': 'Template subject',

  // Settings ResponseScales (ResponseScales.js)
  'Response Scale': 'Response Scale',
  'Delete scale(s)': 'Delete scale(s)',
  'Are you sure you want to delete this scale(s)?': 'Are you sure you want to delete this scale(s)?',
  Responses: 'Responses',
  Languages: 'Languages',
  'Score right to left': 'Score right to left',

  // Settings ResponseScales (bulkActions.js)
  'Delete selected response scale(s)': 'Delete selected response scale(s)',
  // Settings Norms (NormsHeader.js)
  'Sample Size:': 'Sample Size:',
  'Norm Type:': 'Norm Type:',
  'Creation Date:': 'Creation Date:',

  // Settings Norms (NormsScales.js)
  'Norm Table': 'Norm Table',
  'Frequency Histograms': 'Frequency Histograms',
  'Bio Data': 'Bio Data',
  Stenine: 'Stenine',
  Stentable: 'Stentable',

  // Settings Norms (Norms.js)
  'Add new norm': 'Add new norm',
  'Delete norm(s)': 'Delete norm(s)',
  'Assessment and Batteries': 'Assessment and Batteries',
  'selected norms?': 'selected norms?',
  'this norm?': 'this norm?',
  'Creation Date': 'Creation Date',

  // Settings Norms (HistogramView.js)
  FREQUENCY: 'FREQUENCY',
  'RAW SCORE': 'RAW SCORE',
  'Raw Score': 'Raw Score',

  // Settings ManageBattery (index.js)
  'Battery name': 'Battery name',
  'Search existing assessments': 'Search existing assessments',
  'Add battery': 'Add battery',

  // Settings IdealProfile (IdealProfileScales.js)
  'Ideal profile type:': 'Ideal profile type:',
  'Unsaved data': 'Unsaved data',
  'The current ideal profile has not been saved. Would you like to save it?':
    'The current ideal profile has not been saved. Would you like to save it?',
  addIdealProfileGroups: 'Groups',

  // Sidebar.js
  Logout: 'Logout',

  // Settings IdealProfile (IdealProfile.js)
  'Delete ideal profiles': 'Delete ideal profiles',
  'selected ideal profiles?': 'selected ideal profiles?',
  'this ideal profile?': 'this ideal profile?',

  // Settings AddIdealProfile.js
  'Add ideal profile': 'Add ideal profile',
  'Ideal profile name': 'Ideal profile name',
  'I will manually select the values': 'I will manually select the values',
  'I will use respondent data to determine the values': 'I will use respondent data to determine the values',
  Test: 'Test',
  // (advancedFilterOptions.js)
  'Is equal to': 'Is equal to',
  'Is not equal to': 'Is not equal to',
  'Starts with': 'Starts with',
  Contains: 'Contains',
  'Does not contain': 'Does not contain',
  'Ends with': 'Ends with',
  'Lesser than': 'Lesser than',
  'Greater than': 'Greater than',
  '1 day': '1 day',
  '3 days': '3 days',
  '1 week': '1 week',
  '2 weeks': '2 weeks',
  '1 month': '1 month',
  '1 year': '1 year',

  // groupsTypes.js
  Unsupervised: 'Unsupervised',
  Supervised: 'Supervised',
  // idealProfileTypes.js
  'Manually configured': 'Manually configured',
  'Based on respondent data': 'Based on respondent data',

  // inviteCreationCategories.js
  'Personality tests': 'Personality tests',
  'Interests Values Attitudes': 'Interests Values Attitudes',
  Reasoning: 'Reasoning',
  Value: 'Value',
  'Preferences/Styles': 'Preferences/Styles',
  'Custom battery': 'Custom battery',
  'General Solutions': 'General Solutions',
  'Screening Solutions': 'Screening Solutions',
  Solution: 'Solution',
  Battery: 'Battery',
  'Every 4 days': 'Every 4 days',
  'Every 3 days': 'Every 3 days',
  'Every 2 days': 'Every 2 days',
  'Every day': 'Every day',
  "Don't send": "Don't send",
  Immediate: 'Immediate',

  // inviteStatuses.js
  'Not sent': 'Not sent',
  Scheduled: 'Scheduled',
  Sent: 'Sent',
  Failed: 'Failed',
  'Failed status 2': 'Failed status 2',

  // raterRoles.js
  Manager: 'Manager',
  Peer: 'Peer',
  'Direct report': 'Direct report',

  // respondentSelectOptions.js
  'No Qualification': 'No Qualification',
  'Secondary School': 'Secondary School',
  'Industry or Trade Training': 'Industry or Trade Training',
  'Professional Qualification': 'Professional Qualification',
  'Tertiary Institute Diploma/Certificate': 'Tertiary Institute Diploma/Certificate',
  'University Bachelor Degree': 'University Bachelor Degree',
  'Postgraduate Diploma/Certificate': 'Postgraduate Diploma/Certificate',
  'Bachelor Honours': 'Bachelor Honours',
  'Masters Degree': 'Masters Degree',
  'Doctorate Degree': 'Doctorate Degree',
  'African American': 'African American',
  Aborigine: 'Aboriginal or Torres Strait Islander',
  Algerian: 'Algerian',
  African: 'African',
  Afrikaner: 'Afrikaner',
  Afrikaans: 'Afrikaans',
  Albanian: 'Albanian',
  American: 'American',
  Argentinian: 'Argentinian',
  'Australian Aborigine': 'Australian Aborigine',
  Arab: 'Arab',
  Asian: 'Asian',
  Austrian: 'Austrian',
  Australian: 'Australian',
  Bangladeshi: 'Bangladeshi',
  Balinese: 'Balinese',
  'Black British': 'Black British',
  'Black Canadian': 'Black Canadian',
  Belgian: 'Belgian',
  'Black Carribean': 'Black Carribean',
  'Black Indian': 'Black Indian',
  'Black African': 'Black African',
  Bolivian: 'Bolivian',
  Bengali: 'Bengali',
  'Black Other': 'Black Other',
  Bosnian: 'Bosnian',
  Brazilian: 'Brazilian',
  British: 'British',
  Belarusian: 'Belarusian',
  Bulgarian: 'Bulgarian',
  Burmese: 'Burmese',
  Canadian: 'Canadian',
  Castilian: 'Castilian',
  Catalan: 'Catalan',
  Celtic: 'Celtic',
  Chinese: 'Chinese',
  'Channel Islander': 'Channel Islander',
  Cossack: 'Cossack',
  Chilean: 'Chilean',
  Cambodian: 'Cambodian',
  Chechen: 'Chechen',
  Colombian: 'Colombian',
  'Costa Rican': 'Costa Rican',
  Croatian: 'Croatian',
  Corsican: 'Corsican',
  Cantonese: 'Cantonese',
  Cuban: 'Cuban',
  Cypriot: 'Cypriot',
  'Cypriot Greek': 'Cypriot Greek',
  Czech: 'Czech',
  Danish: 'Danish',
  Dutch: 'Dutch',
  Eurasian: 'Eurasian',
  Ecuadorian: 'Ecuadorian',
  Egyptian: 'Egyptian',
  English: 'English',
  Estonian: 'Estonian',
  Ethiopian: 'Ethiopian',
  European: 'European',
  Fijian: 'Fijian',
  Filipino: 'Filipino',
  Flemish: 'Flemish',
  Finnish: 'Finnish',
  French: 'French',
  Gaelic: 'Gaelic',
  Georgian: 'Georgian',
  German: 'German',
  Greek: 'Greek',
  Hungarian: 'Hungarian',
  Hispanic: 'Hispanic',
  'Hong Kong Chinese': 'Hong Kong Chinese',
  Icelandic: 'Icelandic',
  Indonesian: 'Indonesian',
  Irani: 'Irani',
  Indian: 'Indian',
  'Iranian/Persian': 'Iranian/Persian',
  Iraqi: 'Iraqi',
  Irish: 'Irish',
  'Iranian Arab': 'Iranian Arab',
  Iranian: 'Iranian',
  Italian: 'Italian',
  Jamaican: 'Jamaican',
  Jewish: 'Jewish',
  'Jewish/Israeli': 'Jewish/Israeli',
  Jordanian: 'Jordanian',
  Japanese: 'Japanese',
  Kenyan: 'Kenyan',
  Kiwi: 'Kiwi',
  Kongo: 'Kongo',
  Korean: 'Korean',
  Latin: 'Latin',
  Lebanese: 'Lebanese',
  Lithuanian: 'Lithuanian',
  Libyan: 'Libyan',
  'Latin American': 'Latin American',
  Latino: 'Latino',
  Luxembourger: 'Luxembourger',
  Latvian: 'Latvian',
  Macedonian: 'Macedonian',
  'Middle Eastern': 'Middle Eastern',
  Malaitian: 'Malaitian',
  Maori: 'Maori',
  Monacan: 'Monacan',
  Moroccan: 'Moroccan',
  Maltese: 'Maltese',
  Mexican: 'Mexican',
  Malay: 'Malay',
  'Mixed Race': 'Mixed Race',
  'New Zealand European': 'New Zealand European',
  Nigerian: 'Nigerian',
  Norwegian: 'Norwegian',
  Nepalese: 'Nepalese',
  'New Zealander': 'New Zealander',
  'Other Ethnicity': 'Other Ethnicity',
  Paraguayan: 'Paraguayan',
  'Pacific Islander': 'Pacific Islander',
  Persian: 'Persian',
  Peruvian: 'Peruvian',
  'Persian/Iranian': 'Persian/Iranian',
  Pakistani: 'Pakistani',
  Palestinian: 'Palestinian',
  Polish: 'Polish',
  'Papua New Guinean': 'Papua New Guinean',
  'Puerto Rican': 'Puerto Rican',
  Portuguese: 'Portuguese',
  Polynesian: 'Polynesian',
  Romanian: 'Romanian',
  Russian: 'Russian',
  Samoan: 'Samoan',
  'South African Coloured': 'South African Coloured',
  Scottish: 'Scottish',
  Swede: 'Swede',
  'South African European': 'South African European',
  Serbian: 'Serbian',
  Swedish: 'Swedish',
  'South American Indian': 'South American Indian',
  Sicilian: 'Sicilian',
  Slovak: 'Slovak',
  Slav: 'Slav',
  Spanish: 'Spanish',
  'Sri Lankan': 'Sri Lankan',
  'Southeast Asian': 'Southeast Asian',
  Swiss: 'Swiss',
  Swahili: 'Swahili',
  Swazi: 'Swazi',
  Taiwanese: 'Taiwanese',
  Thai: 'Thai',
  Turkish: 'Turkish',
  Tamil: 'Tamil',
  Tongan: 'Tongan',
  Rwandan: 'Rwandan',
  Tunisian: 'Tunisian',
  Udi: 'Udi',
  Ugandan: 'Ugandan',
  Ukrainian: 'Ukrainian',
  Uruguayan: 'Uruguayan',
  Vietnamese: 'Vietnamese',
  Welsh: 'Welsh',
  White: 'White',
  'West Indian': 'West Indian',
  Zimbabwean: 'Zimbabwean',
  Zulu: 'Zulu',
  '- Chief executive': '- Chief executive',
  '- Administrative or commercial manager': '- Administrative or commercial manager',
  '- Production or specialized services manager': '- Production or specialized services manager',
  '- Hospitality, retail or other services manager': '- Hospitality, retail or other services manager',
  '- Senior manager': '- Senior manager',
  '- Middle manager': '- Middle manager',
  '- Junior manager/supervisor': '- Junior manager/supervisor',
  '- Other': '- Other',
  Professional: 'Professional',
  '- Science or engineering professional': '- Science or engineering',
  '- Health professional': '- Health professional',
  '- Teaching professional': '- Teaching professional',
  '- Business or administration professional': '- Business or administration professional',
  '- Information and communications technology professional':
    '- Information and communications technology professional',
  '- Legal, social or cultural professional': '- Legal, social or cultural professional',
  'Technician or associate professional': 'Technician or associate professional',
  '- Science or engineering associate professional': '- Science or engineering associate',
  '- Health associate professional': '- Health associate professional',
  '- Business or administration associate professional': '- Business or administration associate',
  '- Legal, social, cultural or related associate professional': '- Legal, social, cultural or related associate',
  '- Information and communications technician': '- Information and communications technician',
  'Clerical support worker': 'Clerical support worker',
  '- General or keyboard clerk': '- General or keyboard clerk',
  '- Customer services clerk': '- Customer services clerk',
  '- Numerical or material recording clerk': '- Numerical or material recording clerk',
  'Service or sales worker': 'Service or sales worker',
  '- Personal service worker': '- Personal service worker',
  '- Sales worker': '- Sales worker',
  '- Personal care worker': '- Personal care worker',
  '- Protective services worker': '- Protective services worker',
  'Skilled agricultural, forestry or fishery worker': 'Skilled agricultural, forestry or fishery worker',
  '- Market-oriented skilled agricultural worker': '- Skilled agricultural worker',
  '- Market-oriented skilled forestry, fishing or hunting worker': '- Skilled forestry, fishing or hunting worker',
  '- Subsistence farmer, fisher, hunter or gatherer': '- Subsistence farmer, fisher, hunter or gatherer',
  'Craft or related trades worker': 'Craft or related trades worker',
  '- Building or related trades worker, excluding electrician': '- Building or related trades worker, ex electrician',
  '- Metal, machinery or related trades worker': '- Metal, machinery or related trades worker',
  '- Handicraft or printing worker': '- Handicraft or printing worker',
  '- Electrical or electronic trades worker': '- Electrical or electronic trades worker',
  '- Food processing, wood working, garment or other craft or related trades':
    '- Food processing, wood working, garment or other craft or related trades',
  'Plant or machine operator, or assembler': 'Plant or machine operator, or assembler',
  '- Stationary plant or machine operator': '- Stationary plant or machine operator',
  '- Assembler': '- Assembler',
  '- Driver or mobile plant operator': '- Driver or mobile plant operator',
  'Elementary occupation': 'Elementary occupation',
  '- Cleaner or helper': '- Cleaner or helper',
  '- Agricultural, forestry or fishery labourer': '- Agricultural, forestry or fishery labourer',
  '- Labourer in mining, construction, manufacturing or transport': '- Labourer in various sectors',
  '- Food preparation assistant': '- Food preparation assistant',
  '- Street or related sales or service worker': '- Street or related sales or service worker',
  '- Refuse worker or other elementary worker': '- Refuse or other elementary worker',
  'Armed forces occupation': 'Armed forces occupation',
  '- Commissioned armed forces officer': '- Commissioned armed forces officer',
  '- Non-commissioned armed forces officer': '- Non-commissioned armed forces officer',
  '- Armed forces occupation, other rank': '- Armed forces occupation, other rank',
  'Local Government/Authority': 'Local Government/Authority',
  'Private, Corporate ': 'Private, Corporate ',
  'Public service, Fed/State Govt': 'Public service, Fed/State Govt',
  'State Owned Corporation': 'State Owned Corporation',
  Unknown: 'Unknown',
  'Administrative and Support Service Activities': 'Administrative and Support Service',
  'Agriculture, Forestry and Fishing': 'Agriculture, Forestry and Fishing',
  'Arts, Entertainment and Recreation': 'Arts, Entertainment and Recreation',
  'Banking & Finance': 'Banking & Finance',
  'Business Services': 'Business Services',
  'Architecture and Construction': 'Architecture and Construction',
  'Education and Training': 'Education and Training',
  'Utilities (Gas, Water, Electric, etc)': 'Utilities (Gas, Water, Electric, etc)',
  Engineering: 'Engineering',
  'Hospitality and Tourism': 'Hospitality and Tourism',
  'Human Resources': 'Human Resources',
  'Health Sector': 'Health Sector',
  'Information and Technology': 'Information and Technology',
  Insurance: 'Insurance',
  'Law Enforcement': 'Law Enforcement',
  Manufacturing: 'Manufacturing',
  'Mining and Quarrying': 'Mining and Quarrying',
  'Military & Defence': 'Military & Defence',
  'Oil and Gas/Energy Exploration': 'Oil and Gas/Energy Exploration',
  'Pharmaceutical Industry': 'Pharmaceutical Industry',
  'Public Administration': 'Public Administration',
  'Real Estate Activities': 'Real Estate Activities',
  'Scientific and Technical Activities': 'Scientific and Technical Activities',
  Security: 'Security',
  'Social Work': 'Social Work',
  Telecommunications: 'Telecommunications',
  'Transportation and Storage': 'Transportation and Storage',
  Media: 'Media',
  'Wholesale and Retail Trade': 'Wholesale and Retail Trade',
  Arabic: 'Arabic',
  'Brazilian Portuguese': 'Brazilian Portuguese',
  'Chinese-Simplified': 'Chinese-Simplified',
  'Chinese-Traditional': 'Chinese-Traditional',
  Hebrew: 'Hebrew',
  'Hindi-Urdu': 'Hindi-Urdu',
  isiNdebele: 'isiNdebele',
  isiXhosa: 'isiXhosa',
  isiZulu: 'isiZulu',
  'Latin American Spanish': 'Latin American Spanish',
  Malayalam: 'Malayalam',
  Punjabi: 'Punjabi',
  Sepedi: 'Sepedi',
  'Southern Thai': 'Southern Thai',
  Sesotho: 'Sesotho',
  Setswana: 'Setswana',
  siSwati: 'siSwati',
  'Tagalog (Filipino)': 'Tagalog (Filipino)',
  Turkmen: 'Turkmen',
  Tshivenda: 'Tshivenda',
  Xitsonga: 'Xitsonga',
  Male: 'Male',
  Female: 'Female',
  'Not Specified': 'Not Specified',

  // errorMessages.js
  'Email address is not valid': 'Email address is not valid',

  // table bulkActions assessments.js
  'Edit battery': 'Edit battery',
  // table singleRowActions competency.js
  'Add competency': 'Add competency',
  'Edit competency category': 'Edit competency category',
  'Delete competency category': 'Delete competency category',
  'Edit competency': 'Edit competency',
  'Delete competency': 'Delete competency',
  'Edit question': 'Edit question',
  'Delete question': 'Delete question',

  // table singleRowActions competencyGroup.js
  'Delete competency group': 'Delete competency group',
  'Go to competency groups details': 'Go to competency groups details',

  // table singleRowActions  group.js
  'Invite respondents to questionnaire': 'Invite respondents to questionnaire',
  'Administer answer sheet': 'Administer answer sheet',
  'Administer managed group': 'Administer managed group',
  'Create an ideal profile based on selected group': 'Create an ideal profile based on selected group',
  'Create a Norm based on selected group': 'Create a norm based on selected group',
  'View or edit': 'View or edit',
  Delete: 'Delete',

  // table singleRowActions invites.js
  Remind: 'Remind',

  // table singleRowActions projects.js
  'Add to favorites': 'Add to favorites',
  View: 'View',
  'Delete Project': 'Delete Project',

  // table singleRowActions raters.js
  'Delete rater': 'Delete rater',

  // table singleRowActions reports.jsAdminister questionnaire
  Open: 'Open',
  Download: 'Download',

  // table singleRowActions respondents.js
  'Administer questionnaire': 'Administer questionnaire',
  'Invite to questionnaire': 'Invite to questionnaire',
  'Add tag': 'Add tag',

  // table singleRowActions session.js
  'Invite raters who have not yet complete': 'Invite raters who have not yet complete',
  'Invite who have not yet been invited': 'Invite who have not yet been invited',
  'Send reminder to raters who have already been invited but have not yet completed':
    'Send reminder to raters who have already been invited but have not yet completed',
  'Invite to specify own rater': 'Invite to specify own rater',
  'Send invitation to self': 'Send invitation to self',
  'Edit session': 'Edit session',

  // table bulkActions assessments.js
  'Add custom battery': 'Add custom battery',
  'Edit assessment': 'Edit assessment',
  'Delete Assessment(s)': 'Delete Assessment(s)',

  // table bulkActions groups.js
  'Create a Norm based on the selected group(s)': 'Create a Norm based on the selected group(s)',
  createIdealProfileBasedOnSselectedGroup: 'Create an Ideal profile based on the selected group(s)',
  'Create an Ideal profile based on the selected group(s)': 'Create an Ideal profile based on the selected group(s)',
  'Delete selected group(s)': 'Delete selected group(s)',
  'Generate an open group link': 'Generate an open group link',

  // table bulkActions idealProfiles.js
  'Edit ideal profile': 'Edit ideal profile',

  // table bulkActions invites.js
  'Copy invite link to clipboard': 'Copy invite link to clipboard',
  'Delete invites': 'Delete invites',

  // table bulkActions projects.js
  'Edit project details': 'Edit project details',
  'Delete projects(s)': 'Delete projects(s)',
  // table bulkActions raters.js
  'Send invite to selected rater(s)': 'Send invite to selected rater(s)',
  'Remove selected rater(s) from session': 'Remove selected rater(s) from session',

  // table bulkActions reports.js
  Actions: 'Actions',
  'Delete selected report(s)': 'Delete selected report(s)',
  'Download selected report(s)': 'Download selected report(s)',
  'View selected report(s)': 'View selected report(s)',

  // table bulkActions respondentGroups.js
  'Remove respondent from selected group(s)': 'Remove respondent from selected group(s)',
  // table bulkActions respondents.js
  'Administer assessment': 'Administer assessment',
  'Administer assessment for': 'Administer assessment for %{respondentName}',
  'Invite respondent(s) to assessment': 'Invite respondent(s) to assessment ',
  'Request report(s)': 'Request report(s)',
  'Go to respondent details': 'Go to respondent details',
  'Manage tags for selected respondent(s)': 'Manage tags for selected respondent(s) ',
  'Add new group from selection': 'Add new group from selection',
  'Add to existing group': 'Add to existing group ',
  'Delete selected respondents from group': 'Remove selected respondents from group',
  'Delete selected respondents': 'Remove selected respondents',
  'this respondent': 'this respondent',
  'selected respondents': 'selected respondents',

  // table bulkActions respondentsTest.js
  'Delete selected result(s)': 'Delete selected result(s)',

  // table bulkActions sessions.js
  'Invite to specify own raters': 'Invite to specify own raters',
  'Delete Session(s)': 'Delete Session(s)',

  // table bulkActions templates.js
  'Go to template details': 'Go to template details',
  'Delete selected template(s)': 'Delete selected template(s)',
  // table bulkActions welcomeText.js
  'Go to welcome text details': 'Go to welcome text details',
  'Delete selected welcome text(s)': 'Delete selected welcome text(s)',
  // table entitiesDropdownActions groupDetails.js
  'Edit title': 'Edit title',
  'Duplicate 360 assessment': 'Duplicate 360 assessment',
  'Delete 360 assessment': 'Delete 360 assessment',
  // table entitiesDropdownActions sessions.js
  'Invite raters who have not yet completed': 'Invite raters who have not yet completed',

  // table tableActions competencyGroupsDetails.js
  'Review competencies': 'Review competencies',

  // table tableActions customGroups.js
  'Add new 360 assessment': 'Add new 360 assessment',

  // table tableActions raters.js
  'Add rater': 'Add rater',
  // table tableActions respondents.js
  'Import respondents': 'Import respondents',

  // table tableActions templates.js
  'Add new template': 'Add new template',
  'Edit template': 'Edit template',
  'Edit welcome text': 'Edit welcome text',
  // QuestionOrder.js
  Default: 'Default',
  Custom: 'Custom',
  Random: 'Random',
  // tables columns sessions.js
  Invited: 'Invited',
  'Invite Not Sent': 'Invite Not Sent',
  'Invite not sent': 'Invite Not Sent',

  'Show expired': 'Show expired',
  'Sorry we could not find any': 'Sorry, we could not find any %{entityName}',
  'Sorry, we could not find any respondents': 'Sorry, we could not find any respondents',
  'Sorry, we could not find any reports': 'Sorry, we could not find any reports',
  'Sorry, we could not find any results': 'Sorry, we could not find any results',
  'Sorry, we could not find any invites': 'Sorry, we could not find any invites',
  'Sorry, we could not find any records': 'Sorry, we could not find any records',
  'Sorry, we could not find any projects': 'Sorry, we could not find any projects',
  'Sorry, we could not find any tests': 'Sorry, we could not find any tests',
  'Sorry, we could not find any groups': 'Sorry, we could not find any groups',
  'Sorry, we could not find any notifications': 'Sorry, we could not find any notifications',
  'Sorry, we could not find any raters': 'Sorry, we could not find any raters',
  'Sorry, we could not find any templates': 'Sorry, we could not find any templates',
  'Sorry, we could not find any sessions': 'Sorry, we could not find any sessions',
  'Sorry, we could not find any welcome texts': 'Sorry, we could not find any welcome texts',
  'Sorry, we could not find any competency groups': 'Sorry, we could not find any competency groups',
  'Sorry, we could not find any norms': 'Sorry, we could not find any norms',
  'Sorry, we could not find any response scales': 'Sorry, we could not find any response scales',
  'Number of items': 'Number of items',
  Categories: 'Categories',
  'User name': 'User name',
  'Update amount': 'Update amount',
  Members: 'Members',
  'Invitation Date': 'Invitation Date',
  'Respondent Name': 'Respondent Name',
  Report: 'Report',
  'Date submitted': 'Date submitted',
  Status: 'Status',
  'Not invited': 'Not invited',
  'Not completed': 'Not completed',
  'Completed Date': 'Completed Date',
  'Direct Report': 'Direct Report',
  Progress: 'Progress',
  'Assessments Done': 'Assessments Done',
  'Add new project': 'Add new project',
  Date: 'Date',
  'Loading entities': 'Loading %{entityName}',
  Created: 'Created',
  'No options': 'No options',
  '**Default': '**Default',
  '**No Welcome Text': '**No Welcome Text',

  // Settings Account ChangePassword.js

  'Passwords must match': 'Passwords must match',
  'Change password': 'Change password',
  'Confirm new password': 'Confirm new password',
  'New password': 'New password',
  Change: 'Change',
  'Pins must match': 'Pins must match',
  'Change pin': 'Change pin',
  'Confirm new pin': 'Confirm new pin',
  'New pin': 'New pin',
  'Total:': 'Total:',
  'Loading credits history...': 'Loading credits history...',
  'From date': 'From date',
  'To date': 'To date',
  Pin: 'Pin',
  'Receive email notifications': 'Receive email notifications',
  'Complete Status': 'Complete Status',
  'Invite Status': 'Invite Status',
  'Not Invited': 'Not Invited',
  'Sessions count': 'Sessions count',

  // ImportSessions
  'Import sessions': 'Import sessions',
  prepare: 'prepare',
  preview: 'preview',
  import: 'import',
  Import: 'import',
  'Prepare a csv file yourself or': 'Prepare a csv file yourself or ',
  'download the sample import file': 'download the sample import file',
  'Make sure the data order is set out correctly': 'Make sure the data order is set out correctly',
  'Upload the file': 'Upload the file',
  'Drag and drop file here or browse the file': 'Drag and drop file here or browse the file',
  'Upload the csv file to import respondents': 'Upload the csv file to import respondents',
  'The first line contains field headers': 'The first line contains field headers',
  'Choose file': 'Choose file',

  // AccountTabs
  general: 'General',
  'credit updates': 'Credit updates',
  'reports stats': 'reports stats',
  'assessments stats': 'assessments stats',

  // ReportUsage
  total: 'Total: %{total}',
  'Report name': 'Report name',
  'Usage count': 'Usage count',
  'Assessment name': 'Assessment name',
  Total: 'Total',
  'Loading requested reports': 'Loading requested reports',
  'Loading used assessments': 'Loading used assessments',

  // Toasts & system notifications
  'Tag(s) have been deleted': 'Tag(s) have been deleted',
  'Loading items': 'Loading items',
  'Saving results': 'Saving results',
  'Ideal profile has been created': 'Ideal profile has been created',
  'Copy of 360 survey has been created': 'Copy of 360 survey has been created',
  'Tag(s) have been updated': 'Tag(s) have been updated',
  'Welcome text has been saved': 'Welcome text has been saved',
  'Welcome text has been updated': 'Welcome text has been updated',

  // variables
  createOption: 'Create',

  // Toasts
  groupCreationToast: 'Group has been created',
  respondentsDeletionToast: 'Respondent(s) have been deleted',
  resultsSuccessToast: 'Results for %{respondentInfo} added successfully',
  surveyDeletionCancelToast: '360 survey(s) used in project can not be deleted',
  creditBalanceUpdateToast: 'Credit balance has been updated',
  correctDataToast: 'Please make sure all data is added correctly',
  downloadLimitToast: 'Oops! You can not download more than 50 reports at the same time',
  categoryUpdateToast: 'Category has been updated',
  newCategoryToast: 'New category has been added',
  createdRequestToast: 'Report request created',
  respondentCreationToast: 'Respondent has been added',
  respondentUpdateToast: 'Respondent has been updated',
  clipboardCopyToast: 'Successfully copied to clipboard',
  addedAssessmentToast: 'Assessment has been added',
  deletedAssessmentToast: 'Assessment has been deleted',
  completedAssessmentToast: '%{respondentName} completed %{assessment}',
  importRespondentsErrorToast: 'Something went wrong during the process of importing respondents',
  importSessionsErrorToast: 'Something went wrong during the process of importing sessions',
  importSurveyErrorToast: 'Something went wrong during the process of importing survey',
  competencyTitleUpdateToast: 'Title has been updated',
  competencyUpdateToast: 'Competency has been updated',
  newCompetencyToast: 'Competency has been added',
  questionUpdateToast: 'Question has been updated',
  questionAdditionToast: 'Question has been added',
  groupLinkErrorToast: 'Something went wrong during group link generation, please try again',
  reportsDeletionToast: 'Report(s) have been deleted',
  resultsSavingErrorToast: 'Error saving results for %{respondentName} %{assessment}',
  reportRequestCreationToast: 'Report request created',
  assessmentDataFailToast: 'Failed to load assessment data',
  scaleUpdatedToast: 'Response scale has been updated',
  scaleCreatedToast: 'Response scale has been saved',
  scaleDeletionToast: 'Response scale(s) have been deleted',
  surveyUpdatedToast: '360 Survey has been updated',
  normsDeletionToast: 'Norm(s) have been deleted',
  'Deletion blocked': 'Deletion blocked',
  groupCreationErrorToast: 'Error during group creation',
  groupsDeletionToast: 'Group(s) have been deleted',
  reportsGenerationToast: 'Report(s) requested successfully',
  invitesDeletionToast: 'Invite(s) have been deleted',
  normCreationToast: 'Norm request created',
  normCreationErrorToast: 'Unable to create norm, please try again',
  projectDeletionToast: 'Project has been deleted',
  projectsDeletionToast: 'Projects have been deleted',
  projectCreationToast: 'Project has been created',
  projectUpdateToast: 'Project has been updated',
  ratersDeletionToast: 'Rater(s) have been deleted',
  categoryDeletionToast: 'Competency category has been deleted',
  resultsDeletionToast: 'Result(s) have been deleted',
  tagsAdditionToast: 'Tags have been added',
  addedToGroupToast: 'Successfully added to group',
  questionnaireSessionErrorToast: 'Unable to create Questionnaire Session, please try again later',
  sessionAdditionToast: 'Session has been added',
  ratersAdditionToast: 'Session raters have been added',
  competencyDeletionToast: 'Competency has been deleted',
  questionDeletionToast: 'Question has been deleted',
  assessmentsDeletionToast: 'Assessment(s) have been deleted',
  profilesDeletionToast: 'Ideal profile(s) have been deleted',
  profileUpdateToast: 'Ideal profile has been updated',
  profileUpdateToastError: 'Ideal profile update was not successful',
  templatesDeletionToast: 'Template(s) have been deleted',
  templateAdditionToast: 'Template has been saved',
  templateUpdateToast: 'Template has been updated',
  welcomeTextsDeletionToast: 'Welcome Text(s) have been deleted',
  batteryAdditionToast: 'Battery has been created',
  batteryUpdateToast: 'Battery has been updated',
  batteriesDeletionToast: 'Batteries have been deleted',
  batteryDeletionToast: 'Battery has been deleted',
  passwordUpdateSuccessToast: 'Password has been successfully updated',
  pinUpdateSuccessToast: 'Pin has been successfully updated',
  reportFormatUpdateSuccessToast: 'Report format has been successfully updated',
  reportFormatUpdateFailureToast: 'Report format was not updated',
  updateFormVisibilityNonMasterFailureToast: 'Only master user can change this field',
  visibleNormForReport: 'Use norm for reports',
  hideNormForReport: 'Hide norm for reports',
  showNormTitle: 'Make Visible for Reports Request List',
  showConfirmationMessageTitle: 'Are you sure you want to make visible',
  hideNormTitle: 'Hide for Reports Request List',
  hideConfirmationMessageTitle: 'Are you sure you want to remove',
  showhideConfirmationMessageDescription1: 'selected norms for reports generation?',
  showhideConfirmationMessageDescription2: 'this norm for reports generation?',
  visible: 'Visible',
  normsVisibilityToast: 'Norm visibility has been updated',

  // Notifications
  'no messages': 'No messages',
  'no new messages': 'No new messages',
  'tests completed': 'Tests completed',
  'reports requested': 'Reports requested',
  'the previous notification area has been moved': 'The previous notification area has been moved',
  here: 'here',

  // General
  Continue: 'Continue',
  New: 'New',
  History: 'History',

  'Selected respondent(s) can’t be invited, since one or several of them have already been invited':
    'Selected respondent(s) can’t be invited, since one or several of them have already been invited',
  'Copy invite link': 'Copy invite link',
  'Invite link can be copied only for not completed projects':
    'Invite link can be copied only for not completed projects',
  'Invite link can be copied for 1 selected respondent at a time':
    'Invite link can be copied for 1 selected respondent at a time',
  'Invite link is not available, please send or download invites in the csv first':
    'Invite link is not available, please send or download invites in the csv first',
  'Send reminders': 'Send reminders',
  'One or several selected respondents haven’t been invited yet':
    'One or several selected respondents haven’t been invited yet',
  'Download reports': 'Download reports',
  'Download report option can be available for 1 selected respondent at a time':
    'Download report option can be available for 1 selected respondent at a time',
  'One or several selected respondents don’t have available reports':
    'One or several selected respondents don’t have available reports',
  'Go to respondent profile': 'Go to respondent profile',
  'Go to respondent profile option can be available for 1 selected respondent at a time':
    'Go to respondent profile option can be available for 1 selected respondent at a time',
  'Remove respondent(s)': 'Remove respondent(s)',
  "Selected respondents can't be deleted since one or several of them already passed the assessment":
    "Selected respondents can't be deleted since one or several of them already passed the assessment",
  'Administer Managed Group Session': 'Administer Managed Group Session',
  'Generate Open Group Link': 'Generate Open Group Link',
  'Create Norm from a Group': 'Create Norm from a Group',
  'Create an Ideal Profile from a Group': 'Create an Ideal Profile from a Group',
  'Invite Respondents for Assessment': 'Invite Respondents for Assessment',
  'Delete selected Group': 'Remove selected group',
  Authorize: 'Authorize',
  'Project rules': 'Project rules',
  'Invites sending': 'Invites sending',
  'Select assessment, language and email template you want to use':
    'Select assessment, language and email template you want to use',
  'Choose whether invites should go out on the scheduled date, get sent once respondent gets added to the project or manually with pre-selected test and template':
    'Choose whether invites should go out on the scheduled date, get sent once respondent gets added to the project or manually with pre-selected test and template',
  'Reports generation': 'Reports generation',
  'Reports selected during project creation will be automatically generated after respondent submits their results':
    'Reports selected during project creation will be automatically generated after respondent submits their results',
  'Direct feedback report may be activated, if available for the selected test too (check for details with your local distributor':
    'Direct feedback report may be activated, if available for the selected test too (check for details with your local distributor',
  'Credits usage': 'Credits usage',
  'To ensure you have enough credits to get reports generated automatically, we are freezing the required cost per respondent once they get added to the project':
    'To ensure you have enough credits to get reports generated automatically, we are freezing the required cost per respondent once they get added to the project',
  Back: 'Back',
  'Country of origin': 'Country of origin',
  Mixed: 'Mixed',
  Scale: 'Scale',
  Mean: 'Mean',
  Histogram: 'Histogram',
  age: 'Age',
  gender: 'Gender',
  ethnicity: 'Ethnicity',
  education: 'Education',
  occupation: 'Occupation',
  industry: 'Industry',
  'first language': 'First language',
  firstlanguage: 'First language',
  sector: 'Sector',
  jobArea: 'Job Area',
  Sten: 'Sten',
  Stanine: 'Stanine',
  'Less than': 'Less than',
  'Unknown label': 'Unknown label',
  'Send immediate feedback report to respondent': 'Send immediate feedback report to respondent',
  'Send immediate feedback info':
    'Immediate Feedback Reports are intended to be sent via email to the respondent as soon as the assessment has been completed. These reports are customised to the particular situation with the aim of following professional feedback guidelines. Please check with your provider what charges will apply.',
  'General information': 'General information',
  summary: 'summary',
  Inactive: 'Inactive',
  Category: 'Category',
  'Expire after': 'Expire after',
  'How to send invites': 'How to send invites',
  Weekly: 'Weekly',
  Daily: 'Daily',
  Monthly: 'Monthly',
  'Do not remind': 'Do not remind',
  days: 'days',
  expired: 'expired',
  'End project': 'End project',
  'Direct feedback report': 'Direct feedback report',
  Manually: 'Manually',
  Automatically: 'Automatically',
  Schedule: 'Schedule',
  Select: 'Select',
  'Invite template': 'Invite template',
  'Total cost': 'Total cost',
  'Cost per respondent': 'Cost per respondent',
  'Current credits balance': 'Current credits balance',
  'Create project': 'Create project',
  'I confirm that I have read this summary and I want to proceed':
    'I confirm that I have read this summary and I want to proceed',

  Reminder: 'Reminder',
  'Estimated project cost': 'Estimated project cost',
  'Project cost': 'Project cost',
  'Invite expires on': 'Invite expires on',
  'Invites are sent manually': 'Invites are sent manually',
  'Use button on the right to manually send invites': 'Use button on the right to manually send invites',
  'Invites are sent automatically': 'Invites are sent automatically',
  'Invites are automatically sent when you add respondents to the project':
    'Invites are automatically sent when you add respondents to the project',
  'Invites are scheduled': 'Invites are scheduled',
  'Invites will be automatically sent on selected date': 'Invites will be automatically sent on selected date',
  'Project settings': 'Project settings',
  invited: 'invited',
  'invites sent': 'invites sent',
  'Invites will be sent on': 'Invites will be sent on',
  'Invites were sent on': 'Invites were sent on',
  'Respondents added after scheduled date will be invited automatically':
    'Respondents added after scheduled date will be invited automatically',
  'Invite via email': 'Invite via email',
  'Download invite links in a csv file': 'Download invite links in a csv file',
  'Reports are generated after completing the assessment': 'Reports are generated after completing the assessment',
  'Add respondents': 'Add respondents',
  'There are no respondents added': 'There are no respondents added',
  "Sorry, we couldn't find any respondents": "Sorry, we couldn't find any respondents",
  'You may start adding respondents via add or import respondents actions':
    'You may start adding respondents via add or import respondents actions',
  'Add respondents groups or respondents': 'Add respondents groups or respondents',
  'Select group or respondents to be added to this project': 'Select group or respondents to be added to this project',
  'credits per respondent will be frozen from your balance to ensure the project completion':
    'credits per respondent will be frozen from your balance to ensure the project completion',
  'Respondents have been added': 'Respondents have been added',
  Active: 'Active',
  Unavailable: 'Unavailable',
  'There are no reports available for selected test and language':
    'There are no reports available for selected test and language',
  'Please make sure you selected correct language or contact your administrator for help':
    'Please make sure you selected correct language or contact your administrator for help',
  'This field is required': 'This field is required',
  'Report format': 'Report format',
  'Change report format': 'Change report format',
  'Invite will be sent to respondents with valid email address only':
    'Invite will be sent to respondents with valid email address only',
  Percent: 'Percent',
  Undeclared: '* UNDC: Undeclared',
  'Details for': 'Details for %{categorieName} category',
  RawDetails: 'Raw data details',
  '中文(简体) / Chinese (Simplified)': 'Chinese (Simplified) testing',
  '中文(繁體) / Chinese (Traditional)': 'Chinese (Traditional)',
  '中文(香港) / Chinese(Traditional) HK': 'Chinese(Traditional) HK',
  'Administer group session': 'Administer group session',
  'Assessment info': 'Assessment info',
  'Respondent to create new record': 'Respondent to create new record',
  'Create norm based on the selected group(s)': 'Create norm based on the selected group(s)',
  'Norm name is required': 'Norm name is required',
  'Assessment is required': 'Assessment is required',
  'Ideal profile name is required': 'Ideal profile name is required',
  'Norm is required': 'Norm is required',
  'Assessment on which the ideal profile is based': 'Assessment on which the ideal profile is based',
  'Select norm': 'Select norm',
  'missing emails': 'missing emails',
  'Email invites': 'Email invites',
  'You are about to end a project': 'You are about to end a project.',
  'All active invites will be deactivated and unused credits released':
    'All active invites will be deactivated and unused credits released.',
  'It will be not possible to revert the action': 'It will be not possible to revert the action',

  'You are about to send reminder to the selected respondents':
    'You are about to send reminder to the selected respondents',
  'Press “Yes” to continue': 'Press “Yes” to continue',
  'Delete 360 survey': 'Delete 360 survey',
  Ethics: 'Ethics',
  'Biodata Configuration': 'Biodata templates',
  'Loading biodata templates': 'Loading biodata templates',
  'Add Bio Data template': 'Add Bio Data template',
  'Add Name': 'Add name',
  'Add description': 'Add description',
  selecteditcategoryvalues: 'Select or edit the values:',
  'Add values to category': 'Add values to category ',
  biodatadefaulttemplate: 'Set as default',
  'Delete selected biodata template(s)': 'Delete selected biodata template(s)',
  'Biodata Template has been created': 'Biodata Template has been created',
  'Biodata Template has been updated': 'Biodata Template has been updated',
  'Biodata template(s) deleted': 'Biodata template(s) deleted',
  'Default Biodata Template has been updated': 'Default Biodata Template has been updated',
  'Update default biodata template': 'Update default biodata template',
  'Are you sure you want to set this template as default?': 'Are you sure you want to set this template as default?',
  'Delete biodata templates': 'Delete biodata templates',
  'the selected biodata templates?': ' the selected biodata templates?',
  'this biodata template?': ' this biodata template?',
  'There are no respondents added to this group': 'There are no respondents added to this group',
  'Ok, got it': 'Ok, got it',
  'The administered group session has been created': 'The administered group session has been created',
  'Managed group session in progress': 'Managed group session in progress',
  'Session link': 'Session link',
  'No respondents to display': 'No respondents to display',
  "You may add respondents to the group from the existing respondents' list, or they can create new records during the session authorization via the session link":
    "You may add respondents to the group from the existing respondents' list, or they can create new records during the session authorization via the session link",
  'Link copied successfully': 'Link copied successfully',
  'Respondent sessions': 'Respondent sessions',
  'End session': 'End session',
  'Do you want to close the session? Assessments that have started may still be completed but no new respondents may be added':
    'Do you want to close the session? Assessments that have started may still be completed but no new respondents may be added.',
  'You are about to launch a managed group session. While the session is active, actions with the group will be limited':
    'You are about to launch a managed group session. While the session is active, actions with the group will be limited.',
  Expired: 'Expired',
  Id: 'Id',
  DOB: 'DOB',
  'There are no sessions in this project': 'There are no sessions in this project',
  'You may start creating sessions via add or import sessions actions':
    'You may start creating sessions via add or import sessions actions',
  'Number of raters': 'Number of raters',
  'Import complete': 'Import complete',
  'Records imported': 'Records imported',
  'Administer answer sheet for selected respondents': 'Administer answer sheet for selected respondents',
  'Invite respondent(s)': 'Invite respondent(s)',
  'Remove respondents': 'Remove respondents',
  'Are you sure you want to remove selected respondents?': 'Are you sure you want to remove selected respondents?',
  biodataTooltip:
    'Biodata templates allow customised biographical data collection from respondents. Create biodata templates in the Settings area.',
  popupEmailTemplateChangesNotSaved:
    'Warning: Your changes have not been saved. If you leave now, all unsaved changes will be lost. Do you want to leave without saving?',
  'Reset assessment deadline': 'Reset expiry date',
  downloadInvitesReport: 'Download csv',
  popupEmailTemplateLanguageChange: 'Warning: your current changes will be lost, do you want to continue?',
  updateAssessmentInvitationToast: 'Assessment invite(s) has been updated',
  updateProjectInvitationToast: 'Project invite(s) has been updated',
  DEFAULT: 'DEFAULT',
  'Bio data template': 'Bio data template',
  defaultCompleteBioTemplate: '** Default biodata (All categories included) **',
  defaultEmptyBioTemplate: '** Empty biodata (No categories included) **',
  invitesSendedToast: 'Invite(s) has been sent',
  selectNormForReport: 'Please select norm',
  selectIdealProfileForReport: 'Please select ideal profile',
  updateGroupToast: 'Group has been updated',
  updateGroupErrorToast: 'Error during group update',
  'Edit Group Details': 'Edit Group Details',
  'Duplicate Group': 'Duplicate Group',
  fullName: 'full_name',
  email: 'email',
  inviteURL: 'invite_url',
  testID: 'test_id',
  langID: 'lang_id',
  csvReportName: 'Rapporto Inviti %{date}.csv',
  notificationNormCreated: 'Norm "%{name}" was created',
  missingRequestedNorms: 'There are missing norms and/or ideal profiles for the selected reports',
  ownershipTitle: '<b>Registered to </b><br><b>V3.0.1 (2023)</b><br><b>Copyright Psytech International Ltd</b><br><br>',
  ownershipBody:
    'The application and Documentation are the intellectual property of Psytech International Ltd (Psytech) and are protected by applicable copyright laws, international treaty provisions and other applicable laws of the country in which the application is being used. The structure, organization and source code of any aspect of the application are valuable trade secrets and confidential information of Psytech. You grant Psytech the right and license to retain and use any contributions or suggestions provided for any purpose in its current or future products or services, unless by prior written agreement, without further compensation to you and without your approval of such retention or use. <br><br>Your possession and use of this application does not grant you any rights or title to any intellectual property rights in the application or its Documentation. All such rights including all associated copyrights, patents, trade secret rights, trademarks and other intellectual property rights, are reserved by Psytech International Ltd.&nbsp;',
  FAQs: 'FAQs',
  oneRemainingNorm: 'At least one norm must remain visible for this test',
};
export default translations;
