import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setLocale, I18n } from 'react-redux-i18n';

import CustomSelect from './Selects/Select';

import langList from '../../constants/selectableLanguages';

const StyledCustomSelect = styled(CustomSelect)`
  width: calc(100% - 4rem);
  margin-top: 2rem;
`;

const SelectLanguage = () => {
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.i18n.locale);

  const defaultSelectedLang = langList.find((lang) => lang.value.toLowerCase() === locale);

  return (
    <StyledCustomSelect
      placeholder={I18n.t('Language')}
      options={langList}
      selectProps={{
        value: defaultSelectedLang,
        onChange: (option) => {
          if (option.value) {
            const lang = option.value.toLowerCase();

            dispatch(setLocale(lang));
            window.location.reload(false);
          }
        },
        menuPlacement: 'auto',
      }}
    />
  );
};

export default SelectLanguage;
