import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { orderBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { push } from 'redux-first-history';
import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as HideIcon } from '../../../assets/icons/eye-hide.svg';
import { ReactComponent as ShowIcon } from '../../../assets/icons/eye-show-white.svg';
import { ReactComponent as AddNormIcon } from '../../../assets/icons/norm_add.svg';
import { ReactComponent as Ftype } from '../../../assets/icons/settings/ftype.svg';
import { ReactComponent as SDtype } from '../../../assets/icons/settings/sdtype.svg';
import CustomBadge from '../../../components/reusable/Badges/CustomBadge';
import useBulkSidebarState from '../../../hooks/useBulkSidebarState';
import useModals from '../../../hooks/useModals';
import apiInstance2 from '../../../services/apiService';
import { getUserNorms } from '../../../store/settings/norms/actions';
import * as actions from '../../../table/actionsList';
import normsColumns from '../../../table/columns/norms';
import normsTableActions from '../../../table/tableActions/norms';
import createToastNotification from '../../../utils/createToastNotification';
import BulkActionsSidebar from '../../BulkActionsSidebar';
import PageWrapper from '../../PageWrapper';
import ConfirmationModal from '../../reusable/ConfirmationModal';
import TableTabs from '../../reusable/TableTabs';
import AddNormModal from '../AddNormModal';

import TEST_WITHOUT_NORMS from '../../../constants/assessmentWithoutNorm';

ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, SetFilterModule]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

const Norms = () => {
  const dispatch = useDispatch();
  const { accountID, userID } = useSelector((state) => state.user.user);
  const [renderKey, setRenderKey] = useState('');
  const norms = useSelector((state) => {
    return state.settingsNorms.norms;
  });

  const filterOptions = useSelector((state) => {
    return state.settingsNorms.filteringOptions;
  });

  const filterOptionsReady = useMemo(() => {
    return !!Object.keys(filterOptions).length;
  }, [filterOptions]);

  const modals = [actions.ADD_NEW_NORM, actions.DELETE_NORMS, actions.SHOW_NORM_REPORTS, actions.HIDE_NORM_REPORTS];
  const { modalsState, openModal, closeModal } = useModals(modals);

  const [selectedRawNorms, setSelectedRawNorms] = useState([]);
  const [selectedNorms, setSelectedNorms] = useState([]);
  const [shouldResetPage, setShouldResetPageStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const selectedTestId = useSelector((state) => {
    return state.settingsNorms.selectedTestId;
  });

  const orderedNorms = orderBy(norms, 'name');

  const tableColumns = useMemo(() => {
    return normsColumns;
  }, []);

  // Controlling bulk actions sidebar state
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedNorms });

  useEffect(() => {
    setRenderKey(Math.random() * 10);
    dispatch(
      getUserNorms(() => {
        setShouldResetPageStatus(false);
        setIsLoading(false);
      }),
    );
  }, [shouldResetPage]);

  const onNormsDelete = useCallback(() => {
    apiInstance2
      .delete('/api/v2/norms', { data: selectedNorms })
      .then(() => {
        closeModal(actions.DELETE_NORMS);
        createToastNotification({ message: I18n.t('normsDeletionToast') });
        setSelectedNorms([]);
        setShouldResetPageStatus(true);
      })
      .catch(() => {
        closeModal(actions.DELETE_NORMS);
      });
  }, [selectedNorms]);

  const tableActions = useMemo(() => {
    return normsTableActions.map((item) => ({
      ...item,
      handler: () => openModal(actions.ADD_NEW_NORM),
    }));
  }, []);

  const normsBulkActions = [
    {
      name: actions.ADD_NEW_NORM,
      label: I18n.t('Add new norm'),
      Icon: AddNormIcon,
    },
    {
      name: actions.SHOW_NORM_REPORTS,
      label: I18n.t('visibleNormForReport'),
      Icon: ShowIcon,
      isDisabled: selectedRawNorms.every((item) => item.visible === true),
    },
    {
      name: actions.HIDE_NORM_REPORTS,
      label: I18n.t('hideNormForReport'),
      Icon: HideIcon,
      isDisabled: selectedRawNorms.every((item) => item.visible === false),
    },
    {
      name: actions.DELETE_NORMS,
      label: I18n.t('Delete norm(s)'),
      Icon: DeleteIcon,
      isDisabled: selectedRawNorms.some((item) => item.normID <= 1000000),
    },
  ];
  
  const bulkActionsHandlers = {
    [actions.ADD_NEW_NORM]: () => openModal(actions.ADD_NEW_NORM),
    [actions.DELETE_NORMS]: () => openModal(actions.DELETE_NORMS),
    [actions.SHOW_NORM_REPORTS]: () => openModal(actions.SHOW_NORM_REPORTS),
    [actions.HIDE_NORM_REPORTS]: () => openModal(actions.HIDE_NORM_REPORTS),
  };

  const bulkActions = useMemo(() => {
    return normsBulkActions.map((item) => ({ ...item, handler: bulkActionsHandlers[item.name] }));
  }, [selectedNorms]);

  const navigateToNorm = ({ normID }) => {
    dispatch(push(`/settings/norms/${normID}`));
  };

  const tabs = [
    { name: 'assessments-and-batteries', label: 'Assessment and Batteries' },
    { name: 'ideal-profiles', label: 'Ideal Profiles' },
    { name: 'norms', label: 'Norms' },
  ];

  const rowCheckedOnChangeHandler = (rows) => {
    setSelectedRawNorms(rows);
    setSelectedNorms(rows.map((item) => item.normID));
  };
  const allVisibleNorms = orderedNorms.filter(
    (norm) => String(norm.testID).toUpperCase() === selectedTestId && norm.visible === true,
  ).length;
  const areAllNormsSelected =
    orderedNorms.filter((norm) => String(norm.testID).toUpperCase() === selectedTestId).length === selectedNorms.length;
  const handleUpdateNormVisibility = useCallback(
    (modal) => {
      const visibilityAction = modal === 'SHOW_NORM_REPORTS';
      if (!visibilityAction && allVisibleNorms === 1 && !TEST_WITHOUT_NORMS.includes(selectedTestId)) {
        createToastNotification({ type: 'error', message: I18n.t('oneRemainingNorm') });
        closeModal(actions[modal]);
        return;
      }
      if (!visibilityAction && areAllNormsSelected && !TEST_WITHOUT_NORMS.includes(selectedTestId)) {
        createToastNotification({ type: 'error', message: I18n.t('oneRemainingNorm') });
        closeModal(actions[modal]);
        return;
      }
      const normsIDs = selectedRawNorms.filter((norm) => norm.visible !== visibilityAction).map((norm) => norm.normID);
      apiInstance2
        .post(
          `/api/v2/user/norms/visibility?accountId=${accountID}&userId=${userID}&visible=${visibilityAction}`,
          normsIDs,
        )
        .then(() => {
          closeModal(actions[modal]);
          createToastNotification({ message: I18n.t('normsVisibilityToast') });
          setSelectedNorms([]);
          setShouldResetPageStatus(true);
        })
        .catch(() => {
          closeModal(actions[modal]);
        });
    },
    [selectedRawNorms],
  );

  const agGridDefaultSettings = {
    pagination: true,
    paginationPageSize: 50,
    paginationPageSizeSelector: [10, 20, 50],
    rowHeight: '58',
    headerHeight: '58',
    domLayout: 'autoHeight',
    gridOptions: { popupParent: document.body },
    onSelectionChanged: (event) => {
      const selectedData = [];
      event.api.forEachNodeAfterFilter((node) => {
        if (node.isSelected()) {
          selectedData.push(node.data);
        }
      });
      setSelectedNorms(selectedData);
    },
  };

  const agGriddefaultColumnDefinition = {
    sortable: true,
  };

  const [columnDefinition] = useState([
    {
      field: 'name',
      headerName: 'Name',
      cellDataType: 'text',
      filter: 'agTextColumnFilter',
      flex: 3,
      cellRenderer: ({ data }) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconWrapper>
              {data.type === 'F' && <Ftype />}
              {data.type === 'SD' && <SDtype />}
            </IconWrapper>
            <span>{data.name}</span>
            {data.normID > 1000000 && <CustomBadge />}
          </div>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      cellDataType: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
    },
    {
      field: 'sampleSize',
      headerName: 'Sample Size',
      cellDataType: 'number',
      filter: 'agNumberColumnFilter',
      flex: 2,
    },
    {
      field: 'language',
      headerName: 'Language',
      cellDataType: 'text',
      filter: 'agSetColumnFilter',
      flex: 2,
    },
    {
      field: 'countryOfOrigin',
      headerName: 'Country of origin',
      cellDataType: 'text',
      filter: 'agSetColumnFilter',
      flex: 2,
    },
    {
      field: 'creationDate',
      headerName: 'Creation Date',
      cellDataType: 'date',
      flex: 2,
      filter: 'agDateColumnFilter',
      valueGetter: (params) => {
        return new Date(params.data.creationDate);
      },
    },
    {
      field: 'visible',
      headerName: 'Visible',
      cellDataType: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
      valueGetter: (params) => (params.data.visible ? 'True' : 'False'),
    },
    {
      field: 'testID',
      headerName: 'Questionnaire',
      cellDataType: 'text',
      filter: 'agSetColumnFilter',
      flex: 2,
    },
  ]);

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
    };
  }, []);

  const handleFilterChanged = (params) => {
    params.api.deselectAll();
    params.api.refreshHeader();
  };

  const handleRowClick = (event) => {
    if (
      event.event?.target?.closest('[col-id="ag-Grid-ControlsColumn"]')?.getAttribute('col-id') !==
      'ag-Grid-ControlsColumn'
    )
      navigateToNorm(event.data);
  };

  const onFirstDataRendered = (params) => {
    params.api.getFilterInstance('testID', (filterInstance) => {
      filterInstance.setModel({ values: ['15FQ+'] });
      params.api.onFilterChanged();
    });
  };

  return (
    <PageWrapper
      key={renderKey}
      title={I18n.t('Assessment settings')}
      backButtonHandler={() => dispatch(push(`/settings`))}
      buttons={tableActions}
      style={{ overflowY: 'auto' }}
    >
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      <TableTabs
        tabs={tabs}
        activeTab={tabs[2]}
        clickHandler={(tab) => {
          dispatch(push(tab.name));
        }}
      />
      <div className="ag-theme-quartz ag-grid-custom-styles">
        <AgGridReact
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          rowData={isLoading ? null : norms}
          rowSelection={rowSelection}
          onRowClicked={handleRowClick}
          onFilterChanged={handleFilterChanged}
          onFirstDataRendered={onFirstDataRendered}
        />
      </div>
      {modalsState[actions.ADD_NEW_NORM] && (
        <AddNormModal
          onClose={() => closeModal(actions.ADD_NEW_NORM)}
          setShouldResetPageStatus={setShouldResetPageStatus}
        />
      )}
      {modalsState[actions.SHOW_NORM_REPORTS] && (
        <ConfirmationModal
          isVisible={modalsState[actions.SHOW_NORM_REPORTS]}
          title={I18n.t('showNormTitle')}
          description={`${I18n.t('showConfirmationMessageTitle')} ${
            selectedNorms.length > 1
              ? I18n.t('showhideConfirmationMessageDescription1')
              : I18n.t('showhideConfirmationMessageDescription2')
          }`}
          onClose={() => closeModal(actions.SHOW_NORM_REPORTS)}
          onConfirm={() => handleUpdateNormVisibility('SHOW_NORM_REPORTS')}
          caution
        />
      )}
      {modalsState[actions.HIDE_NORM_REPORTS] && (
        <ConfirmationModal
          isVisible={modalsState[actions.HIDE_NORM_REPORTS]}
          title={I18n.t('hideNormTitle')}
          description={`${I18n.t('hideConfirmationMessageTitle')} ${
            selectedNorms.length > 1
              ? I18n.t('showhideConfirmationMessageDescription1')
              : I18n.t('showhideConfirmationMessageDescription2')
          }`}
          onClose={() => closeModal(actions.HIDE_NORM_REPORTS)}
          onConfirm={() => handleUpdateNormVisibility('HIDE_NORM_REPORTS')}
          caution
        />
      )}
      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_NORMS]}
        title={I18n.t('Delete Norm')}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selectedNorms.length > 1 ? I18n.t('selected norms?') : I18n.t('this norm?')
        }`}
        onClose={() => closeModal(actions.DELETE_NORMS)}
        onConfirm={onNormsDelete}
        caution
      />
    </PageWrapper>
  );
};

const IconWrapper = styled.div`
  width: 28px;
  height: 28px;
  margin-right: 12px;
`;

export default Norms;
