import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { object, string } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import { I18n, Translate } from 'react-redux-i18n';

import respondentsColumns from '../../table/columns/respondents';
import {
  setSelectedRespondents,
  deleteRespondents,
  fetchRespondents,
  fetchRespondentsForGroup,
  removeRespondentsFromGroup,
  changeShouldResetRespondentsTableBoolState,
  resetRespondentsTableState,
  setRespondentsSearchValue,
  setRespondentsFilterValue,
  setRespondentsFilterType,
  applyRespondentsFilters,
  clearRespondentsFilters,
  deleteRespondentsAppliedFilter,
} from '../../store/respondents/actions';
import { setActiveGroup, fetchGroupsById, fetchAllGroups } from '../../store/groups/actions';

// import { selectActiveGroupName } from '../../store/respondents/selectors';

import Table from '../reusable/Tables/Table';
import ConfirmationModal from '../reusable/ConfirmationModal';

import PageWrapper from '../PageWrapper';
import Invite from './CreateInvite';
import AnswerSheetModal from './AnswerSheet';
import AdministerAssessmentModal from './AdministerAssessment';
import RequestReportModal from './RequestReportModal';
import ManageTagsModal from './ManageTagsModal';
import AddToGroup from './AddToGroup';
import AddRespondentModal from './AddRespondentModal';
import ImportRespondents from './ImportRespondents';
import GroupHeader from '../Groups/GroupHeader';
import AdvancedFilter from '../reusable/AdvancedFilter';
import Spinner from '../reusable/Spinner';

import useModals from '../../hooks/useModals';

import respondentsBulkActions from '../../table/bulkActions/respondents';
import respondentsTableActions from '../../table/tableActions/respondents';
import BulkActionsSidebar from '../BulkActionsSidebar';
import TableTabs from '../reusable/TableTabs';

import * as actions from '../../table/actionsList';

import useTableFilter from '../../hooks/useTableFilter';
import useBulkSidebarState from '../../hooks/useBulkSidebarState';
import AddNewGroup from '../Groups/AddNewGroup';
import { ReactComponent as EmptyIcon } from '../../assets/icons/empty_state.svg';

const Respondents = ({ location, groupId }) => {
  // Data from redux store and redux actions
  const user = useSelector((state) => state.user.user);
  const respondents = useSelector((state) => state.respondents.respondents);
  const selectedRespondents = useSelector((state) => state.respondents.selectedRespondents);
  // const selectedGroupName = useSelector(selectActiveGroupName);
  const searchValue = useSelector((state) => state.respondents.search);
  const filter = useSelector((state) => state.respondents.filter);
  const pageCount = useSelector((state) => state.respondents.pagesAvailable);
  const totalCount = useSelector((state) => state.respondents.totalCount);
  const lastPageIndex = useSelector((state) => state.respondents.lastPageIndex);
  const shouldResetTableState = useSelector((state) => state.respondents.shouldResetTableState);
  const activeGroupInView = useSelector((state) => state.groups.activeGroupInView);
  const groupsTotalCount = useSelector((state) => state.groups.allGroups);

  // Bulk actions related
  const showBulkActionsRow =
    respondents.length !== 0 &&
    selectedRespondents.length !== totalCount &&
    respondents.length === selectedRespondents.length;
  const [isBulkFlowActive, setBulkFlowStatus] = useState(false);

  useEffect(() => {
    if (!showBulkActionsRow) setBulkFlowStatus(false);
  }, [showBulkActionsRow]);

  const dispatch = useDispatch();
  const [renderKey, setRenderKey] = useState(Math.round(Math.random() * 1000));

  const memoizedSetRespondentsSearchValue = useCallback((val) => dispatch(setRespondentsSearchValue(val)), []);
  const memoizedDeleteRespondentsAppliedFilter = useCallback(
    (fieldName) => dispatch(deleteRespondentsAppliedFilter(fieldName)),
    [],
  );
  const memoizedClearRespondentsFilters = useCallback(() => dispatch(clearRespondentsFilters()), []);

  useEffect(() => {
    if (shouldResetTableState) {
      setRenderKey(Math.round(Math.random() * 1000));
      dispatch(changeShouldResetRespondentsTableBoolState(false));
    }
  }, [shouldResetTableState]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    return () => dispatch(resetRespondentsTableState());
  }, [location.pathname]);

  // ACTIVE GROUP DATA MANAGEMENT PART
  // component is also used when we visit /groups/${groupId} route to edit/view group
  useEffect(() => {
    if (groupId) dispatch(fetchGroupsById(groupId));
  }, [groupId]);

  // clear active group on onmount if it's present
  useEffect(() => {
    return () => {
      if (activeGroupInView) dispatch(setActiveGroup(null));
    };
  }, [groupId]);

  useEffect(() => {
    dispatch(fetchAllGroups());
  }, []);

  const [isLoading, setLoadingStatus] = useState(true);
  const [shouldResetPage, setShouldReset] = useState(false);

  const onSelectChange = useCallback((data) => {
    dispatch(setSelectedRespondents(data));
  }, []);

  // we need data from different endpoints depending on the route; if we are on /respondents - fetch usual list of all respondents, but if we are on /groups/groupId - we need only to fetch respondents that are part of the group
  const fetchData = useCallback(
    ({ tableData, shouldReset, showSpinner, tableCallback }) => {
      if (showSpinner) setLoadingStatus(true);
      const func = groupId ? fetchRespondentsForGroup : fetchRespondents;
      const args = [
        tableData,
        {
          callback: () => {
            setLoadingStatus(false);
            tableCallback();
          },
          shouldReset,
        },
      ];
      if (groupId) args.unshift(groupId);
      dispatch(func(...args));
    },
    [groupId],
  );

  // Controlling bulk actions sidebar state
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedRespondents });

  const modals = [
    actions.REQUEST_REPORT,
    actions.ANSWER_SHEET,
    actions.ADMINISTER_ASSESSMENT,
    actions.CREATE_INVITE,
    actions.MANAGE_TAGS,
    actions.ADD_TO_GROUP,
    actions.ADD_RESPONDENT,
    actions.DELETE_RESPONDENT,
    actions.CREATE_GROUP_FROM_SELECTION,
    actions.IMPORT_RESPONDENTS,
    actions.REMOVE_RESPONDENTS_FROM_GROUP,
  ];

  const { modalsState, openModal, closeModal } = useModals(modals);

  const onRespondentDelete = () => {
    dispatch(
      deleteRespondents(
        selectedRespondents.map((item) => item.respondentID),
        () => {
          closeModal(actions.DELETE_RESPONDENT);
          setShouldReset(true);
        },
      ),
    );
  };

  const omRemoveFromGroup = () => {
    dispatch(
      removeRespondentsFromGroup(
        groupId,
        selectedRespondents.map((item) => item.respondentID),
        () => {
          closeModal(actions.REMOVE_RESPONDENTS_FROM_GROUP);
          setShouldReset(true);
        },
      ),
    );
  };

  // needed for table row rendering optimization, attempt to prevent unnecessary rerenders
  const rowEqualityFunc = useCallback(
    (prevItem, nextItem) =>
      prevItem.tags.length === nextItem.tags.length &&
      prevItem.assessmentsDone.length === nextItem.assessmentsDone.length &&
      prevItem.groups.length === nextItem.groups.length &&
      prevItem.firstName === nextItem.firstName &&
      prevItem.familyName === nextItem.familyName &&
      prevItem.email === nextItem.email,
    [],
  );

  // table actions
  const tableHandlers = {
    [actions.ADD_RESPONDENT]: () => openModal(actions.ADD_RESPONDENT),
    [actions.IMPORT_RESPONDENTS]: () => openModal(actions.IMPORT_RESPONDENTS),
  };
  const tableActions = useMemo(() => {
    return respondentsTableActions.map((item) => ({ ...item, handler: tableHandlers[item.name] }));
  }, []);

  // bulk and row actions
  const navigateToEdit = (respondent) => {
    dispatch(push(`/respondents/${respondent.respondentID}`));
    dispatch(setSelectedRespondents([]));
  };
  const bulkActions = useMemo(() => {
    return respondentsBulkActions(Boolean(groupId), user).map((item) => ({
      ...item,
      handler: () =>
        item.name === actions.EDIT_RESPONDENT ? navigateToEdit(selectedRespondents[0]) : openModal(item.name),
      isDisabled:
        typeof item.isDisabled === 'function'
          ? item.isDisabled(selectedRespondents, isBulkFlowActive)
          : item.isDisabled,
    }));
  }, [selectedRespondents, isBulkFlowActive, user]);

  // columns settings
  const columns = useMemo(() => {
    return respondentsColumns;
  }, []);
  // Table filtering
  const setFilterType = (name, value) => dispatch(setRespondentsFilterType(name, value));
  const setFilterValue = (name, value) => dispatch(setRespondentsFilterValue(name, value));
  const { inputsWithHandlers, appliedFiltersWithColumnNames } = useTableFilter({
    filter,
    columns,
    setFilterType,
    setFilterValue,
  });

  const onFilterApply = () => {
    dispatch(applyRespondentsFilters());
  };

  const tabs = useMemo(() => {
    return [
      { name: 'respondents', label: `${I18n.t('List')} (${totalCount})` },
      { name: 'groups', label: `${I18n.t('Groups')} (${groupsTotalCount.length})` },
    ];
  }, [totalCount, groupsTotalCount.length]);

  const onTabClick = (tab) => {
    if (tab.name === 'groups') dispatch(push('/groups'));
  };

  const NoContent = () => {
    const areFiltersEmpty = !Object.entries(appliedFiltersWithColumnNames).filter((item) => Boolean(item.value)).length;
    return (
      <EmptyMessageWrapper>
        <EmptyIcon />
        <EmptyMessage>
          {groupId && areFiltersEmpty && !searchValue
            ? I18n.t(`There are no respondents added to this group`)
            : I18n.t(`Sorry, we could not find any respondents`)}
        </EmptyMessage>
      </EmptyMessageWrapper>
    );
  };
  // initial table state
  const initialState = useMemo(() => ({ sortBy: [{ id: 'Creation date', desc: true }] }), []);
  const groupTitle = activeGroupInView ? activeGroupInView.description : '';
  const pageTitle = groupId ? groupTitle : <Translate value="Respondents" />;
  const onBackButtonClick = () => dispatch(push('/groups'));
  return (
    <PageWrapper
      key={renderKey}
      title={pageTitle}
      buttons={tableActions}
      backButtonHandler={groupId ? onBackButtonClick : undefined}
    >
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      {!groupId && <TableTabs tabs={tabs} activeTab={tabs[0]} clickHandler={onTabClick} />}
      {groupId && activeGroupInView && <GroupHeader group={activeGroupInView} />}
      <AdvancedFilter
        searchProps={{
          appliedFilters: appliedFiltersWithColumnNames,
          deleteFilter: memoizedDeleteRespondentsAppliedFilter,
          searchValue,
          onSearchChange: memoizedSetRespondentsSearchValue,
        }}
        filterProps={{
          inputs: inputsWithHandlers,
          appliedInputs: filter.appliedInputs,
          applyFilters: onFilterApply,
          clearAll: memoizedClearRespondentsFilters,
          isDirty: filter.dirty,
        }}
      />
      {showBulkActionsRow && (
        <BulkActionsRow>
          {/*  TODO translations? */}
          {isBulkFlowActive ? (
            <>
              <span>{I18n.t('All respondents are selected', { respondentsCount: totalCount })}.</span>
              <BulkActionsLink onClick={() => setBulkFlowStatus(false)}>Clear selection</BulkActionsLink>
            </>
          ) : (
            <>
              <span>
                {I18n.t('All respondents on this page are selected', { respondentsCount: selectedRespondents.length })}.
              </span>
              <BulkActionsLink onClick={() => setBulkFlowStatus(true)}>
                {I18n.t('Select all respondents in this result', { respondentsCount: totalCount })}.
              </BulkActionsLink>
            </>
          )}
        </BulkActionsRow>
      )}

      <StyledTable
        columns={columns}
        data={respondents}
        idAccessor="respondentID"
        rowEqualityFunc={rowEqualityFunc}
        onRowClick={navigateToEdit}
        interceptCheckboxClick
        initialState={initialState}
        onSelectChange={onSelectChange}
        entityName="respondent"
        onFetchData={fetchData}
        totalCount={totalCount}
        searchValue={searchValue}
        appliedFilters={appliedFiltersWithColumnNames}
        pageCount={pageCount}
        lastPageIndex={lastPageIndex}
        shouldResetPage={shouldResetPage}
        setShouldReset={setShouldReset}
        customEmptyMessage={<NoContent />}
        scrollContainerHeight={showBulkActionsRow ? '96.5%' : '100%'}
      />
      <Spinner isLoading={isLoading} full text={I18n.t('Loading respondents')} />
      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_RESPONDENT]}
        title={I18n.t('Delete respondent')}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selectedRespondents.length > 1 ? I18n.t('selected respondents') : I18n.t('this respondent')
        }?`}
        onClose={() => closeModal(actions.DELETE_RESPONDENT)}
        onConfirm={onRespondentDelete}
      />
      <ConfirmationModal
        isVisible={modalsState[actions.REMOVE_RESPONDENTS_FROM_GROUP]}
        title={I18n.t('Delete respondents from group')}
        description={`${I18n.t('Are you sure you want to remove')} ${
          selectedRespondents.length > 1 ? I18n.t('selected respondents') : I18n.t('this respondent')
        } ${I18n.t('from the group?')}`}
        onClose={() => closeModal(actions.REMOVE_RESPONDENTS_FROM_GROUP)}
        onConfirm={omRemoveFromGroup}
      />
      {modalsState[actions.CREATE_INVITE] && selectedRespondents.length && (
        <Invite onClose={() => closeModal(actions.CREATE_INVITE)} respondents={selectedRespondents} />
      )}
      {modalsState[actions.ANSWER_SHEET] && (
        <AnswerSheetModal
          onClose={() => closeModal(actions.ANSWER_SHEET)}
          respondents={selectedRespondents}
          onSuccess={() => setShouldReset(true)}
        />
      )}
      {modalsState[actions.ADMINISTER_ASSESSMENT] && (
        <AdministerAssessmentModal
          onClose={() => closeModal(actions.ADMINISTER_ASSESSMENT)}
          respondents={selectedRespondents}
        />
      )}
      {modalsState[actions.REQUEST_REPORT] && selectedRespondents.length && (
        <RequestReportModal onClose={() => closeModal(actions.REQUEST_REPORT)} respondents={selectedRespondents} />
      )}

      {modalsState[actions.MANAGE_TAGS] && (
        <ManageTagsModal
          onClose={() => closeModal(actions.MANAGE_TAGS)}
          respondents={selectedRespondents}
          onSuccess={() => setShouldReset(true)}
        />
      )}
      {modalsState[actions.ADD_TO_GROUP] && (
        <AddToGroup onClose={() => closeModal(actions.ADD_TO_GROUP)} onSuccess={() => setShouldReset(true)} />
      )}
      {modalsState[actions.ADD_RESPONDENT] && (
        <AddRespondentModal onClose={() => closeModal(actions.ADD_RESPONDENT)} groupId={groupId} />
      )}
      {modalsState[actions.CREATE_GROUP_FROM_SELECTION] && (
        <AddNewGroup
          onClose={() => closeModal(actions.CREATE_GROUP_FROM_SELECTION)}
          selectedRespondents={selectedRespondents}
          isBulkFlowActive={isBulkFlowActive}
        />
      )}
      {modalsState[actions.IMPORT_RESPONDENTS] && (
        <ImportRespondents
          onClose={() => closeModal(actions.IMPORT_RESPONDENTS)}
          onSuccess={() => setShouldReset(true)}
          groupId={groupId}
        />
      )}
    </PageWrapper>
  );
};

Respondents.propTypes = {
  location: object,
  groupId: string,
};

Respondents.defaultProps = {
  location: {},
  groupId: '',
};

const StyledTable = styled(Table)`
  tr:hover {
    & > div div {
      background: rgba(241, 241, 241, 0.1);
    }
  }
`;

const BulkActionsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.6rem 0;
  background-color: ${(props) => props.theme.colors.grey1};
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;

  span {
    color: #4f4f4f;
    margin-right: 0.5rem;
  }
`;

const BulkActionsLink = styled.a`
  color: ${(props) => props.theme.colors.mediumBlue};
  text-decoration: none;

  :hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.darkBlue};
  }
`;

const EmptyMessageWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EmptyMessage = styled.p`
  margin: 15px 0 0 0;
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
`;

export default Respondents;
