import { I18n } from 'react-redux-i18n';

export const educationOptions = [
  { label: I18n.t('No Qualification'), value: 'q-no' },
  { label: I18n.t('Secondary School'), value: 'q-se' },
  { label: I18n.t('Industry or Trade Training'), value: 'q-in' },
  { label: I18n.t('Professional Qualification'), value: 'q-pr' },
  { label: I18n.t('Tertiary Institute Diploma/Certificate'), value: 'q-te' },
  { label: I18n.t('University Bachelor Degree'), value: 'q-un' },
  { label: I18n.t('Postgraduate Diploma/Certificate'), value: 'q-po' },
  { label: I18n.t('Bachelor Honours'), value: 'q-ba' },
  { label: I18n.t('Masters Degree'), value: 'q-ma' },
  { label: I18n.t('Doctorate Degree'), value: 'q-do' },
];

export const ethnicitiesToHideInIt = [
  'e-aa',
  'e-ab',
  'e-af',
  'e-ak',
  'e-am',
  'e-ao',
  'e-ar',
  'e-as',
  'e-bb',
  'e-bc',
  'e-bi',
  'e-bin',
  'e-bl',
  'e-bo',
  'e-cas',
  'e-cat',
  'e-ce',
  'e-ck',
  'e-cyg',
  'e-ea',
  'e-eu',
  'e-fm',
  'e-ira',
  'e-je',
  'e-la',
  'e-lm',
  'e-lt',
  'e-md',
  'e-mr',
  'e-ne',
  'e-ot',
  'e-pe',
  'e-sc',
  'e-se',
  'e-si',
  'e-sic',
  'e-sl',
  'e-wh',
  'e-wi',
];

export const ethnicityOptions = [
  { label: I18n.t('African American'), value: 'e-aa' },
  { label: I18n.t('Aborigine'), value: 'e-ab' },
  { label: I18n.t('Algerian'), value: 'e-ae' },
  { label: I18n.t('African'), value: 'e-af' },
  { label: I18n.t('Afrikaner'), value: 'e-ak' },
  { label: I18n.t('Albanian'), value: 'e-al' },
  { label: I18n.t('American'), value: 'e-am' },
  { label: I18n.t('Argentinian'), value: 'e-an' },
  { label: I18n.t('Australian Aborigine'), value: 'e-ao' },
  { label: I18n.t('Arab'), value: 'e-ar' },
  { label: I18n.t('Asian'), value: 'e-as' },
  { label: I18n.t('Austrian'), value: 'e-at' },
  { label: I18n.t('Australian'), value: 'e-au' },
  { label: I18n.t('Bangladeshi'), value: 'e-ba' },
  { label: I18n.t('Balinese'), value: 'e-bal' },
  { label: I18n.t('Black British'), value: 'e-bb' },
  { label: I18n.t('Black Canadian'), value: 'e-bc' },
  { label: I18n.t('Belgian'), value: 'e-bg' },
  { label: I18n.t('Black Carribean'), value: 'e-bi' },
  { label: I18n.t('Black Indian'), value: 'e-bin' },
  { label: I18n.t('Black African'), value: 'e-bl' },
  { label: I18n.t('Bolivian'), value: 'e-blv' },
  { label: I18n.t('Bengali'), value: 'e-bn' },
  { label: I18n.t('Black Other'), value: 'e-bo' },
  { label: I18n.t('Bosnian'), value: 'e-bos' },
  { label: I18n.t('Brazilian'), value: 'e-br' },
  { label: I18n.t('British'), value: 'e-bt' },
  { label: I18n.t('Belarusian'), value: 'e-bu' },
  { label: I18n.t('Bulgarian'), value: 'e-bul' },
  { label: I18n.t('Burmese'), value: 'e-bur' },
  { label: I18n.t('Canadian'), value: 'e-ca' },
  { label: I18n.t('Castilian'), value: 'e-cas' },
  { label: I18n.t('Catalan'), value: 'e-cat' },
  { label: I18n.t('Celtic'), value: 'e-ce' },
  { label: I18n.t('Chinese'), value: 'e-ch' },
  { label: I18n.t('Channel Islander'), value: 'e-ci' },
  { label: I18n.t('Cossack'), value: 'e-ck' },
  { label: I18n.t('Chilean'), value: 'e-cl' },
  { label: I18n.t('Cambodian'), value: 'e-cm' },
  { label: I18n.t('Chechen'), value: 'e-cn' },
  { label: I18n.t('Colombian'), value: 'e-co' },
  { label: I18n.t('Costa Rican'), value: 'e-cr' },
  { label: I18n.t('Croatian'), value: 'e-cro' },
  { label: I18n.t('Corsican'), value: 'e-cs' },
  { label: I18n.t('Cantonese'), value: 'e-ct' },
  { label: I18n.t('Cuban'), value: 'e-cu' },
  { label: I18n.t('Cypriot'), value: 'e-cy' },
  { label: I18n.t('Cypriot Greek'), value: 'e-cyg' },
  { label: I18n.t('Czech'), value: 'e-cz' },
  { label: I18n.t('Danish'), value: 'e-da' },
  { label: I18n.t('Dutch'), value: 'e-du' },
  { label: I18n.t('Eurasian'), value: 'e-ea' },
  { label: I18n.t('Ecuadorian'), value: 'e-ec' },
  { label: I18n.t('Egyptian'), value: 'e-eg' },
  { label: I18n.t('English'), value: 'e-en' },
  { label: I18n.t('Estonian'), value: 'e-es' },
  { label: I18n.t('Ethiopian'), value: 'e-et' },
  { label: I18n.t('European'), value: 'e-eu' },
  { label: I18n.t('Fijian'), value: 'e-fj' },
  { label: I18n.t('Filipino'), value: 'e-fl' },
  { label: I18n.t('Flemish'), value: 'e-fm' },
  { label: I18n.t('Finnish'), value: 'e-fnn' },
  { label: I18n.t('French'), value: 'e-fr' },
  { label: I18n.t('Gaelic'), value: 'e-ga' },
  { label: I18n.t('Georgian'), value: 'e-ge' },
  { label: I18n.t('German'), value: 'e-gr' },
  { label: I18n.t('Greek'), value: 'e-gre' },
  { label: I18n.t('Hungarian'), value: 'e-hg' },
  { label: I18n.t('Hispanic'), value: 'e-hi' },
  { label: I18n.t('Hong Kong Chinese'), value: 'e-ho' },
  { label: I18n.t('Icelandic'), value: 'e-ic' },
  { label: I18n.t('Indonesian'), value: 'e-id' },
  { label: I18n.t('Irani'), value: 'e-ii' },
  { label: I18n.t('Indian'), value: 'e-in' },
  { label: I18n.t('Iranian/Persian'), value: 'e-ip' },
  { label: I18n.t('Iraqi'), value: 'e-iq' },
  { label: I18n.t('Irish'), value: 'e-ir' },
  { label: I18n.t('Iranian Arab'), value: 'e-ira' },
  { label: I18n.t('Iranian'), value: 'e-iran' },
  { label: I18n.t('Italian'), value: 'e-it' },
  { label: I18n.t('Jamaican'), value: 'e-ja' },
  { label: I18n.t('Jewish'), value: 'e-je' },
  { label: I18n.t('Jewish/Israeli'), value: 'e-ji' },
  { label: I18n.t('Jordanian'), value: 'e-jo' },
  { label: I18n.t('Japanese'), value: 'e-jp' },
  { label: I18n.t('Kenyan'), value: 'e-ke' },
  { label: I18n.t('Kiwi'), value: 'e-ki' },
  { label: I18n.t('Kongo'), value: 'e-ko' },
  { label: I18n.t('Korean'), value: 'e-kr' },
  { label: I18n.t('Latin'), value: 'e-la' },
  { label: I18n.t('Lebanese'), value: 'e-lb' },
  { label: I18n.t('Lithuanian'), value: 'e-lh' },
  { label: I18n.t('Libyan'), value: 'e-li' },
  { label: I18n.t('Latin American'), value: 'e-lm' },
  { label: I18n.t('Latino'), value: 'e-lt' },
  { label: I18n.t('Luxembourger'), value: 'e-lu' },
  { label: I18n.t('Latvian'), value: 'e-lv' },
  { label: I18n.t('Macedonian'), value: 'e-ma' },
  { label: I18n.t('Middle Eastern'), value: 'e-md' },
  { label: I18n.t('Malaitian'), value: 'e-ml' },
  { label: I18n.t('Maori'), value: 'e-mo' },
  { label: I18n.t('Monacan'), value: 'e-mon' },
  { label: I18n.t('Moroccan'), value: 'e-mont' },
  { label: I18n.t('Maltese'), value: 'e-mr' },
  { label: I18n.t('Mexican'), value: 'e-mt' },
  { label: I18n.t('Malay'), value: 'e-mx' },
  { label: I18n.t('Mixed Race'), value: 'e-my' },
  { label: I18n.t('New Zealand European'), value: 'e-ne' },
  { label: I18n.t('Nigerian'), value: 'e-ni' },
  { label: I18n.t('Norwegian'), value: 'e-no' },
  { label: I18n.t('Nepalese'), value: 'e-np' },
  { label: I18n.t('New Zealander'), value: 'e-nz' },
  { label: I18n.t('Other Ethnicity'), value: 'e-ot' },
  { label: I18n.t('Paraguayan'), value: 'e-pa' },
  { label: I18n.t('Pacific Islander'), value: 'e-pc' },
  { label: I18n.t('Persian'), value: 'e-pe' },
  { label: I18n.t('Peruvian'), value: 'e-per' },
  { label: I18n.t('Persian/Iranian'), value: 'e-pi' },
  { label: I18n.t('Pakistani'), value: 'e-pk' },
  { label: I18n.t('Palestinian'), value: 'e-pl' },
  { label: I18n.t('Polish'), value: 'e-po' },
  { label: I18n.t('Papua New Guinean'), value: 'e-pp' },
  { label: I18n.t('Puerto Rican'), value: 'e-pr' },
  { label: I18n.t('Portuguese'), value: 'e-pu' },
  { label: I18n.t('Polynesian'), value: 'e-py' },
  { label: I18n.t('Romanian'), value: 'e-ro' },
  { label: I18n.t('Russian'), value: 'e-ru' },
  { label: I18n.t('Rwandan'), value: 'e-tt' },
  { label: I18n.t('Samoan'), value: 'e-sam' },
  { label: I18n.t('South African Coloured'), value: 'e-sc' },
  { label: I18n.t('Scottish'), value: 'e-sco' },
  { label: I18n.t('Swede'), value: 'e-sd' },
  { label: I18n.t('South African European'), value: 'e-se' },
  { label: I18n.t('Serbian'), value: 'e-ser' },
  { label: I18n.t('Swedish'), value: 'e-sh' },
  { label: I18n.t('South American Indian'), value: 'e-si' },
  { label: I18n.t('Sicilian'), value: 'e-sic' },
  { label: I18n.t('Slovak'), value: 'e-sk' },
  { label: I18n.t('Slav'), value: 'e-sl' },
  { label: I18n.t('Spanish'), value: 'e-sp' },
  { label: I18n.t('Sri Lankan'), value: 'e-sr' },
  { label: I18n.t('Southeast Asian'), value: 'e-ss' },
  { label: I18n.t('Swiss'), value: 'e-sss' },
  { label: I18n.t('Swahili'), value: 'e-sw' },
  { label: I18n.t('Swazi'), value: 'e-sz' },
  { label: I18n.t('Taiwanese'), value: 'e-ta' },
  { label: I18n.t('Thai'), value: 'e-ti' },
  { label: I18n.t('Turkish'), value: 'e-tk' },
  { label: I18n.t('Tamil'), value: 'e-tm' },
  { label: I18n.t('Tongan'), value: 'e-to' },
  { label: I18n.t('Tunisian'), value: 'e-tu' },
  { label: I18n.t('Udi'), value: 'e-ud' },
  { label: I18n.t('Ugandan'), value: 'e-ug' },
  { label: I18n.t('Ukrainian'), value: 'e-uk' },
  { label: I18n.t('Uruguayan'), value: 'e-ur' },
  { label: I18n.t('Vietnamese'), value: 'e-vi' },
  { label: I18n.t('Welsh'), value: 'e-we' },
  { label: I18n.t('White'), value: 'e-wh' },
  { label: I18n.t('West Indian'), value: 'e-wi' },
  { label: I18n.t('Zimbabwean'), value: 'e-zi' },
  { label: I18n.t('Zulu'), value: 'e-zu' },
];

export const occupationOptions = [
  {
    label: I18n.t('Manager'),
    options: [
      { label: I18n.t('Manager'), value: 'o-man' },
      { label: I18n.t('- Chief executive'), value: 'o-exc' },
      { label: I18n.t('- Administrative or commercial manager'), value: 'o-adm' },
      { label: I18n.t('- Production or specialized services manager'), value: 'o-pro' },
      { label: I18n.t('- Hospitality, retail or other services manager'), value: 'o-hos' },
      { label: I18n.t('- Senior manager'), value: 'o-sen' },
      { label: I18n.t('- Middle manager'), value: 'o-mid' },
      { label: I18n.t('- Junior manager/supervisor'), value: 'o-jun' },
      { label: I18n.t('- Other'), value: 'o-omn' },
    ],
  },
  {
    label: I18n.t('Professional'),
    options: [
      { label: I18n.t('Professional'), value: 'o-prf' },
      { label: I18n.t('- Science or engineering professional'), value: 'o-sci' },
      { label: I18n.t('- Health professional'), value: 'o-hea' },
      { label: I18n.t('- Teaching professional'), value: 'o-tea' },
      { label: I18n.t('- Business or administration professional'), value: 'o-bus' },
      {
        label: I18n.t('- Information and communications technology professional'),
        value: 'o-inf',
      },
      { label: I18n.t('- Legal, social or cultural professional'), value: 'o-leg' },
      { label: I18n.t('- Other'), value: 'o-opr' },
    ],
  },
  {
    label: I18n.t('Technician or associate professional'),
    options: [
      { label: I18n.t('Technician or associate professional'), value: 'o-tec' },
      { label: I18n.t('- Science or engineering associate professional'), value: 'o-eng' },
      { label: I18n.t('- Health associate professional'), value: 'o-ass' },
      {
        label: I18n.t('- Business or administration associate professional'),
        value: 'o-bua',
      },
      {
        label: I18n.t('- Legal, social, cultural or related associate professional'),
        value: 'o-lgl',
      },
      { label: I18n.t('- Information and communications technician'), value: 'o-com' },
      { label: I18n.t('- Other'), value: 'o-ote' },
    ],
  },
  {
    label: I18n.t('Clerical support worker'),
    options: [
      { label: I18n.t('Clerical support worker'), value: 'o-cle' },
      { label: I18n.t('- General or keyboard clerk'), value: 'o-gen' },
      { label: I18n.t('- Customer services clerk'), value: 'o-cus' },
      { label: I18n.t('- Numerical or material recording clerk'), value: 'o-num' },
      { label: I18n.t('- Other'), value: 'o-clo' },
    ],
  },
  {
    label: I18n.t('Service or sales worker'),
    options: [
      { label: I18n.t('Service or sales worker'), value: 'o-ser' },
      { label: I18n.t('- Personal service worker'), value: 'o-per' },
      { label: I18n.t('- Sales worker'), value: 'o-sal' },
      { label: I18n.t('- Personal care worker'), value: 'o-car' },
      { label: I18n.t('- Protective services worker'), value: 'o-prt' },
      { label: I18n.t('- Other'), value: 'o-ose' },
    ],
  },
  {
    label: I18n.t('Skilled agricultural, forestry or fishery worker'),
    options: [
      { label: I18n.t('Skilled agricultural, forestry or fishery worker'), value: 'o-ski' },
      { label: I18n.t('- Market-oriented skilled agricultural worker'), value: 'o-mar' },
      {
        label: I18n.t('- Market-oriented skilled forestry, fishing or hunting worker'),
        value: 'o-for',
      },
      { label: I18n.t('- Subsistence farmer, fisher, hunter or gatherer'), value: 'o-sub' },
      { label: I18n.t('- Other'), value: 'o-osk' },
    ],
  },
  {
    label: I18n.t('Craft or related trades worker'),
    options: [
      { label: I18n.t('Craft or related trades worker'), value: 'o-cra' },
      {
        label: I18n.t('- Building or related trades worker, excluding electrician'),
        value: 'o-bui',
      },
      { label: I18n.t('- Metal, machinery or related trades worker'), value: 'o-met' },
      { label: I18n.t('- Handicraft or printing worker'), value: 'o-han' },
      { label: I18n.t('- Electrical or electronic trades worker'), value: 'o-ele' },
      {
        label: I18n.t('- Food processing, wood working, garment or other craft or related trades'),
        value: 'o-foo',
      },
      { label: I18n.t('- Other'), value: 'o-ocr' },
    ],
  },
  {
    label: I18n.t('Plant or machine operator, or assembler'),
    options: [
      { label: I18n.t('Plant or machine operator, or assembler'), value: 'o-pla' },
      { label: I18n.t('- Stationary plant or machine operator'), value: 'o-sta' },
      { label: I18n.t('- Assembler'), value: 'o-asb' },
      { label: I18n.t('- Driver or mobile plant operator'), value: 'o-dri' },
      { label: I18n.t('- Other'), value: 'o-opl' },
    ],
  },
  {
    label: I18n.t('Elementary occupation'),
    options: [
      { label: I18n.t('Elementary occupation'), value: 'o-elm' },
      { label: I18n.t('- Cleaner or helper'), value: 'o-clh' },
      { label: I18n.t('- Agricultural, forestry or fishery labourer'), value: 'o-agr' },
      {
        label: I18n.t('- Labourer in mining, construction, manufacturing or transport'),
        value: 'o-lab',
      },
      { label: I18n.t('- Food preparation assistant'), value: 'o-fop' },
      { label: I18n.t('- Street or related sales or service worker'), value: 'o-str' },
      { label: I18n.t('- Refuse worker or other elementary worker'), value: 'o-ref' },
      { label: I18n.t('- Other'), value: 'o-oel' },
    ],
  },
  {
    label: I18n.t('Armed forces occupation'),
    options: [
      { label: I18n.t('Armed forces occupation'), value: 'o-arm' },
      { label: I18n.t('- Commissioned armed forces officer'), value: 'o-cmm' },
      { label: I18n.t('- Non-commissioned armed forces officer'), value: 'o-non' },
      { label: I18n.t('- Armed forces occupation, other rank'), value: 'o-arf' },
      { label: I18n.t('- Other'), value: 'o-oar' },
    ],
  },
  {
    label: I18n.t('Other'),
    options: [{ label: I18n.t('Other'), value: 'o-ooh' }],
  },
];

export const sectorOptions = [
  { label: I18n.t('Local Government/Authority'), value: 's-lg' },
  { label: I18n.t('Other'), value: 's-ot' },
  { label: I18n.t('Private, Corporate '), value: 's-pr' },
  { label: I18n.t('Public service, Fed/State Govt'), value: 's-pu' },
  { label: I18n.t('State Owned Corporation'), value: 's-st' },
  { label: I18n.t('Unknown'), value: 's-un' },
];

export const industryOptions = [
  { label: I18n.t('Administrative and Support Service Activities'), value: 'i-ad' },
  { label: I18n.t('Agriculture, Forestry and Fishing'), value: 'i-ag' },
  { label: I18n.t('Arts, Entertainment and Recreation'), value: 'i-ar' },
  { label: I18n.t('Banking & Finance'), value: 'i-ban' },
  { label: I18n.t('Business Services'), value: 'i-bs' },
  { label: I18n.t('Architecture and Construction'), value: 'i-co' },
  { label: I18n.t('Education and Training'), value: 'i-ed' },
  { label: I18n.t('Utilities (Gas, Water, Electric, etc)'), value: 'i-el' },
  { label: I18n.t('Engineering'), value: 'i-en' },
  { label: I18n.t('Hospitality and Tourism'), value: 'i-ho' },
  { label: I18n.t('Human Resources'), value: 'i-hr' },
  { label: I18n.t('Health Sector'), value: 'i-hs' },
  { label: I18n.t('Information and Technology'), value: 'i-in' },
  { label: I18n.t('Insurance'), value: 'i-ins' },
  { label: I18n.t('Law Enforcement'), value: 'i-law' },
  { label: I18n.t('Manufacturing'), value: 'i-ma' },
  { label: I18n.t('Mining and Quarrying'), value: 'i-mi' },
  { label: I18n.t('Military & Defence'), value: 'i-mil' },
  { label: I18n.t('Oil and Gas/Energy Exploration'), value: 'i-oi' },
  { label: I18n.t('Other'), value: 'i-ot' },
  { label: I18n.t('Pharmaceutical Industry'), value: 'i-pha' },
  { label: I18n.t('Public Administration'), value: 'i-pu' },
  { label: I18n.t('Real Estate Activities'), value: 'i-re' },
  { label: I18n.t('Scientific and Technical Activities'), value: 'i-sc' },
  { label: I18n.t('Security'), value: 'i-se' },
  { label: I18n.t('Social Work'), value: 'i-sw' },
  { label: I18n.t('Telecommunications'), value: 'i-te' },
  { label: I18n.t('Transportation and Storage'), value: 'i-tr' },
  { label: I18n.t('Media'), value: 'i-tv' },
  { label: I18n.t('Wholesale and Retail Trade'), value: 'i-wh' },
];

export const languageOptions = [
  { label: I18n.t('English'), value: 'l-en' },
  { label: I18n.t('Afrikaans'), value: 'l-af' },
  { label: I18n.t('Arabic'), value: 'l-ar' },
  { label: I18n.t('Balinese'), value: 'l-ba' },
  { label: I18n.t('Bengali'), value: 'l-be' },
  { label: I18n.t('Brazilian Portuguese'), value: 'l-bpo' },
  { label: I18n.t('Catalan'), value: 'l-ca' },
  { label: I18n.t('Chinese-Simplified'), value: 'l-cs' },
  { label: I18n.t('Chinese-Traditional'), value: 'l-ct' },
  { label: I18n.t('Czech'), value: 'l-cz' },
  { label: I18n.t('Danish'), value: 'l-da' },
  { label: I18n.t('Dutch'), value: 'l-du' },
  { label: I18n.t('Estonian'), value: 'l-est' },
  { label: I18n.t('Finnish'), value: 'l-fn' },
  { label: I18n.t('French'), value: 'l-fr' },
  { label: I18n.t('German'), value: 'l-ge' },
  { label: I18n.t('Greek'), value: 'l-gr' },
  { label: I18n.t('Hebrew'), value: 'l-he' },
  { label: I18n.t('Hindi-Urdu'), value: 'l-hi' },
  { label: I18n.t('Hungarian'), value: 'l-hu' },
  { label: I18n.t('Icelandic'), value: 'l-ice' },
  { label: I18n.t('Indonesian'), value: 'l-in' },
  { label: I18n.t('isiNdebele'), value: 'l-is' },
  { label: I18n.t('Italian'), value: 'l-it' },
  { label: I18n.t('isiXhosa'), value: 'l-ix' },
  { label: I18n.t('isiZulu'), value: 'l-iz' },
  { label: I18n.t('Japanese'), value: 'l-jp' },
  { label: I18n.t('Korean'), value: 'l-ko' },
  { label: I18n.t('Latin American Spanish'), value: 'l-las' },
  { label: I18n.t('Lithuanian'), value: 'l-li' },
  { label: I18n.t('Malay'), value: 'l-ma' },
  { label: I18n.t('Malayalam'), value: 'l-ml' },
  { label: I18n.t('Maori'), value: 'l-mao' },
  { label: I18n.t('Norwegian'), value: 'l-no' },
  { label: I18n.t('Maltese'), value: 'l-mt' },
  { label: I18n.t('Other'), value: 'l-ot' },
  { label: I18n.t('Persian'), value: 'l-pe' },
  { label: I18n.t('Polish'), value: 'l-po' },
  { label: I18n.t('Portuguese'), value: 'l-pr' },
  { label: I18n.t('Punjabi'), value: 'l-pu' },
  { label: I18n.t('Russian'), value: 'l-ru' },
  { label: I18n.t('Swedish'), value: 'l-sd' },
  { label: I18n.t('Sepedi'), value: 'l-se' },
  { label: I18n.t('Serbian'), value: 'l-ser' },
  { label: I18n.t('Swahili'), value: 'l-sh' },
  { label: I18n.t('Sicilian'), value: 'l-si' },
  { label: I18n.t('Slovak'), value: 'l-sk' },
  { label: I18n.t('Southern Thai'), value: 'l-so' },
  { label: I18n.t('Spanish'), value: 'l-sp' },
  { label: I18n.t('Sesotho'), value: 'l-ss' },
  { label: I18n.t('Setswana'), value: 'l-st' },
  { label: I18n.t('siSwati'), value: 'l-sw' },
  { label: I18n.t('Tagalog (Filipino)'), value: 'l-ta' },
  { label: I18n.t('Thai'), value: 'l-th' },
  { label: I18n.t('Tamil'), value: 'l-tm' },
  { label: I18n.t('Turkmen'), value: 'l-tr' },
  { label: I18n.t('Tshivenda'), value: 'l-ts' },
  { label: I18n.t('Turkish'), value: 'l-tu' },
  { label: I18n.t('Ukrainian'), value: 'l-uk' },
  { label: I18n.t('Vietnamese'), value: 'l-vi' },
  { label: I18n.t('Welsh'), value: 'l-we' },
  { label: I18n.t('Xitsonga'), value: 'l-xi' },
  { label: I18n.t('Zulu'), value: 'l-zu' },
];

export const genderOptions = [
  {
    name: 'Male',
    value: 'M',
    label: I18n.t('Male'),
  },
  {
    name: 'Female',
    value: 'F',
    label: I18n.t('Female'),
  },
  {
    name: 'Not Specified',
    value: 'U',
    label: I18n.t('Not Specified'),
  },
];

export const languageOptionsByRegion = [
  {
    regionName: 'Europe',
    values: [
      { label: I18n.t('Catalan'), value: 'l-ca' },
      { label: I18n.t('Czech'), value: 'l-cz' },
      { label: I18n.t('Danish'), value: 'l-da' },
      { label: I18n.t('Dutch'), value: 'l-du' },
      { label: I18n.t('Estonian'), value: 'l-est' },
      { label: I18n.t('Finnish'), value: 'l-fn' },
      { label: I18n.t('French'), value: 'l-fr' },
      { label: I18n.t('German'), value: 'l-ge' },
      { label: I18n.t('Greek'), value: 'l-gr' },
      { label: I18n.t('Hungarian'), value: 'l-hu' },
      { label: I18n.t('Icelandic'), value: 'l-ice' },
      { label: I18n.t('Italian'), value: 'l-it' },
      { label: I18n.t('Lithuanian'), value: 'l-li' },
      { label: I18n.t('Maltese'), value: 'l-mt' },
      { label: I18n.t('Persian'), value: 'l-pe' },
      { label: I18n.t('Norwegian'), value: 'l-no' },
      { label: I18n.t('Polish'), value: 'l-po' },
      { label: I18n.t('Portuguese'), value: 'l-pr' },
      { label: I18n.t('Russian'), value: 'l-ru' },
      { label: I18n.t('Serbian'), value: 'l-ser' },
      { label: I18n.t('Slovak'), value: 'l-sk' },
      { label: I18n.t('Sicilian'), value: 'l-si' },
      { label: I18n.t('Spanish'), value: 'l-sp' },
      { label: I18n.t('Swedish'), value: 'l-sd' },
      { label: I18n.t('Ukrainian'), value: 'l-uk' },
      { label: I18n.t('Welsh'), value: 'l-we' },
    ],
  },
  {
    regionName: 'Asia',
    values: [
      { label: I18n.t('Arabic'), value: 'l-ar' },
      { label: I18n.t('Balinese'), value: 'l-ba' },
      { label: I18n.t('Bengali'), value: 'l-be' },
      { label: I18n.t('Chinese-Simplified'), value: 'l-cs' },
      { label: I18n.t('Chinese-Traditional'), value: 'l-ct' },
      { label: I18n.t('Hebrew'), value: 'l-he' },
      { label: I18n.t('Hindi-Urdu'), value: 'l-hi' },
      { label: I18n.t('Indonesian'), value: 'l-in' },
      { label: I18n.t('Japanese'), value: 'l-jp' },
      { label: I18n.t('Korean'), value: 'l-ko' },
      { label: I18n.t('Malay'), value: 'l-ma' },
      { label: I18n.t('Malayalam'), value: 'l-ml' },
      { label: I18n.t('Punjabi'), value: 'l-pu' },
      { label: I18n.t('Tamil'), value: 'l-tm' },
      { label: I18n.t('Thai'), value: 'l-th' },
      { label: I18n.t('Turkmen'), value: 'l-tr' },
      { label: I18n.t('Turkish'), value: 'l-tu' },
      { label: I18n.t('Vietnamese'), value: 'l-vi' },
      { label: I18n.t('Tagalog (Filipino)'), value: 'l-ta' },
    ],
  },
  {
    regionName: 'Africa',
    values: [
      { label: I18n.t('Afrikaans'), value: 'l-af' },
      { label: I18n.t('isiNdebele'), value: 'l-is' },
      { label: I18n.t('isiXhosa'), value: 'l-ix' },
      { label: I18n.t('isiZulu'), value: 'l-iz' },
      { label: I18n.t('Maori'), value: 'l-mao' },
      { label: I18n.t('Sepedi'), value: 'l-se' },
      { label: I18n.t('Sesotho'), value: 'l-ss' },
      { label: I18n.t('Setswana'), value: 'l-st' },
      { label: I18n.t('siSwati'), value: 'l-sw' },
      { label: I18n.t('Swahili'), value: 'l-sh' },
      { label: I18n.t('Tshivenda'), value: 'l-ts' },
      { label: I18n.t('Xitsonga'), value: 'l-xi' },
      { label: I18n.t('Zulu'), value: 'l-zu' },
    ],
  },
  {
    regionName: 'America',
    values: [
      { label: I18n.t('English'), value: 'l-en' },
      { label: I18n.t('Brazilian Portuguese'), value: 'l-bpo' },
      { label: I18n.t('Latin American Spanish'), value: 'l-las' },
      { label: I18n.t('Southern Thai'), value: 'l-so' },
    ],
  },
];
