import { createAction } from 'redux-actions';
import { I18n } from 'react-redux-i18n';
import apiInstance2 from '../../services/apiService';

import generateQueryString from '../generateQueryString';
import invitesColumns from '../../table/columns/invites';
import defaultTableState from '../../constants/defaultTableState';
import createToastNotification from '../../utils/createToastNotification';

export const setSelectedInvites = createAction('SET_SELECTED_INVITES', (invites) => ({ invites }));

export const setActiveInvitesTab = createAction('SET_ACTIVE_INVITES_TAB', (tab) => ({ tab }));

// ---------------- FILTERS -----------------
export const setInvitesSearchValue = createAction('SET_INVITES_SEARCH_VALUE', (searchValue) => ({
  searchValue,
}));

export const setInvitesFilterValue = createAction('SET_INVITES_FILTER_VALUE', (fieldName, value) => ({
  fieldName,
  value,
}));

export const setInvitesFilterType = createAction('SET_INVITES_FILTER_TYPE', (fieldName, filterType) => ({
  fieldName,
  filterType,
}));

export const deleteInvitesAppliedFilter = createAction('DELETE_INVITES_APPLIED_FILTER', (fieldName) => ({
  fieldName,
}));

export const applyInvitesFilters = createAction('APPLY_INVITES_FILTERS');
export const clearInvitesFilters = createAction('CLEAR_INVITES_FILTERS');

// ---------  NETWORK RELATED ----------

const fetchInvitesStart = createAction('FETCH_INVITES_START');
export const fetchInvitesSuccess = createAction('FETCH_INVITES_SUCCESS', (data, tableParams, shouldReset) => ({
  data,
  tableParams,
  shouldReset,
}));
export const fetchInvitesFailure = createAction('FETCH_INVITES_FAILURE');

export const createInviteStart = createAction('CREATE_INVITE_START');
export const createInviteSuccess = createAction('CREATE_INVITE_SUCCESS');
export const createInviteFailure = createAction('CREATE_INVITE_FAILURE');

export const sendInviteStart = createAction('SEND_INVITE_START');
export const sendInviteSuccess = createAction('SEND_INVITE_SUCCESS');
export const sendInviteFailure = createAction('FAILURE');

export const deleteInvitesStart = createAction('DELETE_INVITES_START');
export const deleteInvitesSuccess = createAction('DELETE_INVITES_SUCCESS', (deletedIds) => ({ deletedIds }));
export const deleteInvitesFailure = createAction('DELETE_INVITES_FAILURE');

export const fetchInvites = (tableState = defaultTableState, { callback, shouldReset }) => async (
  dispatch,
  getState,
) => {
  dispatch(fetchInvitesStart());
  const { tabs, activeTab } = getState().invites;
  const finalObj = { ...tableState };
  const columns = invitesColumns();

  if (typeof finalObj.sortBy !== 'undefined' && finalObj.sortBy[0]) {
    const sortingObj = finalObj.sortBy[0];
    const ColumnIndex = columns.findIndex((item) => item.Header === sortingObj.id || item.name === sortingObj.id);
    const Order = sortingObj.desc ? 1 : 0;
    finalObj.sortBy = { ColumnIndex, Order };
  }
  const queryString = generateQueryString(finalObj, columns);
  const activeType = tabs.findIndex((item) => item.name === activeTab.name);
  try {
    const response = await apiInstance2.get(
      `api/v2/Invites?withCounts=true&invitesRequestType=${activeType}&${queryString}`,
    );
    if (response.status === 200) {
      dispatch(fetchInvitesSuccess(response.data, tableState, shouldReset));
      if (callback) callback();
    }
  } catch (err) {
    dispatch(fetchInvitesFailure(err.message));
    if (callback) callback(err);
  }
};

export const deleteInvites = (data, cb) => async (dispatch) => {
  dispatch(deleteInvitesStart());
  try {
    const response = await apiInstance2.delete(`/api/v2/invites`, { data });
    if (response.status === 200) {
      dispatch(deleteInvitesSuccess(response.data.map((invite) => invite.inviteID)));
      createToastNotification({ message: I18n.t('invitesDeletionToast') });
      cb();
    }
  } catch (err) {
    dispatch(deleteInvitesFailure(err.message));
    cb(err);
  }
};

export const createInvite = (body, callback) => async (dispatch) => {
  dispatch(createInviteStart());
  try {
    const response = await apiInstance2.post(`/api/v2/invites/assessment`, body);
    if (response.status === 200) {
      dispatch(createInviteSuccess());
      callback(response.data);
    }
  } catch (err) {
    dispatch(createInviteFailure(null, err.message));
    callback(err);
  }
};

export const sendInvite = (body, callback) => async (dispatch) => {
  dispatch(sendInviteStart());
  try {
    const response = await apiInstance2.post(`/api/v2/invites`, body);
    if (response.status === 200) {
      dispatch(sendInviteSuccess());
      createToastNotification({ message: I18n.t('invitesSendedToast') });
      callback();
    }
  } catch (err) {
    dispatch(sendInviteFailure(err));
    callback(err);
  }
};

export const updateExpiryAssessmentInvites = (data, cb) => async (dispatch) => {
  try {
    const response = await apiInstance2.patch('/api/v2/invites/expiry-date', data);
    if (response.status === 200) {
      if (data.inviteType === 0) createToastNotification({ message: I18n.t('updateAssessmentInvitationToast') });
      if (data.inviteType === 2) createToastNotification({ message: I18n.t('updateProjectInvitationToast') });

      const response = await apiInstance2.get(
        `api/v2/Invites?withCounts=true&invitesRequestType=0&PageNumber=0&PageSize=40&`,
      );
      if (response.status === 200) {
        dispatch(fetchInvitesSuccess(response.data, defaultTableState, true));
      }

      if (cb) cb();
    }
  } catch (e) {
    cb('Something went wrong', e);
  }
};
