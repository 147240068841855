import {
  competencyCategoryRowActions,
  competencyItemRowActions,
  competencyQuestionRowActions,
} from '../../../table/singleRowActions/competency';

export const extractLocalizedData = (obj, locale) => {
  const localizedObj =
    obj?.languages?.length && obj.languages.find((item) => item.langID?.toLowerCase() === locale.toLowerCase());
  return localizedObj ? { name: localizedObj.name, description: localizedObj.description } : {};
};

const extractCompetencyItemLocalizedData = (obj, locale) => {
  if (!obj) return {};
  const localizedString = obj.languages ? obj.languages[locale.toUpperCase()] : obj.item;
  return { ...obj, item: localizedString };
};

function mapCustomCompetencies(data, singleRowHandlers, locale) {
  return data.map((item) => {
    const localizedCompetencyData = extractLocalizedData(item, locale);
    return {
      ...item,
      isActive: false,
      actions: competencyCategoryRowActions.map((action) => ({
        ...action,
        handler: singleRowHandlers[action.name],
      })),
      categories: item.categories.map((category) => ({
        ...category,
        isActive: false,
        actions: competencyItemRowActions.map((nestedAction) => ({
          ...nestedAction,
          handler: singleRowHandlers[nestedAction.name],
        })),
        competencyItems: (category.competencyItems || []).map((question) => ({
          ...question,
          actions: competencyQuestionRowActions.map((questionAction) => ({
            ...questionAction,
            handler: singleRowHandlers[questionAction.name],
          })),
          ...extractCompetencyItemLocalizedData(question, locale),
        })),
        ...extractLocalizedData(category, locale),
      })),
      ...localizedCompetencyData,
    };
  });
}

export default mapCustomCompetencies;
