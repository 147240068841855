import { string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { push } from 'redux-first-history';
import apiInstance2 from '../../../services/apiService';
import * as actions from '../../../table/actionsList';
import PageWrapper from '../../PageWrapper';
import ButtonGroup from '../../reusable/Buttons/ButtonGroup';
import Spinner from '../../reusable/Spinner';
import SimplifiedTable from '../../reusable/Tables/SimplifiedTable';
import TableTabs from '../../reusable/TableTabs';
import BioDataView from './BioDataView';
import HistogramCell from './HistogramCell';
import HistogramView from './HistogramView';
import NormsHeader from './NormsHeader';
import NotesView from './NotesView';
import SteTable from './SteTable';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  StatusBarModule,
  SetFilterModule,
  RowGroupingModule,
  MasterDetailModule,
]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

const agGridDefaultSettings = {
  pagination: false,
  rowHeight: '58',
  headerHeight: '58',
  domLayout: 'autoHeight',
  gridOptions: { popupParent: document.body },
};

const agGriddefaultColumnDefinition = {
  sortable: true,
};

const NormScales = ({ normId }) => {
  const dispatch = useDispatch();
  const onBackButtonClick = () => dispatch(push('/settings/norms'));
  const [norms, setNorms] = useState([]);
  const [normData, setNormData] = useState({});
  const [bioData, setBioData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [normDescription, setNormDescription] = useState('');

  const [shouldRenderBioData, setShouldRenderBioData] = useState(false);

  let applyRemappedAge = false;
  let remappedAge = '';

  let applyRemappedGender = false;
  let remappedGender = '';

  let applyRemappedEthnicity = false;
  let remappedEthnicity = '';

  let applyRemappedEducation = false;
  let remappedEducation = '';

  let applyRemappedOccupation = false;
  let remappedOccupation = '';

  let applyRemappedIndustry = false;
  let remappedIndustry = '';

  let applyRemappedFirstLanguage = false;
  let remappedFirstLanguage = '';

  let applyRemappedSector = false;
  let remappedSector = '';

  const categoriesValuesValidations = (age, sex, ethnicity, education, occupation, industry, firstLanguage, sector) => {
    return (
      age === '' &&
      sex === '' &&
      ethnicity === '' &&
      education === '' &&
      occupation === '' &&
      industry === '' &&
      firstLanguage === '' &&
      sector === ''
    );
  };

  /**
   * Validation of AGE CATEGORY with single format, defined on BOOK OF NORMS.
   * Single string with below format information: 'Aged < 20: 57 (0.6%); Aged 20 -29: 2153 (23.9%); Aged 30 - 39: 2729 (30.3%); Aged 40 - 49: 2506 (27.8%); Aged 50 - 59: 1380 (15.3%); Undeclared: 1 (0%); Aged >= 60: 181 (2%)'
   */
  const ageCategoryValidations = (age) => {
    if (age && age.split(';')[0].startsWith('Aged <')) {
      applyRemappedAge = true;
      const extractCountRegex = /:\s?(\d+(\.\d+)?)\s?\(/;
      const extractPercentageRegex = /\((\d+(\.\d+)?)%\)/;
      const ageValues = [];

      if (age.split(';').length !== 7) return;

      age.split(';').forEach((value) => {
        const countResult = value.match(extractCountRegex);
        const percentageResult = value.match(extractPercentageRegex);
        if (countResult && percentageResult) {
          return ageValues.push(`${countResult[1]}:${percentageResult[1]}`);
        }
      });

      const orderedAgeGroups = [
        ageValues[0],
        ageValues[1],
        ageValues[2],
        ageValues[3],
        ageValues[4],
        ageValues[6],
        ageValues[5],
      ];

      remappedAge = orderedAgeGroups;
    }

    const mappedAge = {
      name: 'age',
      values: applyRemappedAge ? remappedAge : '',
      graghType: 'barChart',
      FOLLOW_BOOK_OF_NORMS_FORMAT: true,
    };

    return [mappedAge];
  };

  /**
   * Validation of SEX CATEGORY with multiple formats, defined below:
   * 1. Single string with following format information: '12;12;12'
   * 2. Single string with following format information: 'M:12;F:12;U:12'
   * 3. Single string with following format information: 'M=12;F=12;U=12'
   * 4. Single string with following format information: 'Males: 5328 (59.2%); Females: 3178 (35.3%); Undeclared: 501 (5.5%)'
   */
  const genderCategoryValidations = (sex) => {
    let mappedGender = '';
    let FOLLOW_BOOK_OF_NORMS_FORMAT = false;
    if (sex) {
      // Format validations 1.
      if (sex.split(';')[0].startsWith('M:') || sex.split(';')[0].startsWith('M=')) {
        applyRemappedGender = true;
        const genderValues = [];
        sex.split(';').forEach((value) => {
          return value.includes(':') ? genderValues.push(value.split(':')[1]) : genderValues.push(value.split('=')[1]);
        });

        const maleGender = `M:${genderValues[0]}`;
        const femaleGender = `F:${genderValues[1]}`;
        const unspecifiedGender = `U:${genderValues[2]}`;

        remappedGender = `${maleGender};${femaleGender};${unspecifiedGender}`;
        FOLLOW_BOOK_OF_NORMS_FORMAT = false;
      }
      // Format validations 2. and 3.
      if (!sex.split(';')[0].startsWith('M:') && !sex.split(';')[0].startsWith('M=')) {
        applyRemappedGender = true;
        const genderValues = [];
        sex.split(';').forEach((value) => {
          return genderValues.push(value);
        });

        const maleGender = `M:${genderValues[0]}`;
        const femaleGender = `F:${genderValues[1]}`;
        const unspecifiedGender = `U:${genderValues[2]}`;

        remappedGender = `${maleGender};${femaleGender};${unspecifiedGender}`;
        FOLLOW_BOOK_OF_NORMS_FORMAT = false;
      }
      // Format validations 4.
      if (sex.split(';')[0].startsWith('Males')) {
        applyRemappedGender = true;
        const extractGroupNameRegex = /^([^:]+):/;
        const extractAgeRegex = /:\s?(\d+(\.\d+)?)\s?\(/;
        const extractPercentageRegex = /\((\d+(\.\d+)?)%\)/;
        const genderValues = [];

        if (sex.split(';').length !== 3) return;

        sex.split(';').forEach((value) => {
          const groupNameResult = value.match(extractGroupNameRegex);
          const countResult = value.match(extractAgeRegex);
          const percentageResult = value.match(extractPercentageRegex);
          if (countResult && percentageResult) {
            return genderValues.push(`${groupNameResult[1]}:${countResult[1]}:${percentageResult[1]}`);
          }
        });

        remappedGender = genderValues;
        FOLLOW_BOOK_OF_NORMS_FORMAT = true;
      }
      mappedGender = {
        name: 'gender',
        values: applyRemappedGender ? remappedGender : sex,
        graghType: 'pieChart',
        FOLLOW_BOOK_OF_NORMS_FORMAT,
      };
    }
    return [mappedGender];
  };

  /**
   * Validation of categories with single format, defined into respondentSelectOptions.js (file)
   * ethnicity => initial code 'e-'
   * education => initial code 'q-'
   * occupation => initial code 'o-'
   * industry => initial code 'i-'
   * firstLanguage => initial code 'l-'
   * sector => initial code 's-'
   */
  const categoryCodeValidations = (ethnicity, education, occupation, industry, firstLanguage, sector) => {
    /** ethnicity */
    if (ethnicity && !ethnicity.split(';')[0].startsWith('e-')) {
      applyRemappedEthnicity = true;
      remappedEthnicity = '';
    }
    const mappedethnicity = {
      name: 'ethnicity',
      values: applyRemappedEthnicity ? remappedEthnicity : ethnicity,
      graghType: 'pieChart',
      FOLLOW_BOOK_OF_NORMS_FORMAT: false,
    };
    /** education */
    if (education && !education.split(';')[0].startsWith('q-')) {
      applyRemappedEducation = true;
      remappedEducation = '';
    }
    const mappedEducation = {
      name: 'education',
      values: applyRemappedEducation ? remappedEducation : education,
      graghType: 'pieChart',
      FOLLOW_BOOK_OF_NORMS_FORMAT: false,
    };
    /** occupation */
    if (occupation && !occupation.split(';')[0].startsWith('o-')) {
      applyRemappedOccupation = true;
      remappedOccupation = '';
    }
    const mappedOccupation = {
      name: 'occupation',
      values: applyRemappedOccupation ? remappedOccupation : occupation,
      graghType: 'pieChart',
      FOLLOW_BOOK_OF_NORMS_FORMAT: false,
    };
    /** industry */
    if (industry && !industry.split(';')[0].startsWith('i-')) {
      applyRemappedIndustry = true;
      remappedIndustry = '';
    }
    const mappedIndustry = {
      name: 'industry',
      values: applyRemappedIndustry ? remappedIndustry : industry,
      graghType: 'pieChart',
      FOLLOW_BOOK_OF_NORMS_FORMAT: false,
    };
    /** firstLanguage */
    if (firstLanguage && !firstLanguage.split(';')[0].startsWith('l-')) {
      applyRemappedFirstLanguage = true;
      remappedFirstLanguage = '';
    }
    const mappedFirstLanguage = {
      name: 'first language',
      values: applyRemappedFirstLanguage ? remappedFirstLanguage : firstLanguage,
      graghType: 'pieChart',
      FOLLOW_BOOK_OF_NORMS_FORMAT: false,
    };
    /** sector */
    if (sector && !sector.split(';')[0].startsWith('s-')) {
      applyRemappedSector = true;
      remappedSector = '';
    }
    const mappedSector = {
      name: 'sector',
      values: applyRemappedSector ? remappedSector : sector,
      graghType: 'pieChart',
      FOLLOW_BOOK_OF_NORMS_FORMAT: false,
    };

    return [mappedethnicity, mappedFirstLanguage, mappedEducation, mappedIndustry, mappedOccupation, mappedSector];
  };

  useEffect(() => {
    apiInstance2
      .get(`/api/v2/norms/${normId}`)
      .then((response) => {
        const {
          description = '',
          type,
          creationDate,
          name,
          sampleSize,
          testID,
          scales,
          age = '',
          sex = '',
          ethnicity = '',
          education = '',
          occupation = '',
          industry = '',
          firstLanguage = '',
          sector = '',
        } = response.data;

        setNormData({ description, type, creationDate, name, sampleSize, testID });
        setNorms(scales.map((details) => ({ ...details, sampleSize })));
        setNormDescription(description);
        const isAllCategoriesEmpty = categoriesValuesValidations(
          age,
          sex,
          ethnicity,
          education,
          occupation,
          industry,
          firstLanguage,
          sector,
        );
        if (!isAllCategoriesEmpty) {
          const ageInfo = ageCategoryValidations(age);
          const genderInfo = genderCategoryValidations(sex);
          const bioInfo = categoryCodeValidations(ethnicity, education, occupation, industry, firstLanguage, sector);
          setBioData([...ageInfo, ...genderInfo, ...bioInfo]);
          setShouldRenderBioData(!isAllCategoriesEmpty);
        }

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });

    return () => {};
  }, [normId]);

  const columns = [
    { Header: 'Scale', name: 'scaleName', accessor: 'scaleName' },
    { Header: 'Mean', name: 'mean', accessor: 'mean' },
    { Header: 'SD', name: 'sd', accessor: 'sd' },
    { Header: 'Sample Size', name: 'sampleSize', accessor: 'sampleSize' },
    { Header: 'Histogram', name: 'histogram', accessor: 'histogram', Cell: HistogramCell },
  ];
  
  const SD_columnDefinition = [
    {
      field: 'scaleName',
      headerName: 'Scale',
      cellDataType: 'text',
      filter: 'agNumberColumnFilter',
      flex: 1,
    },
    {
      field: 'mean',
      headerName: 'Mean',
      cellDataType: 'number',
      filter: 'agNumberColumnFilter',
      flex: 1,
      editable: true,
    },
    {
      field: 'sd',
      headerName: 'SD',
      cellDataType: 'number',
      filter: 'agNumberColumnFilter',
      flex: 1,
      editable: true,
    },
    {
      field: 'sampleSize',
      headerName: 'Sample Size',
      cellDataType: 'number',
      filter: 'agNumberColumnFilter',
      flex: 1,
    },
  ];

  const hasHistogram = norms.find((scale) => scale.frequency);

  const tabs = [
    { name: 'Main Details', label: 'Main Details' },
    ...(normData.type === 'F' ? [{ name: 'Norm Table', label: 'Norm Table' }] : []),
    ...(normData.type === 'F' && hasHistogram ? [{ name: 'Frequency Histograms', label: 'Frequency Histograms' }] : []),
    ...(normData.type === 'F' && shouldRenderBioData ? [{ name: 'Bio Data', label: 'Bio Data' }] : []),
    ...(normDescription !== '' ? [{ name: 'Notes', label: 'Notes' }] : []),
  ];
  
  

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const onTabClick = (tab) => {
    setActiveTab(tab);
  };

  const groups = [
    {
      name: 'sten',
      title: I18n.t('Sten'),
    },
    {
      name: 'stanine',
      title: I18n.t('Stanine'),
    },
  ];

  const [activeGroup, setActiveGroup] = useState(groups[0]);
  const title = normData.name ? normData.name : I18n.t('Norms');
  
  const isIncomplete = norms.filter((norm) => norm.mean === 0 || norm.sd === 0).length > 0;

  const handleSDNormUpdate = () => {
    const mappedNorms = norms.map((norm) => {
      return {
        scaleName: norm.scaleName,
        sd: norm.sd,
        mean: norm.mean
      }
    });
    console.log('mappedNorms', mappedNorms, isIncomplete);
  };
  
  const templateScreenTableActions = (isEdit, handler, hasDataChanged) => [
    {
      name: actions.SAVE_SD_UPDATED_NORM,
      label: I18n.t('Save'),
      handler,
      isDisabled: isEdit && !hasDataChanged,
    },
  ];
  
  const isCustomSDNorms = normData.type === 'SD' && true;
  const customButtons = isCustomSDNorms ? templateScreenTableActions(true, handleSDNormUpdate, true) : [];
  
  return (
    <PageWrapper title={title} backButtonHandler={onBackButtonClick}
      buttons={customButtons}>
      
      <NormsHeader data={normData} />
      <TableTabs
        tabs={tabs}
        activeTab={activeTab}
        clickHandler={onTabClick}
        withChildren={activeTab.name === tabs[1]?.name}
      >
        {normData.type === 'F' && activeTab.name === tabs[1]?.name && (
          <ButtonGroup groups={groups} activeGroup={activeGroup} setActiveGroup={setActiveGroup} />
        )}
      </TableTabs>
      {normData.type === 'F' && activeTab.name === tabs[0].name && (
        <SimplifiedTable
          columns={columns}
          data={norms}
          onSelectChange={() => {}}
          idAccessor="normID"
          entityName="norm"
          disableSelect
          bottomOffset={170}
        />
      )}
      {normData.type === 'SD' && activeTab.name === tabs[0].name && (
        <div className="ag-theme-quartz ag-grid-custom-styles" style={{overflow: "scroll"}}>
          <AgGridReact
            {...agGridDefaultSettings}
            defaultColDef={agGriddefaultColumnDefinition}
            columnDefs={SD_columnDefinition}
            masterDetail={true}
            rowData={norms}
          />
        </div>
      )}
      {normData.type === 'F' && activeTab.name === tabs[1]?.name && <SteTable norms={norms} activeGroup={activeGroup} />}
      {normData.type === 'F' && activeTab.name === tabs[2]?.name && <HistogramView norms={norms} />}
      {normData.type === 'F' && activeTab.name === tabs[hasHistogram ? 3 : 2]?.name && (
        <BioDataView bioData={bioData} />
      )}
      {activeTab.name === tabs[hasHistogram ? 4 : 1]?.name && <NotesView description={normDescription} />}
      <Spinner isLoading={isLoading} full />
    </PageWrapper>
  );
};

NormScales.propTypes = {
  normId: string.isRequired,
};

export default NormScales;
