import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { LicenseManager } from '@ag-grid-enterprise/core';
import RatersTableWrapper from '../components/RatersTableWrapper';
import SimplifiedTable from '../../reusable/Tables/SimplifiedTable';
import Spinner from '../../reusable/Spinner';
import BulkActionsSidebar from '../../BulkActionsSidebar';
import apiInstance2 from '../../../services/apiService';
import * as actions from '../../../table/actionsList';
import ratersResultsColumns from '../../../table/columns/ratersResults';
import ratersResultsBulkActions from '../../../table/bulkActions/ratersResults';
import useBulkSidebarState from '../../../hooks/useBulkSidebarState';
import useModals from '../../../hooks/useModals';
import ConfirmationModal from '../../reusable/ConfirmationModal';
import TableEmptyState from '../../reusable/TableEmptyState';
import raterRoles from '../../../constants/raterRoles';

ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, SetFilterModule]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

const RatersResults = ({ projectId, sessionId }) => {
  const [results, setResults] = useState([]);
  console.log(results);
  const [isLoading, setLoading] = useState(true);
  const [selectedResults, setSelectedResults] = useState([]);

  useEffect(() => {
    setLoading(true);
    apiInstance2
      .get(`/api/v2/360/projects/${projectId}/sessions/costs?sessionIds=${sessionId}`)
      .then((response) => {
        setLoading(false);
        const results = [
          ...[response.data[0].self].filter((item) => item.completedDate).map((item) => ({ ...item, role: 0 })),
          ...response.data[0].raters.filter((item) => item.completedDate),
        ];
        setResults(results);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const modals = [actions.DELETE_COMPLETED_TEST, actions.REQUEST_REPORT];
  const { modalsState, openModal, closeModal } = useModals(modals);

  // const onDeleteConfirm = () => {
  //   dispatch(
  //     deleteRespondentResults(
  //       selectedResults.map((item) => item.resultID),
  //       null,
  //       () => {
  //         closeModal(actions.DELETE_COMPLETED_TEST);
  //       },
  //     ),
  //   );
  // };

  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedResults });

  // BULK ACTIONS
  const bulkActionsHandlers = {
    [actions.REQUEST_REPORT]: () => openModal(actions.REQUEST_REPORT),
    [actions.DELETE_COMPLETED_TEST]: () => openModal(actions.DELETE_COMPLETED_TEST),
  };
  const bulkActions = useMemo(() => {
    return ratersResultsBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedResults) : item.isDisabled,
    }));
  }, [selectedResults]);

  const agGridDefaultSettings = {
    pagination: true,
    paginationPageSize: 50,
    paginationPageSizeSelector: [10, 20, 50],
    rowHeight: '58',
    headerHeight: '58',
    domLayout: 'autoHeight',
    gridOptions: { popupParent: document.body },
    onSelectionChanged: (event) => {
      const selectedData = [];
      event.api.forEachNodeAfterFilter((node) => {
        if (node.isSelected()) {
          selectedData.push(node.data);
        }
      });
      setSelectedResults(selectedData);
    },
  };

  const agGriddefaultColumnDefinition = {
    sortable: true,
  };

  const [columnDefinition] = useState([
    {
      headerName: I18n.t('Name'),
      celldatatype: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
      valueGetter: ({ data }) => `${data.firstName} ${data.familyName}`,
    },
    {
      field: 'email',
      headerName: I18n.t('Email Address'),
      celldatatype: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
    },
    {
      field: 'role',
      headerName: I18n.t('Role'),
      celldatatype: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
      valueGetter: ({ data }) => {
        const role = raterRoles().find((item) => item.backendValueReference === data.role);
        return role ? role.label : '';
      },
    },
    {
      field: 'completedDate',
      headerName: I18n.t('Completed Date'),
      cellDataType: 'date',
      flex: 1,
      filter: 'agDateColumnFilter',
      sort: 'desc',
      valueGetter: (params) => {
        return new Date(params.data.completedDate);
      },
    },
  ]);

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
    };
  }, []);

  const handleFilterChanged = (params) => {
    params.api.deselectAll();
    params.api.refreshHeader();
  };

  return (
    <RatersTableWrapper projectId={projectId} sessionId={sessionId} activeTab={2}>
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      <div className="ag-theme-quartz ag-grid-custom-styles">
        <AgGridReact
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          rowData={isLoading ? null : results}
          rowSelection={rowSelection}
          onFilterChanged={handleFilterChanged}
        />
      </div>
      <ConfirmationModal
        title="Result deletion"
        onClose={() => closeModal(actions.DELETE_COMPLETED_TEST)}
        isVisible={modalsState[actions.DELETE_COMPLETED_TEST]}
        description="Are you sure you want to delete selected result?"
        onConfirm={() => closeModal(actions.DELETE_COMPLETED_TEST)}
        caution
      />
    </RatersTableWrapper>
  );
};

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 3.2rem - 56px);
`;

const CustomEmptyState = styled(TableEmptyState)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export default RatersResults;
