import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import styled from 'styled-components';
import * as Yup from 'yup';
import { errorMessages } from '../../constants/errorMessages';
import { addNewNorm, fetchBriefGroups, fetchGroupResults } from '../../store/norms/actions';
import { getTestsWithSystemNorms } from '../../store/settings/norms/actions';
import { resolveAssessmentName } from '../../utils/assessmentsNames';
import Button from '../reusable/Buttons/Button';
import FormikInput from '../reusable/FormComponents/FormikInput';
import RadioButton from '../reusable/FormComponents/RadioButton';
import TextArea from '../reusable/FormComponents/TextArea';
import Modal from '../reusable/NewModal';
import Select from '../reusable/Selects/Select';

import countriesMap from '../../constants/countries';
import languagesMap from '../../constants/languages';
import { getItemsArray } from '../../utils/objectToArrayItems';

const AddNormModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const testsWithSystemNorms = useSelector((state) => state.settingsNorms.testsWithSystemNorms);
  const groups = useSelector((state) => state.norms.groups);
  const isCreateNormInProgress = useSelector((state) => state.norms.isCreateNormInProgress);

  const [submitIsDisabled, setSubmitIsDisabled] = useState(true);
  const [formError, setFormError] = useState('');
  const [fetchingGroupResults, setFetchingGroupResults] = useState(false);

  const availableTests = testsWithSystemNorms.map((assessment) => ({
    label: resolveAssessmentName(assessment),
    value: assessment.testID,
  }));
  
  const countryOptions = getItemsArray(countriesMap);
  const languageOptions = getItemsArray(languagesMap);

  const { values, handleChange, errors, touched, setFieldValue, handleSubmit, setFieldError } = useFormik({
    initialValues: {
      name: '',
      sampleSize: '',
      countryOrigin: '',
      language: '',
      test: '',
      group: '',
      description: '',
      normType: 'F',
    },
    validateOnChange: false,
    onSubmit: ({ description, name, normType, sampleSize, test, group, countryOrigin, language }) => {
      dispatch(
        addNewNorm(
          {
            description,
            name,
            normType,
            testId: test.value,
            ...(sampleSize && { sampleSize }),
            ...(group && { groupIds: [group.value] }),
            ...(countryOrigin && {countryOrigin: countryOrigin.value}),
            ...(language && { language: language.value} ),
          },
          (err) => {
            if (typeof err !== 'undefined' && err.errorMessage) {
              setFieldError('group', err.errorMessage);
            }
          },
        ),
      );
      onClose();
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .trim()
        .required(errorMessages.inputField),
      description: Yup.string().required(errorMessages.inputField),
      test: Yup.string().required(errorMessages.inputField),
      normType: Yup.string().required(),
      group: Yup.string().when('normType', {
        is: 'F',
        then: Yup.string().required(errorMessages.inputField),
      }),
      sampleSize: Yup.number().when('normType', {
        is: 'SD',
        then: Yup.number()
          .positive(I18n.t('Sample size must be greater than or equal to 1'))
          .required(errorMessages.inputField),
      }),
      countryOrigin: Yup.string().when('normType', {
        is: 'SD',
        then: Yup.string().required(errorMessages.inputField),
      }),
      language: Yup.string().when('normType', {
        is: 'SD',
        then: Yup.string().required(errorMessages.inputField),
      }),
    }),
  });

  useEffect(() => {
    dispatch(getTestsWithSystemNorms());
    dispatch(fetchBriefGroups());
  }, []);

  useEffect(() => {
    if (values.normType === 'F') {
      setFieldValue('sampleSize', '');
    }

    if (values.normType === 'SD') {
      setFieldValue('group', '');
    }
  }, [values.normType]);

  const changeType = (event) => {
    setFieldValue('normType', event.target.value);
  };

  useEffect(() => {
    if (values.normType === 'SD') {
      setSubmitIsDisabled(false);
      return;
    }
    setSubmitIsDisabled(true);
    setFormError('');
    if (values.test?.value && values.group?.value) {
      setFetchingGroupResults(true);
      dispatch(
        fetchGroupResults({ groupId: values.group?.value, testId: values.test?.value }, (status, data) => {
          if (status) {
            if (typeof data === 'number' && data > 1) {
              setSubmitIsDisabled(false);
            } else {
              setFormError(I18n.t('Not enough results in group'));
            }
          } else {
            setFormError(data);
          }
          setFetchingGroupResults(false);
        }),
      );
    }
  }, [values.test?.value, values.group?.value, values.normType]);

  return (
    <Modal title={I18n.t('Add New Norm')} lg isVisible onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <FieldWrapper>
          <CustomInput
            inputName={I18n.t('Norm name')}
            errors={errors}
            touched={touched}
            type="text"
            name="name"
            id="name"
            onChange={handleChange}
            value={values.name}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Select
            name="test"
            error={errors.test}
            placeholder={I18n.t('Assessment on which the norm is based')}
            options={availableTests}
            selectProps={{
              onChange: (value) => {
                setFieldValue('test', value);
              },
              value: values.test,
            }}
            touched={touched.test}
          />
        </FieldWrapper>
        <FieldWrapper>
          <GroupLabel>{I18n.t('Type')}</GroupLabel>
          <RadioButtonGroup>
            <StyledLabel>
              <RadioButton isChecked={values.normType === 'F'} value="F" onClick={changeType} readOnly />
              <RadioLabel>{I18n.t('Frequency')}</RadioLabel>
            </StyledLabel>
            <StyledLabel>
              <RadioButton isChecked={values.normType === 'SD'} value="SD" onClick={changeType} readOnly />
              <RadioLabel>{I18n.t('SD')}</RadioLabel>
            </StyledLabel>
          </RadioButtonGroup>
        </FieldWrapper>
        {values.normType === 'SD' && (
          <FieldWrapper>
            <NumberInput
              inputName={I18n.t('Sample Size')}
              errors={errors}
              type="number"
              name="sampleSize"
              id="sampleSize"
              onChange={handleChange}
              value={values.sampleSize}
              touched={touched}
            />
          </FieldWrapper>
        )}
          <FieldWrapper>
            <Select
              name="countryOrigin"
              error={errors.countryOrigin}
              placeholder={I18n.t('Country of Origin')}
              options={countryOptions}
              selectProps={{
                onChange: (value) => {
                  setFieldValue('countryOrigin', value);
                },
                value: values.countryOrigin,
              }}
              touched={touched.test}
            />
          </FieldWrapper>
        {values.normType === 'SD' && (
          <FieldWrapper>
            <Select
              name="language"
              error={errors.language}
              placeholder={I18n.t('Main Language')}
              options={languageOptions}
              selectProps={{
                onChange: (value) => {
                  setFieldValue('language', value);
                },
                value: values.language,
              }}
              touched={touched.test}
            />
          </FieldWrapper>
        )}
        {values.normType === 'F' && (
          <FieldWrapper>
            <Select
              name="group"
              error={errors.group}
              placeholder={I18n.t('Select group')}
              options={groups}
              selectProps={{
                onChange: (value) => {
                  setFieldValue('group', value);
                },
                value: values.group,
              }}
              touched={touched.group}
            />
          </FieldWrapper>
        )}
        <FieldWrapper>
          <TextArea
            inputName={I18n.t('Description')}
            errors={errors}
            type="text"
            name="description"
            id="description"
            onChange={handleChange}
            value={values.description}
            touched={touched}
          />
        </FieldWrapper>
        {formError ? (
          <ErrorWrapper>
            {typeof formError === 'string' ? formError : formError?.message || JSON.stringify(formError)}
          </ErrorWrapper>
        ) : (
          ''
        )}
        <ModalFooter>
          <Button loading={fetchingGroupResults} type="submit" disabled={submitIsDisabled || isCreateNormInProgress}>
            {I18n.t('Add')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

const FieldWrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

const ErrorWrapper = styled.div`
  color: red;
  text-align: center;
  font-size: 12px;
  margin-top: -20px;
`;

const StyledLabel = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const RadioButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const RadioLabel = styled.span`
  padding: 0 12px;
`;

const CustomInput = styled(FormikInput)`
  width: 100%;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const GroupLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #295368;
  margin-bottom: 16px;
`;

const NumberInput = styled(FormikInput)`
  & input {
    appearance: none;
  }
`;

export default AddNormModal;
