/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { string } from 'prop-types';
import { push } from 'redux-first-history';

import { I18n } from 'react-redux-i18n';
import PageWrapper from '../../PageWrapper';
import CustomInput from '../../reusable/FormComponents/Input';
import CustomSelect from '../../reusable/Selects/Select';
import Spinner from '../../reusable/Spinner';
import EditorSummernote from '../../reusable/TextEditor/EditorSummernote';
import * as actions from '../../../table/actionsList';
import NewModal from '../../reusable/NewModal';
import CustomButton from '../../reusable/Buttons/Button';
import { selectAvailableLanguages } from '../../../store/user/selectors';

import {
  selectTemplateBodyVariables,
  selectTemplateSubjectVariables,
} from '../../../store/settings/templates/selectors';

import {
  fetchTemplatesVariables,
  fetchTemplateById,
  setActiveTemplate,
  createTemplate,
  editTemplate,
  fetchDefaultEmailInvitaionTemplate,
} from '../../../store/settings/templates/actions';

const templateScreenTableActions = (isEdit, handler, hasDataChanged) => [
  {
    name: actions.CREATE_TEMPLATE,
    label: I18n.t('Save'),
    handler,
    isDisabled: isEdit && !hasDataChanged,
  },
];

const TemplateScreen = ({ templateId }) => {
  const languages = useSelector(selectAvailableLanguages);
  const dispatch = useDispatch();
  const defaultEmailInvitaionTemplate = useSelector((state) => state.templates.defaultEmailInvitaionTemplate);
  const activeTemplate = useSelector((state) => state.templates.activeTemplate);
  const bodyVariables = useSelector(selectTemplateBodyVariables);
  const subjectVariables = useSelector(selectTemplateSubjectVariables);
  const [isLoading, setLoadingStatus] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [templateSubject, setTemplateSubject] = useState('');
  const [templateBody, setTemplateBody] = useState('');
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [unsavedChangesModalIsVisible, setUnsavedChangesModalIsVisible] = useState(false);
  const [initialTemplateLangugeChangeModalIsVisible, setInitialTemplateLangugeChangeModalIsVisible] = useState(false);
  const user = useSelector((state) => state.user.user);

  const [errors, setErrors] = useState({
    templateName: '',
    templateSubject: '',
    templateBody: '',
  });

  const goBackToTemplates = () => dispatch(push('/settings/templates'));

  const initialTemplateLanguges = languages.filter((lang) => !lang.value.match(/AF|BG|CHHK|CZ|FL|HE|JP|MK/));

  const [initialTemplateLangugeValue, setInitialTemplateLangugeValue] = useState(
    initialTemplateLanguges.find((lang) => lang.value === 'EN'),
  );

  useEffect(() => {
    setInitialTemplateLangugeValue(initialTemplateLanguges.find((lang) => lang.value === 'EN'));
  }, [languages.length]);

  const [refetchTemplate, setRefetchTemplate] = useState(0);

  let initialValue =
    (activeTemplate && activeTemplate.emailBody) || activeTemplate?.htmlTemplate || defaultEmailInvitaionTemplate;
  const initialFullPageHtmlTemplate = initialValue;

  let editorSettings = {};
  let fullPageTemplate = false;

  if (initialValue && initialValue.includes('<!-- EDITABLE_AREA_STARTS -->')) {
    fullPageTemplate = true;
    const editableArea = initialValue.match(
      /<!-- EDITABLE_AREA_STARTS -->((.|\n|\r)*)<!-- EDITABLE_AREA_ENDS -->/,
    )?.[1];
    const prepend = initialValue.match(/<!-- PREPEND_STARTS -->((.|\n|\r)*)<!-- PREPEND_ENDS -->/)?.[1];
    const append = initialValue.match(/<!-- APPEND_STARTS -->((.|\n|\r)*)<!-- APPEND_ENDS -->/)?.[1];
    const editorStyles = initialValue.match(
      /<!-- EDITOR_STYLES_STARTS -->((.|\n|\r)*)<!-- EDITOR_STYLES_ENDS -->/,
    )?.[1];
    initialValue = editableArea;

    editorSettings = {
      ...editorSettings,
      ...(prepend ? { prepend } : {}),
      ...(append ? { append } : {}),
      ...(editorStyles ? { editorStyles } : {}),
    };
  }

  useEffect(() => {
    dispatch(fetchTemplatesVariables());
    dispatch(
      fetchDefaultEmailInvitaionTemplate({
        languageId: initialTemplateLangugeValue?.value || 'EN',
        distributorId: user?.distributorOwnerID,
        accountId: user?.accountID,
      }),
    );
    if (templateId) {
      setLoadingStatus(true);
      dispatch(fetchTemplateById(templateId, () => setLoadingStatus(false)));
    }
    return () => dispatch(setActiveTemplate(null));
  }, [user, refetchTemplate]);

  useEffect(() => {
    if (activeTemplate) {
      setTemplateName(activeTemplate.name);
      setTemplateSubject(activeTemplate.emailSubject);
      setTemplateBody(initialValue);
    }
  }, [activeTemplate, initialValue]);

  const initFieldsTouched =
    templateName || templateSubject || (fullPageTemplate ? initialValue !== templateBody : templateBody);

  const onSelectSubjectVariable = (data) => {
    setTemplateSubject((prev) => `${prev}${data.value}`);
    setErrors((prev) => ({ ...prev, templateSubject: '' }));
  };

  const onNameChange = (value) => {
    setTemplateName(value);
    setErrors((prev) => ({ ...prev, templateName: '' }));
  };

  const onSubjectChange = (value) => {
    setTemplateSubject(value);
    setErrors((prev) => ({ ...prev, templateSubject: '' }));
  };

  const onTemplateBodyChange = (value) => {
    setTemplateBody(value);
    setErrors((prev) => ({ ...prev, templateBody: '' }));
  };

  // eslint-disable-next-line no-nested-ternary
  const hasDataChanged = !activeTemplate
    ? false
    : activeTemplate.name !== templateName ||
      activeTemplate.emailSubject !== templateSubject ||
      activeTemplate?.htmlTemplate
    ? activeTemplate?.htmlTemplate?.match(
        /<!-- EDITABLE_AREA_STARTS -->((.|\n|\r)*)<!-- EDITABLE_AREA_ENDS -->/,
      )?.[1] !== templateBody
    : activeTemplate.emailBody !== templateBody;

  const handleCloseModal = () => {
    setModalIsVisible(false);
  };

  const handleUnsavedChangesCloseModal = () => {
    setUnsavedChangesModalIsVisible(false);
  };

  const handleConfirm = () => {
    setModalIsVisible(false);
    onSaveClick(undefined, 'bypass');
  };

  const handleConirmChangesNotSaved = () => {
    goBackToTemplates();
  };

  const handleCloseModalChangesNotSaved = () => {
    setUnsavedChangesModalIsVisible(false);
  };

  const isDataValid = () => {
    const list = [
      { name: 'templateName', value: templateName },
      { name: 'templateSubject', value: templateSubject },
      { name: 'templateBody', value: templateBody },
    ];
    let isInvalid;
    const errors = {};
    list.forEach((item) => {
      if (!item.value) {
        isInvalid = true;
        errors[item.name] = I18n.t('Field is required');
      }
    });
    setErrors(errors);
    return !isInvalid;
  };

  // eslint-disable-next-line no-shadow-restricted-names
  const onSaveClick = (undefined, bypass) => {
    const isValid = isDataValid();
    if (!isValid) return;

    const payloadBody = fullPageTemplate
      ? initialFullPageHtmlTemplate.replace(
          initialFullPageHtmlTemplate.match(
            /<!-- EDITABLE_AREA_STARTS -->((.|\n|\r)*)<!-- EDITABLE_AREA_ENDS -->/,
          )?.[1],
          templateBody,
        )
      : templateBody;

    const data = {
      name: templateName,
      emailSubject: templateSubject,
      ...(fullPageTemplate ? { htmlTemplate: payloadBody } : { emailBody: payloadBody }),
      ...(templateId && { templateID: Number(templateId) }),
    };
    const method = templateId ? editTemplate : createTemplate;

    if (fullPageTemplate && !bypass && !templateBody.includes('%FULLURL%')) {
      setModalIsVisible(true);
      return;
    }

    dispatch(
      method(data, () => {
        if (!templateId) goBackToTemplates();
      }),
    );
  };

  const handleBackButton = () => {
    if ((!templateId && initFieldsTouched) || (Boolean(templateId) && hasDataChanged)) {
      setUnsavedChangesModalIsVisible(true);
    } else {
      goBackToTemplates();
    }
  };

  const selectdLanugageRef = useRef(initialTemplateLangugeValue);

  const changeLanguage = (data) => {
    setInitialTemplateLangugeValue(data);
    setRefetchTemplate((prev) => prev + 1);
  };

  const handleInitialTemplateLangugeChange = (data) => {
    if (!templateId && initFieldsTouched) {
      selectdLanugageRef.current = data;
      setInitialTemplateLangugeChangeModalIsVisible(true);
    } else {
      changeLanguage(data);
    }
  };

  const handleCloseInitialTemplateLangugeChangeModal = () => {
    setInitialTemplateLangugeChangeModalIsVisible(false);
  };

  const handleYesInitialTemplateLangugeChangeModal = () => {
    changeLanguage(selectdLanugageRef.current);
    setInitialTemplateLangugeChangeModalIsVisible(false);
  };

  const handleNoInitialTemplateLangugeChangeModal = () => {
    setInitialTemplateLangugeChangeModalIsVisible(false);
  };

  return (
    <PageWrapper
      title={templateId ? I18n.t('Edit template') : I18n.t('Add new template')}
      backButtonHandler={handleBackButton}
      buttons={templateScreenTableActions(Boolean(templateId), onSaveClick, hasDataChanged)}
    >
      <Spinner isLoading={isLoading} full />
      <Container>
        <Input
          inputName={I18n.t('Template name')}
          value={templateName}
          onChange={(e) => onNameChange(e.target.value)}
          error={errors.templateName}
        />

        <SubjectRow>
          <Input
            inputName={I18n.t('Template subject')}
            value={templateSubject}
            onChange={(e) => onSubjectChange(e.target.value)}
            error={errors.templateSubject}
          />
          <Select
            options={subjectVariables}
            placeholder={I18n.t('Insert variable')}
            selectProps={{ value: null, onChange: onSelectSubjectVariable }}
            withoutDropdownIndicator
          />
        </SubjectRow>
        {!templateId && (
          <div>
            <StyledSelect
              options={initialTemplateLanguges}
              placeholder={I18n.t('Select language')}
              selectProps={{ value: initialTemplateLangugeValue, onChange: handleInitialTemplateLangugeChange }}
            />
          </div>
        )}
        <div>
          {!!bodyVariables.length && (
            <EditorSummernote
              initialValue={initialValue}
              variables={bodyVariables}
              onChange={onTemplateBodyChange}
              error={errors.templateBody}
              settings={editorSettings}
            />
          )}
        </div>
        <NewModal
          isVisible={modalIsVisible}
          onClose={handleCloseModal}
          title={I18n.t('Email invitation template does not contain invitation link')}
        >
          <ButtonWrapper spaceBetween>
            <Button handler={handleCloseModal} variant="secondary">
              {I18n.t('Cancel')}
            </Button>
            <Button handler={handleConfirm}>{I18n.t('Ok, got it')}</Button>
          </ButtonWrapper>
        </NewModal>
        <NewModal
          isVisible={unsavedChangesModalIsVisible}
          onClose={handleUnsavedChangesCloseModal}
          title={I18n.t('popupEmailTemplateChangesNotSaved')}
        >
          <ButtonWrapper spaceBetween>
            <Button handler={handleCloseModalChangesNotSaved} variant="secondary">
              {I18n.t('No')}
            </Button>
            <Button handler={handleConirmChangesNotSaved}>{I18n.t('Yes')}</Button>
          </ButtonWrapper>
        </NewModal>
        <NewModal
          isVisible={initialTemplateLangugeChangeModalIsVisible}
          onClose={handleCloseInitialTemplateLangugeChangeModal}
          title={I18n.t('popupEmailTemplateLanguageChange')}
        >
          <ButtonWrapper spaceBetween>
            <Button handler={handleNoInitialTemplateLangugeChangeModal} variant="secondary">
              {I18n.t('No')}
            </Button>
            <Button handler={handleYesInitialTemplateLangugeChangeModal}>{I18n.t('Yes')}</Button>
          </ButtonWrapper>
        </NewModal>
      </Container>
    </PageWrapper>
  );
};

TemplateScreen.propTypes = {
  templateId: string,
};

TemplateScreen.defaultProps = {
  templateId: '',
};

const Container = styled.div`
  padding: 3rem 0 0 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
`;

const SubjectRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  margin-top: 3rem;
`;

const Input = styled(CustomInput)`
  width: 70rem;
`;

const Select = styled(CustomSelect)`
  margin: 0 0 0 1rem;
  width: 13rem;
  min-width: 13rem;
  z-index: 20;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: ${(props) => (props.spaceBetween ? 'space-between' : 'flex-end')};
`;

const Button = styled(CustomButton)`
  width: ${(props) => (props.wide ? '16rem' : '14rem')};
  text-transform: uppercase;

  ${(props) =>
    props.isLinkCopied &&
    css`
      border-color: ${(props) => props.theme.colors.green};
      color: ${(props) => props.theme.colors.green};
      :hover {
        border-color: ${(props) => props.theme.colors.green};
        color: ${(props) => props.theme.colors.green};
      }
    `}
`;

const StyledSelect = styled(CustomSelect)`
  width: 50rem;
  margin-bottom: 2rem;
`;
export default TemplateScreen;
