import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { array, object, func, string, oneOfType, bool, number, node } from 'prop-types';
import { useTable, useSortBy, useRowSelect, useAsyncDebounce, useFilters, useGlobalFilter } from 'react-table';
import styled, { css } from 'styled-components';
import { I18n } from 'react-redux-i18n';
import { ReactComponent as ArrowUp } from '../../../assets/icons/blue-arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/blue-arrow-down.svg';
import { ReactComponent as EmptyIcon } from '../../../assets/icons/empty_state.svg';

import TableRow from '../MultiSelectTableRow';
import MultiSelectCheckbox from '../MultiSelectCheckbox';
import Spinner from '../Spinner';
import InfiniteTableScroll from '../../InfiniteScroll';

const Table = ({
  columns,
  data,
  initialState,
  highlightRowOptions,
  onSelectChange,
  className,
  onRowClick,
  idAccessor,
  rowEqualityFunc,
  scrollContainerHeight,
  // needed specifically for Groups table to prevent navigation to another screen instead of checking/unchecking selection checkbox
  interceptCheckboxClick,
  shouldResetPage,
  setShouldReset,
  entityName,
  isLoading,
  onFetchData,
  totalCount,
  searchValue,
  appliedFilters,
  lastPageIndex,
  disableSelect,
  customEmptyMessage,
  debounceTimeInMs,
  showSpinnerDuringInfiniteLoading,
  manualSortBy,
  withFilters,
  withGlobalFilter,
  localData,
}) => {
  const memoizedColumns = useMemo(() => columns, [columns]);
  const skipPageResetRef = useRef(null);
  const [isFetchingAdditionData, setFetchingAdditionalDataStatus] = useState(false);

  useEffect(() => {
    // After the table has updated, always remove the flag
    if (isFetchingAdditionData) return;
    skipPageResetRef.current = false;
  });

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds, sortBy },
    setAllFilters,
    setGlobalFilter,
  } = useTable(
    {
      columns: memoizedColumns,
      data,
      initialState: {
        pageSize: 20,
        ...initialState,
        hiddenColumns: columns
          .filter((column) => !column?.isVisible && typeof column?.isVisible === 'boolean')
          .map((column) => column.accessor),
      },
      manualSortBy,
      disableMultiSort: true,
      disableSortRemove: true,
      autoResetSelectedRows: !skipPageResetRef.current,
      autoResetSortBy: !skipPageResetRef.current,
      autoResetFilters: !skipPageResetRef.current,
      autoResetRowState: !skipPageResetRef.current,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      if (!disableSelect)
        hooks.allColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <CheckboxHolder>
                <MultiSelectCheckbox {...getToggleAllRowsSelectedProps()} enableClickHandler />
              </CheckboxHolder>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => {
              const toggleProps = row.getToggleRowSelectedProps();
              return (
                <CheckboxHolder
                  onClick={(e) => {
                    if (interceptCheckboxClick) e.stopPropagation();
                    toggleProps.onChange(e);
                  }}
                >
                  <MultiSelectCheckbox {...toggleProps} />
                </CheckboxHolder>
              );
            },
          },
          ...columns,
        ]);
    },
  );

  const goToTop = () => {
    const scrollComponent = document.querySelector('.infinite-scroll-component');
    scrollComponent.scrollTop = 0;
  };

  // Debounce our onFetchData call for 300ms
  const onFetchDataDebounced = useAsyncDebounce(onFetchData, debounceTimeInMs);
  const fetchData = useCallback(
    ({ pageNumber = 0, shouldReset = false, showSpinner = true }) => {
      skipPageResetRef.current = true;
      setFetchingAdditionalDataStatus(true);
      onFetchDataDebounced({
        tableData: {
          pagination: { PageNumber: pageNumber, PageSize: initialState?.pageSize || 40 },
          sortBy,
          search: searchValue,
          appliedFilters,
        },
        shouldReset,
        showSpinner,
        tableCallback: () => setFetchingAdditionalDataStatus(false),
      });
    },
    [sortBy, searchValue, appliedFilters],
  );

  useEffect(() => {
    fetchData({ pageNumber: 0, shouldReset: true });
    goToTop();
  }, [onFetchDataDebounced, sortBy, searchValue, appliedFilters]);

  useEffect(() => {
    if (onSelectChange) onSelectChange(selectedFlatRows.map((item) => ({ ...item.original })));
  }, [selectedFlatRows.length]);

  useEffect(() => {
    if (shouldResetPage) {
      fetchData({ pageNumber: 0, shouldReset: true });
      goToTop();
      setShouldReset(false);
    }
  }, [shouldResetPage]);

  const rowsLength = rows.length;
  const fetchDataInfinite = () =>
    fetchData({ pageNumber: lastPageIndex + 1, showSpinner: showSpinnerDuringInfiniteLoading });

  const NoContent = customEmptyMessage || (
    <EmptyMessageWrapper>
      <EmptyIcon />
      <EmptyMessage>{I18n.t(`Sorry, we could not find any ${entityName}s`)}</EmptyMessage>
    </EmptyMessageWrapper>
  );

  const setGlobalFileterWithDebounce = useAsyncDebounce((val) => {
    setGlobalFilter(val);
  }, 500);

  useEffect(() => {
    if (withFilters?.value && withFilters?.value?.length) {
      setAllFilters(withFilters.value);
    }
  }, [withFilters?.value]);

  useEffect(() => {
    if (withGlobalFilter?.value) {
      setGlobalFileterWithDebounce(withGlobalFilter.value);
    }
  }, [withGlobalFilter?.value]);

  return (
    <InfiniteTableScroll
      hasMore={rowsLength < totalCount}
      fetchData={fetchDataInfinite}
      height={scrollContainerHeight || '100%'}
      dataLength={rowsLength}
      loadingText={I18n.t('Loading entities', { entityName })}
    >
      <TableStyles className={className} selectDisabled={disableSelect}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      title=""
                      style={{
                        width: column.width,
                        maxWidth: column.width,
                      }}
                    >
                      <ThInnerDiv>
                        {column.render((props) => {
                          return typeof column.Header === 'string' ? (
                            I18n.t(column.Header)
                          ) : (
                            <column.Header {...props} />
                          );
                        })}
                        {column.id !== 'selection' && !column.disableSortBy && (
                          <ArrowsContainer>
                            <StyledArrowUp isactive={(column.isSorted && !column.isSortedDesc).toString()} />
                            <StyledArrowDown isactive={(column.isSorted && column.isSortedDesc).toString()} />
                          </ArrowsContainer>
                        )}
                      </ThInnerDiv>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const resolvedAccessor = typeof idAccessor === 'function' ? idAccessor(row) : idAccessor;
              return (
                <TableRow
                  key={row.id}
                  row={row}
                  rowEqualityFunc={rowEqualityFunc}
                  isActive={Object.keys(selectedRowIds).includes(row.id) || row.isSelected}
                  highlightRowOptions={highlightRowOptions}
                  resolvedAccessor={resolvedAccessor}
                  selectRow={
                    onRowClick ||
                    (() => {
                      if (!disableSelect) row.toggleRowSelected(!row.isSelected);
                    })
                  }
                />
              );
            })}
          </tbody>
        </table>
        {false && isFetchingAdditionData && !localData ? (
          <RowsLoadingSpinner>
            <Spinner isLoading />
          </RowsLoadingSpinner>
        ) : (
          ''
        )}
        {!data.length && !isLoading && NoContent}
        {isLoading && !showSpinnerDuringInfiniteLoading && <Spinner isLoading text={I18n.t('Loading items')} />}
      </TableStyles>
      {isLoading && showSpinnerDuringInfiniteLoading && <Spinner isLoading text={I18n.t('Loading items')} />}
    </InfiniteTableScroll>
  );
};

Table.propTypes = {
  columns: array.isRequired,
  data: array.isRequired,
  totalCount: number.isRequired,
  lastPageIndex: number,
  appliedFilters: array,
  initialState: object,
  onSelectChange: func,
  onRowClick: func,
  className: string,
  idAccessor: oneOfType([string, func]),
  rowEqualityFunc: func,
  highlightRowOptions: object,
  interceptCheckboxClick: bool,
  shouldResetPage: bool,
  entityName: string,
  setShouldReset: func,
  isLoading: bool,
  searchValue: string,
  disableSelect: bool,
  customEmptyMessage: node,
  scrollContainerHeight: string,
  debounceTimeInMs: number,
  showSpinnerDuringInfiniteLoading: bool,
  onFetchData: func,
  manualSortBy: bool,
  withFilters: object,
  withGlobalFilter: object,
  localData: bool,
};

Table.defaultProps = {
  initialState: {},
  className: '',
  idAccessor: 'id',
  rowEqualityFunc: undefined,
  highlightRowOptions: {},
  onSelectChange: undefined,
  onRowClick: undefined,
  interceptCheckboxClick: false,
  shouldResetPage: false,
  setShouldReset: undefined,
  entityName: '',
  isLoading: false,
  searchValue: '',
  lastPageIndex: null,
  appliedFilters: [],
  disableSelect: false,
  customEmptyMessage: null,
  scrollContainerHeight: undefined,
  debounceTimeInMs: 1500,
  showSpinnerDuringInfiniteLoading: false,
  onFetchData: () => {},
  manualSortBy: true,
  withFilters: {},
  withGlobalFilter: {},
  localData: false,
};

const TableStyles = styled.div`
  position: relative;
  height: 100%;
  table {
    //table-layout: fixed;
    //word-break: break-word;
    border-spacing: 0;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 500;
    font-size: ${(props) => props.theme.fontSizes.small};
    width: auto;
    min-width: 100%;
    thead {
      user-select: none;
      th {
        position: sticky;
        top: 0;
        z-index: 8;
        color: ${(props) => props.theme.colors.darkBlue};
        cursor: pointer;

        text-align: left;
        :hover {
          background-color: ${(props) => props.theme.colors.lightBlue1};
        }
        &:last-child {
          word-wrap: initial;
          word-break: initial;
        }
      }
      // Make checkboxes column smaller
      ${(props) =>
        !props.selectDisabled &&
        css`
          tr th:first-child {
            min-width: 30px !important;
            width: 30px !important;
            max-width: 30px !important;
            & > div > div {
              width: 100%;
            }
          }
        `}
    }

    th {
      color: ${(props) => props.theme.colors.secondary};
      border-bottom: 1px solid ${(props) => props.theme.colors.grey6};
      padding: 1rem;
      font-weight: 600;
      background: ${(props) => props.theme.colors.white};
    }
    td {
      margin: 0;
      padding: 1rem;
      font-size: 12px;
      cursor: default;
      border-bottom: 1px solid ${(props) => props.theme.colors.grey6};
    }

    tr td:first-child,
    th:first-child {
      padding: ${(props) => (props.selectDisabled ? '2rem 1rem' : 0)};
    }

    tbody tr td {
      cursor: pointer;
      word-wrap: break-word;
    }
    tbody tr td:last-child {
      word-wrap: initial;
      word-break: initial;
    }
  }
`;

const ThInnerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  margin-left: 5px;
`;

const StyledArrowUp = styled(ArrowUp)`
  width: 8px;
  height: 8px;
  path {
    fill: ${(props) => (props.isactive === 'true' ? props.theme.colors.blue : '#e4e6e6')};
  }
`;
const StyledArrowDown = styled(ArrowDown)`
  width: 8px;
  height: 8px;
  path {
    fill: ${(props) => (props.isactive === 'true' ? props.theme.colors.blue : '#e4e6e6')};
  }
`;

const CheckboxHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  min-height: 55px;
`;

const EmptyMessageWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EmptyMessage = styled.p`
  margin: 15px 0 0 0;
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
`;

const RowsLoadingSpinner = styled.div`
  position: relative;
  width: 100%;
  height: 30px;
`;

const MemoizedTable = React.memo(Table);

export default MemoizedTable;
