import React, { useState } from 'react';
import styled from 'styled-components';
import { bool, func } from 'prop-types';

const ToggleButton = ({ toggled, onClick, disabled }) => {
  const [isToggled, toggle] = useState(toggled);

  const callback = () => {
    toggle(!isToggled);
    onClick(!isToggled);
  };

  return (
    <StyledContainer disabled={disabled}>
      <StyledLabel htmlFor="first">
        <StyledInput disabled={disabled} id="first" type="checkbox" defaultChecked={isToggled} onClick={callback} />
        <StyledSpan disabled={disabled} />
      </StyledLabel>
    </StyledContainer>
  );
};

const StyledInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const StyledSpan = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #2c3e50;
    background: ${(props) => props.disabled && props.theme.colors.grey2} !important;
    transition: 0.3s;
    border-radius: 30px;
  
    &:before {
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        left: 3px;
        bottom: 2.6px;
        background-color: #fff;
        border-radius: 50%;
        transition: 0.3s;
        }
    }
`;

const StyledLabel = styled.label`
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
}
`;

const StyledContainer = styled.label`
  ${StyledInput}:checked + ${StyledSpan} {
    background-color: #17a9e1;
    background-color: ${(props) => props.disabled && props.theme.colors.grey2} !important;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }

  ${StyledInput}:checked + ${StyledSpan}:before {
    transform: translateX(14px);
  }
`;

ToggleButton.propTypes = {
  onClick: func,
  disabled: bool,
  toggled: bool,
};

ToggleButton.defaultProps = {
  onClick: undefined,
  disabled: false,
  toggled: undefined,
};

export default ToggleButton;
