import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import styled, { keyframes, css } from 'styled-components';
import { capitalize } from 'lodash';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { LicenseManager } from '@ag-grid-enterprise/core';
import PageWrapper from '../PageWrapper';
import TableTabs from '../reusable/TableTabs';
import BulkActionsSidebar from '../BulkActionsSidebar';
import reportsBulkActions from '../../table/bulkActions/reports';
import useBulkSidebarState from '../../hooks/useBulkSidebarState';
import downloadZippedArchiveFromReports from './reports.utils';
import {
  setSelectedReports,
  setActiveReportsTab,
  fetchReports,
  deleteReports,
  markReportsAsViewed,
} from '../../store/reports/actions';
import { selectReportsTabs } from '../../store/reports/selectors';
import * as actions from '../../table/actionsList';
import ConfirmationModal from '../reusable/ConfirmationModal';
import createToastNotification from '../../utils/createToastNotification';
import isChineseEnv from '../../utils/isChineseEnv';
import { ReactComponent as CompletedIcon } from '../../assets/icons/check.svg';
import { ReactComponent as UnreadIcon } from '../../assets/icons/unread.svg';
import { ReactComponent as ProgressIcon } from '../../assets/icons/progress.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';
import { ReactComponent as ExcelIcon } from '../../assets/icons/report-excel.svg';
import { ReactComponent as ZipIcon } from '../../assets/icons/report-zip.svg';
import { ReactComponent as PdfIcon } from '../../assets/icons/report-pdf.svg';
import { ReactComponent as DocIcon } from '../../assets/icons/report-doc.svg';
import { findReportFormat } from '../../table/bulkActions/reports';

ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, SetFilterModule]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

const Reports = ({ location: { pathname } }) => {
  const reports = useSelector((state) => state.reports.reports);
  const selectedReports = useSelector((state) => state.reports.selectedReports);
  const tabs = useSelector(selectReportsTabs);
  const activeTab = useSelector((state) => state.reports.activeTab);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);

  const [isLoading, setLoadingStatus] = useState(true);

  const fetchData = useCallback(({ tableData, shouldReset, showSpinner, tableCallback }) => {
    if (showSpinner) setLoadingStatus(true);
    dispatch(
      fetchReports(tableData, {
        callback: () => {
          setLoadingStatus(false);
          tableCallback && tableCallback();
        },
        shouldReset,
      }),
    );
  }, []);

  const [isDeletionModalVisible, setDeletionModalStatus] = useState(false);

  const closeConfirmationModal = () => setDeletionModalStatus(false);
  const openConfirmationModal = () => setDeletionModalStatus(true);

  const [shouldResetPage, setShouldResetPageStatus] = useState(false);

  const onDeleteConfirm = () => {
    const data = selectedReports.map((report) => ({
      reportType: report.reportType,
      notificationID: report.notificationID || null,
      requestID: report.requestID || null,
    }));
    dispatch(
      deleteReports(data, () => {
        setShouldResetPageStatus(true);
        closeConfirmationModal();
      }),
    );
  };

  const onDownloadClick = (reports) => {
    reports.forEach((report) => {
      const link = document.createElement('a');
      link.href = report.downloadLink;
      link.download = report.downloadLink.substr(report.downloadLink.lastIndexOf('/') + 1);
      link.click();
    });
    dispatch(markReportsAsViewed(reports.map((item) => item.notificationID)));
  };

  const onOpenClick = (reports) => {
    reports.forEach((report) => {
      window.open(report.downloadLink, '_blank');
    });
    dispatch(markReportsAsViewed(reports.map((item) => item.notificationID)));
  };

  // Controlling bulk actions sidebar state
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedReports });

  // BULK ACTIONS
  const bulkActionsHandlers = {
    [actions.OPEN_REPORT]: () => onOpenClick(selectedReports),
    [actions.DOWNLOAD_REPORT]: () => {
      if (selectedReports.length > 50) {
        createToastNotification({
          message: I18n.t('downloadLimitToast'),
          type: 'error',
        });
        return;
      }

      if (selectedReports.length > 1)
        downloadZippedArchiveFromReports(
          selectedReports.filter((item) => item.reportType !== 1),
          () => {
            dispatch(markReportsAsViewed(selectedReports.map((item) => item.notificationID)));
          },
        );
      else onDownloadClick(selectedReports);
    },
    [actions.DELETE_REPORT]: openConfirmationModal,
  };

  const bulkActions = useMemo(() => {
    return reportsBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedReports) : item.isDisabled,
    }));
  }, [selectedReports]);

  const onTabClick = (tab) => {
    setShouldResetPageStatus(true);
    dispatch(setActiveReportsTab(tab));
  };

  const iconsMap = {
    viewed: CompletedIcon,
    unread: UnreadIcon,
    pending: ProgressIcon,
    error: WarningIcon,
  };

  const styleFormatIcon = (Icon) => styled(Icon)`
    margin-right: 1rem;
  `;

  const formatIconsMap = {
    zip: styleFormatIcon(ZipIcon),
    pdf: styleFormatIcon(PdfIcon),
    xlsx: styleFormatIcon(ExcelIcon),
    doc: styleFormatIcon(DocIcon),
  };

  const rotate = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  `;

  const animation = () =>
    css`
      ${rotate} 2s infinite linear;
    `;

  const styleIcon = (Icon, status) => styled(Icon)`
    margin-right: 10px;
    width: 18px;
    height: 18px;
    animation: ${() => (status === 'pending' ? animation : 'none')};

    .a {
      ${() =>
        status === 'error' &&
        css`
          fill: ${(props) => props.theme.colors.red};
          stroke: ${(props) => props.theme.colors.red};
          stroke-width: 0.3px;
        `}
    }
  `;

  const agGridDefaultSettings = {
    pagination: true,
    paginationPageSize: 50,
    paginationPageSizeSelector: [10, 20, 50],
    rowHeight: '58',
    headerHeight: '58',
    domLayout: 'autoHeight',
    gridOptions: { popupParent: document.body },
    onSelectionChanged: (event) => {
      const selectedData = [];
      event.api.forEachNodeAfterFilter((node) => {
        if (node.isSelected()) {
          selectedData.push(node.data);
        }
      });
      dispatch(setSelectedReports(selectedData));
    },
  };

  const agGriddefaultColumnDefinition = {
    sortable: true,
  };

  const [columnDefinition] = useState([
    {
      headerName: I18n.t('PersonName'),
      cellDataType: 'text',
      filter: 'agTextColumnFilter',
      flex: 2,
      valueGetter: (params) => {
        const fullName = isChineseEnv
          ? `${params.data.familyName}${params.data.firstName}`
          : `${params.data.firstName} ${params.data.familyName}`;
        return fullName;
      },
      cellRenderer: ({ data }) => {
        const mainName = isChineseEnv ? `${data.familyName}${data.firstName}` : `${data.firstName} ${data.familyName}`;
        const respondentsLength = data.respondentIDs.split(',').length - 1;
        const Icon = formatIconsMap[findReportFormat(data)];
        return (
          <Row>
            {data.downloadLink && Icon && <Icon />}
            <span>{mainName} </span>
            {respondentsLength > 0 && (
              <span style={{ fontStyle: 'italic', fontSize: '8px' }}>and {respondentsLength} more</span>
            )}
          </Row>
        );
      },
    },
    {
      field: 'questionnaire',
      headerName: 'Report',
      cellDataType: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
    },
    {
      field: 'status',
      headerName: I18n.t('Status'),
      cellDataType: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
      cellRenderer: ({ data: { status, error } }) => {
        const finalizedStatus = error ? 'error' : status;
        const Icon = status !== 'deleted' && styleIcon(iconsMap[finalizedStatus], finalizedStatus);
        return (
          <StatusContainer>
            {status !== 'deleted' && <Icon />}
            <StatusLabel error={error}>
              {finalizedStatus === 'error' ? I18n.t('Support ticket raised') : I18n.t(capitalize(finalizedStatus))}
            </StatusLabel>
          </StatusContainer>
        );
      },
    },
    {
      field: 'creationDate',
      headerName: 'Creation Date',
      cellDataType: 'date',
      flex: 1,
      filter: 'agDateColumnFilter',
      sort: 'desc',
      valueGetter: (params) => {
        return new Date(params.data.creationDate);
      },
    },
  ]);

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
    };
  }, []);

  const handleFilterChanged = (params) => {
    params.api.deselectAll();
    params.api.refreshHeader();
  };

  function getRowClass(params) {
    if (params.data.reportType === 2) {
      return 'row-unread';
    }
    return null;
  }

  useEffect(() => {
    fetchData({ shouldReset: true, showSpinner: true });
  }, [activeTab]);

  return (
    <PageWrapper title={I18n.t('Reports')} style={{ overflowY: 'auto' }}>
      <BulkActionsSidebar actions={bulkActions} isOpen={isSidebarOpen} onClose={closeSidebar} />
      <TableTabs tabs={tabs} clickHandler={onTabClick} activeTab={activeTab} />
      <div className="ag-theme-quartz ag-grid-custom-styles">
        <AgGridReact
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          rowData={isLoading ? null : reports}
          rowSelection={rowSelection}
          onFilterChanged={handleFilterChanged}
          getRowClass={getRowClass}
        />
      </div>
      <ConfirmationModal
        title={I18n.t('Report deletion')}
        onClose={closeConfirmationModal}
        isVisible={isDeletionModalVisible}
        description={I18n.t('Are you sure you want to delete selected report(s)?')}
        onConfirm={onDeleteConfirm}
        caution
      />
    </PageWrapper>
  );
};

Reports.propTypes = {
  location: object,
};

Reports.defaultProps = {
  location: {},
};

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StatusLabel = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => (props.error ? 'red' : props.theme.colors.primary)};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

export default Reports;
