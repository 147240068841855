import React, { useMemo } from 'react';
import { object, bool, func } from 'prop-types';
import { isAfter, format } from 'date-fns';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

import getDateFromNow from '@utils/getDateFromNow';

import CustomButton from '../../reusable/Buttons/Button';
import Spinner from '../../reusable/Spinner';
import ProjectInviteDropdown from './ProjectInviteDropdown';

import languagesMap from '../../../constants/languages';
import { sendingOptions, reminderOptions } from './components/options';

import { ReactComponent as InfoIcon } from '../../../assets/icons/small-info.svg';
import { AP_COMPLETED_STATUS } from '../../../constants/assessmentProjectStatuses';

const AssessmentProjectHeader = ({ project, isLoading, onInvite, onDownload, onInvitesDownload }) => {
  const {
    ableToDownloadInviteCsv,
    assessmentName,
    description,
    invitedCount,
    reportsCount,
    totalCount,
    langID,
    sendInviteType,
    remindInviteType,
    sendInviteDate,
    status,
  } = project;
  const actions = [
    {
      name: 'emailInvite',
      label: I18n.t('Invite via email'),
      isDisabled: false,
      handler: onInvite,
    },
    {
      name: 'csvLink',
      label: I18n.t('Download invite links in a csv file'),
      isDisabled: !ableToDownloadInviteCsv,
      handler: onInvitesDownload,
    },
  ];

  const isProjectCompleted = Number(status) === AP_COMPLETED_STATUS;

  const invitesCount = useMemo(() => {
    if (!sendInviteType && sendInviteType !== 0) return null;
    if (sendInviteType === 0)
      return (
        <CenteringBlock>
          <InvitedCount>
            <span>{invitedCount}</span>/
            <span>
              {totalCount} {I18n.t('invited')}
            </span>
          </InvitedCount>
          <ProjectInviteDropdown actions={actions} disabled={isProjectCompleted} />
        </CenteringBlock>
      );
    if (sendInviteType === 1)
      return (
        <InvitedCount>
          {invitedCount} {I18n.t('invites sent')}
        </InvitedCount>
      );
    if (sendInviteType === 2) {
      const inviteDate = new Date(sendInviteDate);
      const isInvitesSent = isAfter(new Date(), inviteDate);

      return !isInvitesSent ? (
        <InvitedCount>
          {I18n.t('Invites will be sent on')} {format(inviteDate, 'dd/M/y HH:mm')}
        </InvitedCount>
      ) : (
        <InvitedCount>
          <div>
            {I18n.t('Invites were sent on')} {format(inviteDate, 'dd/M/y HH:mm')}
          </div>
          <StyledInfoIcon data-tip data-for="info" />
          <StyledTooltip id="info">
            {I18n.t('Respondents added after scheduled date will be invited automatically')}
          </StyledTooltip>
        </InvitedCount>
      );
    }
  }, [sendInviteType, sendInviteDate, invitedCount, totalCount, isProjectCompleted]);

  return (
    <AssessmentProjectHeaderContainer>
      <Spinner isLoading={isLoading} />
      <InfoBlock>
        <Row>
          <ValueBox>
            <ValueTitle>{I18n.t('Assessment')}</ValueTitle>
            <Value>{assessmentName}</Value>
          </ValueBox>
        </Row>
        <Row justifyContent="flex-start">
          <ValueBox>
            <ValueTitle>{I18n.t('Language')}</ValueTitle>
            <Value>
              {languagesMap[langID]} ({langID})
            </Value>
          </ValueBox>
        </Row>

        <ValueBox>
          <ValueTitle>{I18n.t('Description')}</ValueTitle>
          <DescriptionValue>{description || '-'}</DescriptionValue>
        </ValueBox>
        {/* <ValueBox style={{marginTop: "15px"}}>
          <ValueTitle>{I18n.t('Expiry Date')}</ValueTitle>
          <DescriptionValue>{'date' || '-'}</DescriptionValue>
        </ValueBox> */}
      </InfoBlock>

      <ActionsBlock>
        <Tile>
          <div>
            <CenteringBlock>
              <TileTitle>{sendingOptions[sendInviteType]?.longLabel}</TileTitle>
              <InfoIcon data-tip data-for="info" />
              <StyledTooltip id="info">{sendingOptions[sendInviteType]?.hint}</StyledTooltip>
            </CenteringBlock>
            <InvitesOptions>
              <InvitesPair>
                <span>{I18n.t('Reminder')}:</span>
                <span>{reminderOptions.find((item) => item.backendReference === remindInviteType)?.label}</span>
              </InvitesPair>
            </InvitesOptions>
          </div>
          {invitesCount}
        </Tile>

        <Tile>
          <div>
            <CenteringBlock>
              <TileTitle>{I18n.t('Reports')}</TileTitle>{' '}
              <ReportsCount>
                ({reportsCount}/{totalCount})
              </ReportsCount>
            </CenteringBlock>
            <ReportsLabel>{I18n.t('Reports are generated after completing the assessment')}</ReportsLabel>
          </div>
          <CustomButton disabled={reportsCount <= 0} handler={onDownload} size="s" variant="secondary">
            {I18n.t('Download')}
          </CustomButton>
        </Tile>
      </ActionsBlock>
    </AssessmentProjectHeaderContainer>
  );
};

AssessmentProjectHeader.propTypes = {
  project: object.isRequired,
  onInvite: func.isRequired,
  onDownload: func.isRequired,
  onInvitesDownload: func.isRequired,
  isLoading: bool.isRequired,
};

const AssessmentProjectHeaderContainer = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 2.4rem;
  justify-content: space-between;
`;

const ActionsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 46%;
`;

const Tile = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.6rem;
  min-height: 7rem;
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0.8rem;
  margin-bottom: 1.6rem;
`;

const TileTitle = styled.h5`
  font-size: ${(props) => props.theme.fontSizes.normal};
  color: ${(props) => props.theme.colors.almostBlack};
  font-weight: 600;
  line-height: 20px;
  margin-right: 0.6rem;
`;

const InvitesOptions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
`;

const InvitesPair = styled.div`
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 1rem;

  span:last-of-type {
    font-weight: bold;
    margin-left: 0.5rem;
  }
  &:last-of-type {
    margin-right: 0;
  }
`;

const CenteringBlock = styled.div`
  display: flex;
  align-items: center;
`;

const InvitedCount = styled.div`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
  color: ${(props) => props.theme.colors.almostBlack};

  span:first-of-type {
    font-weight: bold;
  }
  margin-right: 2rem;
`;

const ReportsCount = styled.span`
  font-size: ${(props) => props.theme.fontSizes.normal};
  color: ${(props) => props.theme.colors.almostBlack};
  line-height: 20px;
`;

const ReportsLabel = styled.p`
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
  margin-top: 0.5rem;
  color: rgba(0, 0, 0, 0.6);
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 42%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent || 'space-between'};
  margin-bottom: 2.4rem;
`;

export const ValueBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${(props) => props.marginRight || 0};
`;

export const ValueTitle = styled.p`
  font-size: 10px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.mediumBlue};
  text-transform: uppercase;
  margin-bottom: 0.8rem;
`;

export const Value = styled.p`
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
  color: rgba(0, 0, 0, 0, 88);
`;

const DescriptionValue = styled.p`
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
  color: rgba(0, 0, 0, 0, 88);
  max-height: 7rem;

  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const StyledTooltip = styled(ReactTooltip)`
  width: 35rem;
`;

const StyledInfoIcon = styled(InfoIcon)`
  margin-left: 0.5rem;
`;

export default AssessmentProjectHeader;
