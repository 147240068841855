const translations = {
  Launch: 'Launch (pt-br)',
  About: 'About (pt-br)',
  Tutorial: 'Tutorial (pt-br)',

  // vars
  LoginSubmit: 'Login',
  'Reset assessment deadline': 'Redefinir prazo',
  downloadInvitesReport: 'Baixar CSV',
  Update: 'atualizar',
  updateAssessmentInvitationToast: 'Convites atualizados(s)',
  updateProjectInvitationToast: 'Convites atualizados(s)',
  biodataTooltip:
    'Os modelos de biodados permitem a coleta personalizada de dados biográficos dos entrevistados. Crie modelos de biodados na área Configurações',
  selectNormForReport: 'Selecione uma norma para o relatório',
  updateGroupToast: 'O grupo foi atualizado',
  updateGroupErrorToast: 'Erro durante a atualização do grupo',
  'Edit Group Details': 'Editar detalhes do grupo',
  'Duplicate Group': 'Duplicar Grupo',
  csvReportName: 'Relatório de Convites %{date}.csv',
  hideAllNormsWarning: 'Voc%C3%AA vai ocultar todas as normas',
  hideAllNormsWarningDescription: 'Isso pode levar a resultados inesperados ao gerar relatórios',
};

export default translations;
