import React, { useState } from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { startOfTomorrow } from 'date-fns';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';
import ReactTooltip from 'react-tooltip';

import getDateFromNow from '@utils/getDateFromNow';

import { errorMessages } from '../../../../constants/errorMessages';

import ProjectModalFooter from '../ProjectModalFooter';
import CustomButton from '../../../reusable/Buttons/Button';
import CustomSelect from '../../../reusable/Selects/Select';
import DateTimeSelect from '../../../reusable/Selects/DateTimeSelect';
import DirectFeedbackReport from '../../../reusable/DirectFeedbackReport';
import Spinner from '../../../reusable/Spinner';
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrow-right-thin.svg';
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning-blue.svg';
import { ReactComponent as QuestionIcon } from '../../../../assets/icons/question-mark.svg';

import { sendingOptions, reminderOptions } from '../../AssessmentProject/components/options';

import { saveProjectInvitesData } from '../../../../store/projects/actions';
import { selectWelcomeTextsOptions } from '../../../../store/settings/welcome-texts/selectors';
import { selectTemplatesAsOptions } from '../../../../store/settings/templates/selectors';
import { selectAvailableMappedBiodataTemplates } from '../../../../store/user/selectors';

const InvitesStep = ({ goPrev, goNext }) => {
  const initialData = useSelector((state) => state.projects.createAssessmentProject.invites);
  const assessmentData = useSelector((state) => state.projects.createAssessmentProject.assessment);
  const dispatch = useDispatch();

  const welcomeTexts = useSelector(selectWelcomeTextsOptions);
  const templates = useSelector(selectTemplatesAsOptions);

  const initialExpiryDate = React.useMemo(() => {
    return initialData.expiryDate || getDateFromNow(30, 'day_end');
  }, [initialData.expiryDate]);

  const availableBiodataTemplates = useSelector(selectAvailableMappedBiodataTemplates);
  const hasDefaultTemplate = availableBiodataTemplates.find(
    (template) => template.isDefault === true && template.value !== 0,
  );
  const [biodataTemplate] = useState(hasDefaultTemplate || availableBiodataTemplates[0]);

  const { values, setFieldValue, handleSubmit, errors, setFieldError } = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      template: initialData.template || (templates.length && templates[0]),
      welcomeText: initialData.welcomeText || (welcomeTexts.length && welcomeTexts[0]),
      reminderType: initialData.reminderType || reminderOptions.find((item) => item.value === 'weekly'),
      sendingType: initialData.sendingType || sendingOptions.find((item) => item.value === 'manually'),
      invitationDate: initialData.invitationDate,
      isDirectReport: initialData.isDirectReport,
      biodataTemplate: initialData.biodataTemplate || (availableBiodataTemplates.length && hasDefaultTemplate),
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      template: Yup.object()
        .nullable()
        .required(errorMessages.inputField),
      welcomeText: Yup.object()
        .nullable()
        .required(errorMessages.inputField),
      reminderType: Yup.object()
        .nullable()
        .required(errorMessages.inputField),
      sendingType: Yup.object()
        .nullable()
        .required(errorMessages.inputField),
      invitationDate: Yup.string().required(errorMessages.inputField),
      isDirectReport: Yup.bool(),
      biodataTemplate: Yup.object()
        .nullable()
        .required(errorMessages.biodataTemplate),
    }),
    onSubmit: (values) => {
      dispatch(saveProjectInvitesData(values));
      goNext();
    },
  });

  const onSelectChange = (name) => (value) => {
    setFieldValue(name, value);
    setFieldError(name, '');
  };

  const toggleDirectReportStatus = () => setFieldValue('isDirectReport', !values.isDirectReport);

  const isDirectReportAvailable = Boolean(
    assessmentData.assessment.directFeedbackReportLanguagesAvailable?.includes(assessmentData.language.code),
  );

  const isLoading = !welcomeTexts.length || !templates.length;

  return (
    <Container>
      <Spinner isLoading={isLoading} />
      <Form>
        <Title>{I18n.t('Invites')}</Title>
        <Row>
          <InfoSelectWrapper>
            <StyledCustomSelect
              options={availableBiodataTemplates}
              placeholder={I18n.t('Bio data template')}
              selectProps={{ value: biodataTemplate, onChange: onSelectChange('biodataTemplate') }}
              isDisabled={availableBiodataTemplates.length === 0}
              error={errors.welcomeText}
            />
            <QuestionIcon data-tip data-for="biodata" />
            <StyledTooltip id="biodata">{I18n.t('biodataTooltip')}</StyledTooltip>
          </InfoSelectWrapper>
        </Row>
        <Row>
          <StyledSelect
            options={templates}
            placeholder={I18n.t('Email template')}
            selectProps={{ value: values.template, onChange: onSelectChange('template') }}
            error={errors.template}
          />
          <StyledSelect
            options={welcomeTexts}
            placeholder={I18n.t('Welcome text')}
            selectProps={{ value: values.welcomeText, onChange: onSelectChange('welcomeText') }}
            error={errors.welcomeText}
          />
        </Row>
        <Row>
          <StyledSelect
            options={reminderOptions}
            placeholder={I18n.t('Remind')}
            selectProps={{ value: values.reminderType, onChange: onSelectChange('reminderType') }}
            error={errors.reminderType}
          />
        </Row>
        <Row>
          <StyledSelect
            options={sendingOptions}
            placeholder={I18n.t('How to send invites')}
            selectProps={{ value: values.sendingType, onChange: onSelectChange('sendingType') }}
            error={errors.sendingType}
          />

          {values.sendingType?.value === 'schedule' && (
            <DateTimeSelect
              inputName={I18n.t('Send invites on')}
              date={values.invitationDate}
              onDateChange={(date) => setFieldValue('invitationDate', date)}
              inputWidth="34rem"
              minDate={startOfTomorrow()}
            />
          )}
        </Row>

        {values.sendingType && (
          <InfoContainer>
            <StyledWarningIcon />
            <span>{values.sendingType.description}</span>
          </InfoContainer>
        )}
        {isDirectReportAvailable && (
          <>
            <Title>{I18n.t('Direct feedback report')}</Title>
            <DirectFeedbackReport onChange={toggleDirectReportStatus} isChecked={values.isDirectReport} />
          </>
        )}
      </Form>
      <Footer>
        <Button handler={goPrev} variant="secondary">
          <ArrowLeft />
          {I18n.t('Back')}
        </Button>
        <Button handler={handleSubmit}>
          {I18n.t('Next')} <ArrowRight />
        </Button>
      </Footer>
    </Container>
  );
};

InvitesStep.propTypes = {
  goPrev: func.isRequired,
  goNext: func.isRequired,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Title = styled.h3`
  color: ${(props) => props.theme.colors.mediumBlue};
  font-size: ${(props) => props.theme.fontSizes.normal};
  font-weight: 600;
  margin-bottom: 1.6rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0 2.4rem;
  flex-grow: 1;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const StyledSelect = styled(CustomSelect)`
  width: 34rem;
  margin-bottom: 0;
`;

export const DaysLabel = styled.span`
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: ${(props) => props.theme.fontSizes.small};
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  background-color: #e0f1f7;
  padding: 1.8rem;
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
  color: ${(props) => props.theme.colors.darkBlue2};

  span {
    margin-left: 1rem;
  }
`;

const Footer = styled(ProjectModalFooter)`
  justify-content: space-between;
`;

const ArrowLeft = styled(ArrowRight)`
  transform: rotate(180deg);
  path {
    fill: ${(props) => props.theme.colors.lightBlue};
  }
`;

const Button = styled(CustomButton)`
  text-transform: uppercase;
  width: 12rem;
  justify-content: space-evenly;
`;

export const StyledWarningIcon = styled(WarningIcon)`
  min-width: 2rem;
  min-height: 2rem;
`;

const InfoSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  // width: 34rem;
`;

const StyledCustomSelect = styled(CustomSelect)`
  width: 100%;
  margin-bottom: 0;
  margin-right: 1rem;
`;

const StyledTooltip = styled(ReactTooltip)`
  width: 30rem;
`;

export default InvitesStep;
