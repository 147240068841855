import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { func, array, object, oneOfType, string, number, bool } from 'prop-types';
import * as Yup from 'yup';
import { connect, useSelector } from 'react-redux';
import { withFormik } from 'formik';
import { sortBy } from 'lodash';
import compose from 'recompose/compose';
import { I18n } from 'react-redux-i18n';
import { addRespondent } from '../../../store/respondents/actions';
import isChineseEnv from '../../../utils/isChineseEnv';

import TagsTab from './TagsTab';
import GroupsTab from './GroupsTab';

import CustomSelect from '../../reusable/Selects/Select';
import DateSelect from '../../reusable/FormComponents/DateSelect';
import CustomButton from '../../reusable/Buttons/Button';

import {
  educationOptions,
  occupationOptions,
  sectorOptions,
  industryOptions,
  languageOptions,
  genderOptions,
  ethnicityOptions,
} from '../../../constants/respondentSelectOptions';
import { errorMessages } from '../../../constants/errorMessages';
import FormikInput from '../../reusable/FormComponents/FormikInput';

const optionsMapper = {
  Ethnicity: ethnicityOptions,
  Education: educationOptions,
  'Job Area': occupationOptions,
  Industry: industryOptions,
  Language: languageOptions,
  Sector: sectorOptions,
};

const selectorMapper = {
  Ethnicity: 'ethnicity',
  Education: 'education',
  'Job Area': 'jobArea',
  Industry: 'industry',
  Language: 'firstLanguage',
  Sector: 'sector',
};

const defaultBiodataTemplate = [
  {
    name: 'Ethnicity',
    optionValues: optionsMapper.Ethnicity,
  },
  {
    name: 'Education',
    optionValues: optionsMapper.Education,
  },
  {
    name: 'Job Area',
    optionValues: optionsMapper['Job Area'],
  },
  {
    name: 'Sector',
    optionValues: optionsMapper.Sector,
  },
  {
    name: 'Industry',
    optionValues: optionsMapper.Industry,
  },
  {
    name: 'Language',
    optionValues: optionsMapper.Language,
  },
];

const allOccupationOptions = occupationOptions.reduce((acc, item) => {
  return [...acc, ...item.options];
}, []);

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required(errorMessages.inputField)
    .max(200, errorMessages.tooLong(200)),
  ...(isChineseEnv
    ? {
        familyName: Yup.string()
          .trim()
          .required(errorMessages.inputField)
          .max(200, errorMessages.tooLong(200))
          .optional(),
      }
    : {
        familyName: Yup.string()
          .trim()
          .required(errorMessages.inputField)
          .max(200, errorMessages.tooLong(200)),
      }),
  email: Yup.string()
    .trim()
    .email(errorMessages.emailField)
    .max(150, errorMessages.tooLong(150)),
  title: Yup.string().max(50, errorMessages.tooLong(50)),
  reference: Yup.string().max(150, errorMessages.tooLong(150)),
  company: Yup.string().max(150, errorMessages.tooLong(150)),
});

const AddRespondentForm = ({
  handleSubmit,
  handleChange,
  setFieldValue,
  setFieldError,
  values,
  errors,
  touched,
  allTags,
  activeTab,
  allGroups,
  groupId,
  resetToInitialTab,
  dirty,
}) => {
  const availableBiodataTemplates = useSelector((state) => state.user.biotemplates);
  const hasDefaultTemplate = availableBiodataTemplates.find(
    (template) => template.isDefault === true && template.value !== 0,
  );

  const biodataTemplate = hasDefaultTemplate || availableBiodataTemplates[0];
  const mappedBiodataTemplates = [];
  if (biodataTemplate) {
    biodataTemplate.bioOptions.forEach(({ label, values }) => {
      return mappedBiodataTemplates.push({
        label: selectorMapper[label],
        options: optionsMapper[label],
        values,
      });
    });
  }

  const textInputHandler = (name) => (e) => {
    if (name === 'email') {
      e.target.value = e.target.value.trim();
    }
    setFieldError(name, '');
    handleChange(e);
  };
  const renderTextInput = useCallback((name, label) => (
    <StyledInput
      name={name}
      id={name}
      inputName={label}
      onChange={textInputHandler(name)}
      value={values[name]}
      errors={errors}
      touched={touched}
    />
  ));

  // TAGS TAB MANAGEMENT
  // all tags without those already added
  const allTagsOptions = allTags.filter((item) => !values.tags.find((tag) => tag.tagID === item.tagID));
  const tagOptions = sortBy(
    allTagsOptions.map((item) => ({ ...item, value: item.tagID, label: item.name })),
    'name',
  );

  const addTag = (newTags) => {
    if (!newTags?.length) return;

    // give tag object a name if it doesn't have one (manual input case)
    const newTagsWithName = newTags.map((item) => ({ ...item, name: item.name || item.value }));
    // remove potential duplicates
    const withoutDuplicates = newTagsWithName.filter((item) => !values.tags.find((tag) => tag.name === item.name));
    setFieldValue('tags', [...values.tags, ...withoutDuplicates]);
  };

  const removeTag = (tag) => {
    setFieldValue(
      'tags',
      values.tags.filter((item) => item.tagID !== tag.tagID),
    );
  };

  // GROUPS TAB MANAGEMENT
  const allGroupsOptions = allGroups.filter((item) => !values.groups.find((group) => group.groupID === item.groupID));
  const groupsOptions = sortBy(
    allGroupsOptions.map((item) => ({ ...item, value: item.groupID, label: item.name })),
    'name',
  );

  // set default preselected group if groupId prop is provided
  useEffect(() => {
    if (groupId && groupsOptions.length && !values.groups.length) {
      setFieldValue(
        'groups',
        groupsOptions.filter((item) => item.groupID === Number(groupId)),
      );
    }
  }, [groupsOptions.length, groupId]);

  const addGroup = (newGroups) => {
    if (!newGroups?.length) return;

    // give group object a name if it doesn't have one (manual input case)
    const newGroupsWithName = newGroups.map((item) => ({ ...item, name: item.name || item.value }));
    // remove potential duplicates
    const withoutDuplicates = newGroupsWithName.filter(
      (item) => !values.groups.find((group) => group.name === item.name),
    );
    setFieldValue('groups', [...values.groups, ...withoutDuplicates]);
  };

  const removeGroup = (group) => {
    setFieldValue(
      'groups',
      values.groups.filter((item) => item.groupID !== group.groupID),
    );
  };

  const formatBioData = (biodataCategories) => {
    const biodataFormatedCategories = [];
    biodataCategories.forEach(({ label, options, values }) => {
      if (values[0] === '') return;
      const categoryValue = [];
      values.forEach((item) => {
        const finalOptions = label === 'jobArea' ? allOccupationOptions : options;
        finalOptions.forEach(({ label, value }) => {
          if (item === value) {
            categoryValue.push({
              label,
              value,
            });
          }
        });
      });
      return biodataFormatedCategories.push({ name: label, optionValues: categoryValue });
    });
    return biodataFormatedCategories;
  };

  const biodataFormatedOptions = biodataTemplate ? formatBioData(mappedBiodataTemplates) : defaultBiodataTemplate;

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        if (dirty && Object.values(errors).filter((i) => i).length) handleSubmit(e);
        else {
          resetToInitialTab();
          handleSubmit(e);
        }
      }}
      autoComplete="off"
    >
      {activeTab.name === 'mainDetails' && (
        <InputsHolder>
          {renderTextInput('firstName', I18n.t('First Name'))}
          {!isChineseEnv && renderTextInput('familyName', I18n.t('Family Name'))}
          <GenderSelect
            placeholder={I18n.t('Sex')}
            options={genderOptions}
            selectProps={{
              onChange: (option) => setFieldValue('sex', option.value),
              value: genderOptions.find((item) => item.value === values.sex) || null,
              menuPosition: 'fixed',
            }}
          />
          {renderTextInput('title', I18n.t('BioTitle'))}
          {renderTextInput('email', I18n.t('Email Address'))}
          <DatePicker
            selectedDate={values.dob}
            onDateChange={(date) => setFieldValue('dob', date)}
            label={I18n.t('Date of Birth')}
          />
          {renderTextInput('reference', I18n.t('Reference'))}
        </InputsHolder>
      )}
      {activeTab.name === 'extraDetails' && (
        <InputsHolder>
          {renderTextInput('company', I18n.t('Company Name'))}
          {biodataFormatedOptions.map(({ name, optionValues }) => (
            <StyledSelect
              key={name}
              placeholder={name}
              options={optionValues}
              selectProps={{
                onChange: (option) => {
                  setFieldValue(name, option.value);
                },
                value: optionValues.find((item) => item.value === values[name]) || null,
                menuPosition: 'fixed',
              }}
            />
          ))}
        </InputsHolder>
      )}
      {activeTab.name === 'tags' && (
        <TagsTab allTags={tagOptions} onAddTag={addTag} selectedTags={values.tags} onRemoveTag={removeTag} />
      )}
      {activeTab.name === 'groups' && (
        <GroupsTab
          allGroups={groupsOptions}
          selectedGroups={values.groups}
          onAddGroup={addGroup}
          onRemoveGroup={removeGroup}
        />
      )}
      <ButtonWrapper>
        <CustomButton type="submit">{I18n.t('Save')}</CustomButton>
      </ButtonWrapper>
    </Form>
  );
};

AddRespondentForm.propTypes = {
  handleSubmit: func.isRequired,
  handleChange: func.isRequired,
  setFieldValue: func.isRequired,
  setFieldError: func.isRequired,
  values: object.isRequired,
  errors: object.isRequired,
  touched: object.isRequired,
  allTags: array.isRequired,
  allGroups: array.isRequired,
  activeTab: object.isRequired,
  groupId: oneOfType([string, number]),
  resetToInitialTab: func.isRequired,
  dirty: bool.isRequired,
};

AddRespondentForm.defaultProps = {
  groupId: '',
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const InputsHolder = styled.div`
  flex-grow: 1;
`;

const StyledSelect = styled(CustomSelect)`
  margin-bottom: 2rem;
`;

const GenderSelect = styled(StyledSelect)`
  width: 8rem;
`;

const StyledInput = styled(FormikInput)`
  margin-bottom: 2rem;
`;

const DatePicker = styled(DateSelect)`
  margin-bottom: 2rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const mapDispatchToProps = (dispatch) => ({
  createRespondent: (respondent) => {
    dispatch(addRespondent(respondent));
  },
});

export default compose(
  connect(null, mapDispatchToProps),
  withFormik({
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({
      firstName: '',
      familyName: '',
      sex: 'U',
      email: '',
      title: '',
      dob: null,
      reference: '',
      company: '',
      education: '',
      ethnicity: '',
      jobArea: '',
      sector: '',
      industry: '',
      language: '',
      tags: [],
      groups: [],
    }),
    handleSubmit: (values, formikBag) => {
      const preparedData = {
        ...values,
        tags: values.tags.map((item) => item.name),
        groups: values.groups.map((item) => item.groupID),
        firstLanguage: values.language,
      };
      formikBag.props.createRespondent(preparedData);

      // TODO: Implement proper callback handling
      formikBag.props.onSubmit();
    },
    displayName: 'AddRespondentForm',
  }),
)(AddRespondentForm);
