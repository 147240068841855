import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { push } from 'redux-first-history';

import { I18n } from 'react-redux-i18n';

import PageWrapper from '../../PageWrapper';

import Logo from '../../reusable/Logo';
import FAQDropDown from './DropDown';

const FAQS = () => {
  const dispatch = useDispatch();

  const goBackToSettings = () => dispatch(push('/settings'));

  const questions = [
    {
      id: 1,
      title: 'Question Title 1',
      description:
        'Lorem ipsum dolor sit amet consectetur adipiscing elit neque torquent, auctor viverra nisl pretium euismod senectus laoreet ultricies, varius nulla tellus scelerisque vel montes imperdiet per. Scelerisque mauris cras per sagittis proin facilisis faucibus taciti, libero iaculis class dui condimentum sollicitudin sociis fames inceptos, diam posuere pellentesque quisque tincidunt a egestas. Enim neque dui tellus parturient nec, sollicitudin ullamcorper per donec, non bibendum interdum erat. <br> Justo congue arcu orci convallis sociis volutpat ut tortor, vel per dui varius augue venenatis felis lacus, dictum molestie sem mi ridiculus ullamcorper pharetra.Sed pulvinar rhoncus faucibus fusce interdum curabitur scelerisque tortor suscipit felis, id eleifend accumsan venenatis turpis platea vitae cursus mollis convallis, fringilla semper tempor dis quam neque orci risus conubia.Vestibulum mus porttitor rutrum odio augue, eleifend faucibus a. <br><br> <img src="https://fastly.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI"/>',
    },
    {
      id: 2,
      title: 'Question Title 2',
      description:
        'Lorem ipsum dolor sit amet consectetur adipiscing elit neque torquent, auctor viverra nisl pretium euismod senectus laoreet ultricies, varius nulla tellus scelerisque vel montes imperdiet per. Scelerisque mauris cras per sagittis proin facilisis faucibus taciti, libero iaculis class dui condimentum sollicitudin sociis fames inceptos, diam posuere pellentesque quisque tincidunt a egestas. Enim neque dui tellus parturient nec, sollicitudin ullamcorper per donec, non bibendum interdum erat. <br> Justo congue arcu orci convallis sociis volutpat ut tortor, vel per dui varius augue venenatis felis lacus, dictum molestie sem mi ridiculus ullamcorper pharetra.Sed pulvinar rhoncus faucibus fusce interdum curabitur scelerisque tortor suscipit felis, id eleifend accumsan venenatis turpis platea vitae cursus mollis convallis, fringilla semper tempor dis quam neque orci risus conubia.Vestibulum mus porttitor rutrum odio augue, eleifend faucibus a.',
    },
    {
      id: 3,
      title: 'Question Title 3',
      description:
        'Lorem ipsum dolor sit amet consectetur adipiscing elit neque torquent, auctor viverra nisl pretium euismod senectus laoreet ultricies, varius nulla tellus scelerisque vel montes imperdiet per. Scelerisque mauris cras per sagittis proin facilisis faucibus taciti, libero iaculis class dui condimentum sollicitudin sociis fames inceptos, diam posuere pellentesque quisque tincidunt a egestas. Enim neque dui tellus parturient nec, sollicitudin ullamcorper per donec, non bibendum interdum erat. <br> Justo congue arcu orci convallis sociis volutpat ut tortor, vel per dui varius augue venenatis felis lacus, dictum molestie sem mi ridiculus ullamcorper pharetra.Sed pulvinar rhoncus faucibus fusce interdum curabitur scelerisque tortor suscipit felis, id eleifend accumsan venenatis turpis platea vitae cursus mollis convallis, fringilla semper tempor dis quam neque orci risus conubia.Vestibulum mus porttitor rutrum odio augue, eleifend faucibus a.',
    },
  ];

  return (
    <StyledPageWrapper title={I18n.t('FAQs')} backButtonHandler={goBackToSettings}>
      <Logo />
      <FAQDropDown questions={questions} />
    </StyledPageWrapper>
  );
};

FAQS.propTypes = {};

const StyledPageWrapper = styled(PageWrapper)`
  width: 90%;
`;

export default FAQS;
