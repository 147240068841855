import React, { useMemo } from 'react';
import { func, instanceOf, object, array, bool, string } from 'prop-types';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { capitalize } from 'lodash';

import { I18n } from 'react-redux-i18n';
import CustomSelect from '../../reusable/Selects/Select';
import RadioButton from '../../reusable/FormComponents/RadioButton';
import DateTimeSelect from '../../reusable/Selects/DateTimeSelect';
import Checkbox from '../../reusable/FormComponents/Checkbox';
import useAvailableTests from '../../../hooks/useAvailableTests';

import { ReactComponent as QuestionIcon } from '../../../assets/icons/question-mark.svg';

import createTestsOptions from './createInviteUtils';

const AssessmentStep = ({
  language,
  assessment,
  languages,
  assessments,
  onValueChange,
  expiryDate,
  welcomeText,
  welcomeTexts,
  biodataTemplate,
  biodataTemplates,
  categories,
  activeCategory,
  isDirectReportAvailable,
  isDirectReportChecked,
  toggleDirectReportStatus,
}) => {
  const availableAssessments = useAvailableTests({
    assessments,
    language,
  });

  const assessmentsOptions = useMemo(() => {
    return createTestsOptions(availableAssessments);
  }, [availableAssessments]);

  const onLanguageChange = (data) => {
    onValueChange('language', data);
  };

  const onAssessmentValueChange = (item) => {
    onValueChange('assessment', item);
  };

  const onBiodataTemplateChange = (item) => {
    onValueChange('biodataTemplate', item);
  };

  const onWelcomeTextChange = (item) => {
    onValueChange('welcomeText', item);
  };

  const onCategoryChange = (option) => {
    onValueChange('category', option.value);
  };

  const onExpiryDateChange = (date) => {
    onValueChange('expiryDate', date);
  };
  const categoriesOptions = categories.map((category) => ({ name: I18n.t(category), value: category }));
  const activeCategoryOption = { name: activeCategory, value: activeCategory };
  return (
    <AssessmentContainer>
      <StyledCustomSelect
        options={languages}
        placeholder={I18n.t('Language')}
        selectProps={{ value: language, onChange: onLanguageChange }}
      />
      <Categories>
        {categoriesOptions.map((item) => (
          <RadioOption
            key={item.value}
            isActive={item.value === activeCategoryOption.value}
            onClick={() => onCategoryChange(item)}
          >
            <RadioButton isChecked={item.value === activeCategoryOption.value} readOnly />
            <span>{item.name}</span>
          </RadioOption>
        ))}
      </Categories>
      <StyledCustomSelect
        options={assessmentsOptions}
        placeholder={activeCategoryOption.name ? capitalize(I18n.t(activeCategoryOption.name)) : ''}
        selectProps={{ value: assessment, onChange: onAssessmentValueChange }}
      />
      <InfoSelectWrapper>
        <StyledCustomSelect
          options={biodataTemplates}
          placeholder={I18n.t('Bio data template')}
          selectProps={{ value: biodataTemplate, onChange: onBiodataTemplateChange }}
          isDisabled={biodataTemplates.length === 0}
        />
        <StyledQuestionIcon data-tip data-for="biodata" />
        <StyledTooltip id="biodata">{I18n.t('biodataTooltip')}</StyledTooltip>
      </InfoSelectWrapper>
      <StyledCustomSelect
        options={welcomeTexts}
        placeholder={I18n.t('Welcome text')}
        selectProps={{ value: welcomeText, onChange: onWelcomeTextChange }}
      />
      <DateTimeSelect
        date={expiryDate}
        onDateChange={onExpiryDateChange}
        inputName={I18n.t('Expiry date')}
        minDate={new Date()}
      />
      {isDirectReportAvailable && (
        <CheckboxHolder onClick={toggleDirectReportStatus}>
          <Checkbox isChecked={isDirectReportChecked} />
          <span>{I18n.t('Send immediate feedback report to respondent')}</span>
          <QuestionIcon data-tip data-for="info" />
          <StyledTooltip id="info">{I18n.t('Send immediate feedback info')}</StyledTooltip>
        </CheckboxHolder>
      )}
    </AssessmentContainer>
  );
};

AssessmentStep.propTypes = {
  onValueChange: func.isRequired,
  languages: array.isRequired,
  language: object,
  assessment: object,
  assessments: array,
  expiryDate: instanceOf(Date),
  welcomeText: object,
  welcomeTexts: array.isRequired,
  biodataTemplate: object,
  biodataTemplates: array.isRequired,
  activeCategory: string,
  categories: array.isRequired,
  isDirectReportAvailable: bool.isRequired,
  isDirectReportChecked: bool.isRequired,
  toggleDirectReportStatus: func.isRequired,
};

AssessmentStep.defaultProps = {
  expiryDate: null,
  assessment: null,
  language: null,
  welcomeText: null,
  biodataTemplate: null,
  activeCategory: null,
  assessments: [],
};

const AssessmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  align-self: center;
  width: 50%;
`;

const Categories = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
`;

const RadioOption = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;
  user-select: none;
  margin-bottom: 0.5rem;

  :hover {
    cursor: pointer;
  }

  span {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: bold;
    margin-left: 0.5rem;
    color: ${(props) => (props.isActive ? props.theme.colors.lightBlue : props.theme.colors.darkBlue2)};
  }
`;

const CheckboxHolder = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  span {
    margin: 0 1rem;
    font-size: 1.4rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.darkBlue2};
  }

  :hover {
    cursor: pointer;
  }
`;

const StyledTooltip = styled(ReactTooltip)`
  width: 30rem;
`;

const StyledCustomSelect = styled(CustomSelect)`
  width: 95%;
`;
const StyledQuestionIcon = styled(QuestionIcon)`
  margin-bottom: 20px;
`;

const InfoSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default AssessmentStep;
