import React from 'react';
import { instanceOf, func } from 'prop-types';
import styled from 'styled-components';

import { I18n } from 'react-redux-i18n';
import DateTimeSelect from '../reusable/Selects/DateTimeSelect';

import NewModal from '../reusable/NewModal';
import CustomButton from '../reusable/Buttons/Button';

const ResetExpiryDate = ({ expiryDate, onClose, onConfirm, onValueChange }) => {
  const onExpiryDateChange = (date) => {
    onValueChange(date);
  };
  return (
    <Modal isVisible onClose={onClose} title={I18n.t('Reset assessment deadline')}>
      <Form>
        <DateTimeSelect
          date={expiryDate}
          onDateChange={onExpiryDateChange}
          inputName={I18n.t('Expiry date')}
          minDate={new Date()}
        />
      </Form>
      <ButtonsWrapper>
        <StyledButton variant="secondary" onClick={onClose}>
          {I18n.t('Cancel')}
        </StyledButton>
        <StyledButton type="button" variant="primary" onClick={onConfirm}>
          {I18n.t('Update')}
        </StyledButton>
      </ButtonsWrapper>
    </Modal>
  );
};

ResetExpiryDate.propTypes = {
  expiryDate: instanceOf(Date),
  onClose: func.isRequired,
  onConfirm: func.isRequired,
  onValueChange: func.isRequired,
};

ResetExpiryDate.defaultProps = {
  expiryDate: null,
};

const Modal = styled(NewModal)`
  display: flex;
  flex-direction: column;
  min-height: 26rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(CustomButton)`
  margin-left: 1rem;
  width: 12.5rem;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export default ResetExpiryDate;
