import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import copy from 'copy-to-clipboard';
import { I18n } from 'react-redux-i18n';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { LicenseManager } from '@ag-grid-enterprise/core';
import RatersTableWrapper from '../components/RatersTableWrapper';
import apiInstance2 from '../../../services/apiService';
import invitesColumns from './columns';
import { deleteInvites } from '../../../store/invites/actions';
import * as actions from '../../../table/actionsList';
import ConfirmationModal from '../../reusable/ConfirmationModal';
import BulkActionsSidebar from '../../BulkActionsSidebar';
import useBulkSidebarState from '../../../hooks/useBulkSidebarState';
import invitesBulkActions from '../../../table/bulkActions/invites';
import { createCopiedNotification } from '../../Invites/Invites';
import inviteStatuses from '../../../constants/inviteStatuses';

ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, SetFilterModule]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

const RatersInvites = ({ projectId, sessionId }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);
  const [selectedInvites, setSelectedInvites] = useState([]);

  const [invites, setInvites] = useState([]);
  const [inviteToDelete, setInviteToDelete] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const deleteInvitesCallback = useCallback((data, cb) => dispatch(deleteInvites(data, cb)), [dispatch]);

  const [isConfirmationModalVisible, setConfirmationModalVisibilityStatus] = useState(false);
  const openConfirmationModal = () => setConfirmationModalVisibilityStatus(true);

  const closeConfirmationModal = () => {
    if (inviteToDelete) setInviteToDelete(null);
    setConfirmationModalVisibilityStatus(false);
  };

  const fetchInvites = () => {
    setLoading(true);
    apiInstance2
      .get(`/api/v2/360/projects/${projectId}/sessions/${sessionId}/invites`)
      .then((response) => {
        setLoading(false);
        setInvites(response.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onDeleteConfirm = () => {
    const data = inviteToDelete
      ? [{ inviteID: inviteToDelete.inviteID, inviteType: inviteToDelete.inviteType }]
      : selectedInvites.map((item) => ({ inviteID: item.inviteID, inviteType: item.inviteType }));

    deleteInvitesCallback(data, () => {
      closeConfirmationModal();
      fetchInvites();
    });
  };

  useEffect(() => {
    fetchInvites();
  }, []);

  // columns settings
  const columns = useMemo(() => {
    return invitesColumns();
  }, [user]);

  const onChange = useCallback((data) => {
    setSelectedInvites(data);
  }, []);

  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedInvites });

  // BULK ACTIONS
  const bulkActionsHandlers = {
    [actions.DELETE_INVITE]: openConfirmationModal,
    [actions.COPY_LINK]: () => {
      copy(selectedInvites[0].downloadLink);
      createCopiedNotification();
    },
  };

  const bulkActions = useMemo(() => {
    return invitesBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedInvites) : item.isDisabled,
    }));
  }, [selectedInvites, user]);

  const agGridDefaultSettings = {
    pagination: true,
    paginationPageSize: 50,
    paginationPageSizeSelector: [10, 20, 50],
    rowHeight: '58',
    headerHeight: '58',
    domLayout: 'autoHeight',
    gridOptions: { popupParent: document.body },
    onSelectionChanged: (event) => {
      const selectedData = [];
      event.api.forEachNodeAfterFilter((node) => {
        if (node.isSelected()) {
          selectedData.push(node.data);
        }
      });
      setSelectedInvites(selectedData);
    },
  };

  const agGriddefaultColumnDefinition = {
    sortable: true,
  };

  const [columnDefinition] = useState([
    {
      field: 'assessment',
      headerName: I18n.t('Name'),
      celldatatype: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
      valueGetter: ({ data }) => `${data.firstName} ${data.familyName}`,
    },
    {
      field: 'email',
      headerName: I18n.t('Email Address'),
      celldatatype: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
    },
    {
      field: 'invitationStatus',
      headerName: I18n.t('Invite status'),
      celldatatype: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
      valueGetter: ({ data }) => I18n.t(inviteStatuses[data.invitationStatus]),
    },
    {
      field: 'expiryDate',
      headerName: I18n.t('Expiry Date'),
      cellDataType: 'date',
      flex: 1,
      filter: 'agDateColumnFilter',
      sort: 'desc',
      valueGetter: (params) => {
        return new Date(params.data.expiryDate);
      },
    },
  ]);

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
    };
  }, []);

  const handleFilterChanged = (params) => {
    params.api.deselectAll();
    params.api.refreshHeader();
  };

  return (
    <RatersTableWrapper projectId={projectId} sessionId={sessionId} activeTab={1}>
      <BulkActionsSidebar actions={bulkActions} onClose={closeSidebar} isOpen={isSidebarOpen} />
      <div className="ag-theme-quartz ag-grid-custom-styles">
        <AgGridReact
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          rowData={isLoading ? null : invites}
          rowSelection={rowSelection}
          onFilterChanged={handleFilterChanged}
        />
      </div>
      <ConfirmationModal
        title={I18n.t('Invite Deletion')}
        onClose={closeConfirmationModal}
        isVisible={isConfirmationModalVisible}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selectedInvites.length > 0 ? I18n.t('selected invite(s)?') : I18n.t('chosen invite(s)?')
        }`}
        onConfirm={onDeleteConfirm}
        caution
      />
    </RatersTableWrapper>
  );
};

export default RatersInvites;
