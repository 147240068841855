import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import { I18n } from 'react-redux-i18n';
import styled from 'styled-components';

import PageWrapper from '../../PageWrapper';
import TableTabs from '../../reusable/TableTabs';

import accountTabs from './accountTabs';

import CustomButton from '../../reusable/Buttons/Button';
import ToggleButton from '../../reusable/Buttons/ToggleButton';

const RespondentPersonalInfo = () => {
  const dispatch = useDispatch();
  const goBackToSettings = () => dispatch(push('/settings'));
  // const [shouldResetPage, setShouldReset] = useState(false);

  const server = 'CH';
  let userOptions = [
    { label: 'First Name', isDisabled: true, isChecked: true },
    { label: 'Family Name', isDisabled: false, isChecked: false },
    { label: 'Sex', isDisabled: false, isChecked: false },
    { label: 'Date of Birth', isDisabled: false, isChecked: false },
    { label: 'Reference', isDisabled: false, isChecked: false },
  ];

  if (server === 'CH') {
    userOptions = [
      { label: 'Full Name', isDisabled: true, isChecked: true },
      { label: 'Sex', isDisabled: true, isChecked: false },
      { label: 'Date of Birth', isDisabled: true, isChecked: false },
    ];
  }

  const onPageTabClick = (tab) => dispatch(push(tab.path));

  const handleSubmit = () => {};

  const logState = (state) => {
    console.log('Toggled:', state);
  };

  return (
    <PageWrapper title={I18n.t('Respondents')} backButtonHandler={goBackToSettings}>
      <TableTabs tabs={accountTabs} activeTab={accountTabs[0]} clickHandler={onPageTabClick} />
      <ContentWrapper>
        <PseudoInput>
          <Label>
            {I18n.t('Toggle to show/hide the options from the respondents invitions personal information page')}
          </Label>
        </PseudoInput>
        {userOptions.map(({ label, isChecked, isDisabled }) => (
          <PseudoInput key={label}>
            <Row>
              <ToggleButton disabled={isDisabled} toggled={isChecked} onClick={logState} />
              <Value>{I18n.t(`${label}`)}</Value>
            </Row>
          </PseudoInput>
        ))}
        <PseudoInput>
          <ButtonsWrapper>
            <StyledButton disabled={false} onClick={handleSubmit}>
              {I18n.t('Save')}
            </StyledButton>
          </ButtonsWrapper>
        </PseudoInput>
      </ContentWrapper>
    </PageWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PseudoInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.4rem;
`;

const Label = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.primary};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Value = styled.span`
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.darkBlue2};
  font-weight: 600;
  margin: 0 10px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(CustomButton)`
  text-transform: uppercase;
`;

RespondentPersonalInfo.propTypes = {};

export default RespondentPersonalInfo;
