import { set, nextMonday } from 'date-fns';
import getDateFromNow from '@utils/getDateFromNow';
import handleActions from '../immerHandleActions';
import * as actions from './actions';
import {
  DATE_FILTER_TYPES,
  datesFilterOptions,
  NUMBER_FILTER_TYPES,
  numberFilterOptions,
  TEXT_FILTER_TYPES,
  textFilterOptions,
} from '../../constants/advancedFilterOptions';
import projectsColumns from '../../table/columns/projects';

const getInitialFilterValue = (type) => {
  if (type === 'number') return numberFilterOptions.find((item) => item.name === NUMBER_FILTER_TYPES.IS_EQUAL);
  if (type === 'date') return datesFilterOptions.find((item) => item.name === DATE_FILTER_TYPES.THREE_DAYS);
  return textFilterOptions.find((item) => item.name === TEXT_FILTER_TYPES.CONTAINS);
};

const PROJECTS_FILTER_FIELDS = projectsColumns
  .filter((item) => item.name !== 'actions')
  .filter((item) => !item.disableFilterBy)
  .map((column) => ({ name: column.name, type: column.type, filteringUiName: column.filteringUiName }));

const initialInputsState = PROJECTS_FILTER_FIELDS.map((fieldObj) => ({
  ...fieldObj,
  name: fieldObj.name,
  value: fieldObj.type === 'date' ? null : '',
  type: fieldObj.type,
  filterType: getInitialFilterValue(fieldObj.type),
}));

const initialFilters = {
  isApplied: false,
  dirty: false,
  appliedInputs: initialInputsState,
  inputs: initialInputsState,
};

const initialCreateAssessmentProjectState = {
  info: {
    projectName: '',
    projectDescription: '',
  },
  assessment: {
    language: null,
    category: null,
    assessment: null,
  },
  invites: {
    template: null,
    welcomeText: null,
    expiryDate: getDateFromNow(30, 'day_end'),
    reminderType: null,
    sendingType: null,
    invitationDate: set(nextMonday(new Date()), { hours: 9, minutes: 0, seconds: 0 }),
    isDirectReport: false,
    biodataTemplate: null,
  },
  reports: {
    language: null,
    reports: [],
    readyToBeSentReports: [],
    idealProfiles: {},
    norms: {},
  },
};

const initialAssessmentProjectState = {
  respondents: [],
  selectedRespondents: [],
  search: '',
  pagesAvailable: 0,
  totalCount: 0,
  lastPageIndex: null,
  hash: '',
};

const initialState = {
  projects: [],
  selectedProjects: [],
  activeProjectInView: null,
  search: '',
  pagesAvailable: 0,
  totalCount: 0,
  totalAssessment: 0,
  total360: 0,
  lastPageIndex: null,
  filter: initialFilters,
  activeTab: { name: 'all', label: 'All' },
  competencyGroups: [],
  createAssessmentProject: initialCreateAssessmentProjectState,
  assessmentProject: initialAssessmentProjectState,
};

const projectsReducer = handleActions(
  {
    // FILTERS PART
    [actions.setProjectsSearchValue]: (draft, { payload: { searchValue } }) => {
      draft.search = searchValue;
    },
    [actions.setProjectsFilterValue]: (draft, { payload: { fieldName, value } }) => {
      draft.filter.inputs = draft.filter.inputs.map((item) => (item.name === fieldName ? { ...item, value } : item));
      draft.filter.dirty = true;
    },
    [actions.setProjectsFilterType]: (draft, { payload: { fieldName, filterType } }) => {
      const initValue = getInitialFilterValue(
        projectsColumns.find((item) => item.name.toLowerCase() === fieldName.toLowerCase()).type,
      );
      draft.filter.inputs = draft.filter.inputs.map((item) =>
        item.name === fieldName ? { ...item, filterType: filterType === 'initial' ? initValue : filterType } : item,
      );
      draft.filter.dirty = true;
    },
    [actions.applyProjectsFilters]: (draft) => {
      draft.filter.dirty = false;
      draft.filter.isApplied = true;
      draft.filter.appliedInputs = draft.filter.inputs;
    },
    [actions.clearProjectsFilters]: (draft) => {
      draft.filter.dirty = true;
      draft.filter.inputs = initialInputsState;
    },
    [actions.deleteProjectsAppliedFilter]: (draft, { payload: { fieldName } }) => {
      draft.filter.appliedInputs = draft.filter.appliedInputs.map((input) =>
        input.name === fieldName ? { ...input, value: input.type === 'date' ? null : '' } : input,
      );
      draft.filter.inputs = draft.filter.inputs.map((input) =>
        input.name === fieldName ? { ...input, value: input.type === 'date' ? null : '' } : input,
      );
    },
    [actions.resetProjectsTableState]: (draft) => {
      draft.search = '';
      draft.filter = { ...initialFilters };
      draft.shouldResetTableState = true;
    },
    // END OF FILTERS, EVERYTHING ELSE
    [actions.fetchProjectsSuccess]: (draft, { payload: { data, shouldReset } }) => {
      draft.projects = shouldReset ? data.data : [...draft.projects, ...data.data];
      draft.totalCount = data.totalCount;
      draft.totalAssessment = data.totalAssessment;
      draft.total360 = data.total360;
      draft.pagesAvailable = data.pagesAvailable;
      draft.lastPageIndex = data.pageNumber;
    },
    [actions.setSelectedProjects]: (draft, { payload: { projects } }) => {
      draft.selectedProjects = projects;
    },
    [actions.deleteProjectsSuccess]: (draft, { payload: { projects } }) => {
      draft.projects = draft.projects.filter(
        (item) => !projects.find((project) => project.projectId === item.projectId),
      );
    },
    [actions.setActiveProject]: (draft, { payload: { project } }) => {
      draft.activeProjectInView = project;
    },
    [actions.fetchCompetencyGroupsSuccess]: (draft, { payload: { data } }) => {
      draft.competencyGroups = data.data.map((g) => ({ label: g.name, value: g.competencyGroupID, ...g }));
    },
    [actions.saveProjectInfoData]: (draft, { payload: { values } }) => {
      draft.createAssessmentProject.info = { ...draft.createAssessmentProject.info, ...values };
    },
    [actions.saveProjectAssessmentData]: (draft, { payload: { values } }) => {
      draft.createAssessmentProject.assessment = { ...draft.createAssessmentProject.assessment, ...values };
    },
    [actions.saveProjectInvitesData]: (draft, { payload: { values } }) => {
      draft.createAssessmentProject.invites = { ...draft.createAssessmentProject.invites, ...values };
    },
    [actions.saveProjectReportsData]: (draft, { payload: { values } }) => {
      draft.createAssessmentProject.reports = { ...draft.createAssessmentProject.reports, ...values };
    },
    [actions.resetCreateAssessmentProjectData]: (draft) => {
      draft.createAssessmentProject = { ...initialCreateAssessmentProjectState };
    },
    [actions.resetAssessmentProjectData]: (draft) => {
      draft.assessmentProject = { ...initialAssessmentProjectState };
    },
    [actions.fetchAssessmentProjectRespondentsSuccess]: (draft, { payload: { data, shouldReset } }) => {
      draft.assessmentProject.respondents = shouldReset
        ? data.data
        : [...draft.assessmentProject.respondents, ...data.data];
      draft.assessmentProject.totalCount = data.totalCount;
      draft.assessmentProject.pagesAvailable = data.pagesAvailable;
      draft.assessmentProject.lastPageIndex = data.pageNumber;
    },
    [actions.setAssessmentProjectSelectedRespondents]: (draft, { payload: { respondents } }) => {
      draft.assessmentProject.selectedRespondents = respondents;
    },
    [actions.setAssessmentProjectSearchValue]: (draft, { payload: { searchValue } }) => {
      draft.assessmentProject.search = searchValue;
    },
    [actions.assessmentProjectHashCheckSuccess]: (draft, { payload: { hash } }) => {
      draft.assessmentProject.hash = hash;
    },
    [actions.setAssessmentProjectHash]: (draft, { payload: { hash } }) => {
      draft.assessmentProject.hash = hash;
    },
  },
  initialState,
);

export default projectsReducer;
