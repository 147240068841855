import React, { useState, useMemo } from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { LicenseManager } from '@ag-grid-enterprise/core';
import BulkActionsSidebar from '../BulkActionsSidebar';
import respondentTestsColumns from '../../table/columns/respondentTests';
import ConfirmationModal from '../reusable/ConfirmationModal';
import RequestReportModal from '../Respondents/RequestReportModal';
import { selectRespondentTests } from '../../store/respondents/selectors';
import * as actions from '../../table/actionsList';
import respondentTestsBulkActions from '../../table/bulkActions/respondentTests';
import useBulkSidebarState from '../../hooks/useBulkSidebarState';
import useModals from '../../hooks/useModals';
import { resolveTestName } from '../../utils/assessmentsNames';

ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, SetFilterModule]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

const RespondentTests = ({ deleteTests }) => {
  const [selectedTests, setSelectedTests] = useState([]);
  const respondentToEdit = useSelector((state) => state.respondents.respondentToEdit);
  const tests = useSelector(selectRespondentTests);

  const modals = [actions.DELETE_COMPLETED_TEST, actions.REQUEST_REPORT];
  const { modalsState, openModal, closeModal } = useModals(modals);

  const onDeleteConfirm = () => {
    deleteTests(
      selectedTests.map((item) => item.resultID),
      () => {
        closeModal(actions.DELETE_COMPLETED_TEST);
      },
    );
  };

  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedTests });

  // BULK ACTIONS
  const bulkActionsHandlers = {
    [actions.REQUEST_REPORT]: () => openModal(actions.REQUEST_REPORT),
    [actions.DELETE_COMPLETED_TEST]: () => openModal(actions.DELETE_COMPLETED_TEST),
  };
  const bulkActions = useMemo(() => {
    return respondentTestsBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedTests) : item.isDisabled,
    }));
  }, [selectedTests]);

  const agGridDefaultSettings = {
    pagination: true,
    paginationPageSize: 50,
    paginationPageSizeSelector: [10, 20, 50],
    rowHeight: '58',
    headerHeight: '58',
    domLayout: 'autoHeight',
    gridOptions: { popupParent: document.body },
    onSelectionChanged: (event) => {
      const selectedData = [];
      event.api.forEachNodeAfterFilter((node) => {
        if (node.isSelected()) {
          selectedData.push(node.data);
        }
      });
      setSelectedTests(selectedData);
    },
  };

  const agGriddefaultColumnDefinition = {
    sortable: true,
  };

  const [columnDefinition] = useState([
    {
      field: 'name',
      headerName: I18n.t('Assessment'),
      cellDataType: 'text',
      flex: 1,
      filter: 'agSetColumnFilter',
      valueGetter: ({ data }) => resolveTestName(data),
    },
    {
      field: 'langID',
      headerName: I18n.t('Language'),
      cellDataType: 'text',
      flex: 1,
      filter: 'agSetColumnFilter',
    },
    {
      field: 'completionDate',
      headerName: I18n.t('Date'),
      cellDataType: 'date',
      flex: 1,
      filter: 'agDateColumnFilter',
      sort: 'desc',
      valueGetter: (params) => {
        return new Date(params.data.completionDate);
      },
    },
  ]);

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
    };
  }, []);

  const handleFilterChanged = (params) => {
    params.api.deselectAll();
    params.api.refreshHeader();
  };

  return (
    <RespondentTestsContainer>
      <BulkActionsSidebar actions={bulkActions} onClose={closeSidebar} isOpen={isSidebarOpen} />
      <div className="ag-theme-quartz ag-grid-custom-styles">
        <AgGridReact
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          rowData={tests}
          rowSelection={rowSelection}
          onFilterChanged={handleFilterChanged}
        />
      </div>
      <ConfirmationModal
        title={I18n.t('Test deletion')}
        onClose={() => closeModal(actions.DELETE_COMPLETED_TEST)}
        isVisible={modalsState[actions.DELETE_COMPLETED_TEST]}
        description={I18n.t('Are you sure you want to delete selected test?')}
        onConfirm={onDeleteConfirm}
        caution
      />
      {modalsState[actions.REQUEST_REPORT] && (
        <RequestReportModal
          onClose={() => closeModal(actions.REQUEST_REPORT)}
          respondents={[{ ...respondentToEdit, doneTests: selectedTests }]}
        />
      )}
    </RespondentTestsContainer>
  );
};

RespondentTests.propTypes = {
  deleteTests: func.isRequired,
};

const RespondentTestsContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

export default RespondentTests;
