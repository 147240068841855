const languagesMap = {
  MX: 'Mixed',
  EN: 'English',
  IT: 'Italiano / Italian',
  ES: 'Español / Spanish (Castellano)',
  'ES-LATAM': 'Español / Spanish (Latam)',
  'CH-PRC': '中文(简体) / Chinese (Simplified)',
  CHTR: '中文(繁體) / Chinese (Traditional)',
  AR: 'العربية / Arabic',
  DK: 'Dansk / Danish',
  TR: 'Türkçe / Turkish',
  IN: 'Bahasa Indonesia / Indonesian',
  TH: 'ภาษาไทย / Thai',
  UA: 'Yкраїнська / Ukrainian',
  PL: 'Polski / Polish',
  PT: 'Português / Portuguese',
  'PT-BR': 'Português Brasileiro / Portuguese Brazil',
  DE: 'Deutsch / German',
  DU: 'Nederlands / Dutch',
  FR: 'Français / French',
  GR: 'ελληνικά / Greek',
  KO: '한국어 / Korean',
  NO: 'Norsk / Norwegian',
  BG: 'Български / Bulgarian',
  RO: 'Rumunski / Romanian',
  SR: 'Cрпски / Serbian',
  BS: 'Bosanski / Bosnian',
  ME: 'Crnogorski / Montenegrian',
  HU: 'Mađarski / Hungarian',
  HR: 'Hrvatski / Croatian',
  MK: 'Македонски / Macedonian',
  JP: '日本語 / Japanese',
  MN: 'Mongolian / Mongolian',
  SL: 'Slovenački / Slovenian',
  HE: 'עִבְרִית / Hebrew',
  AF: 'Afrikaans',
  AZ: 'Azərbaycan dili / Azerbaijani',
  CZ: 'Češki / Czech',
  VN: 'Tiếng Việt / Vietnamese',
  MY: 'Melayu / Malay',
  HI: 'हिन्दी/ Hindi',
  UR: 'اردو / Urdu',
  FL: 'Tagalog / Filipino',
  RU: 'Pусский / Russian',
  GB: 'Welsh',
  MT: 'Maltese',
  IR: 'Farsi',
  SE: 'Swedish'
};

export default languagesMap;