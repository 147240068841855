import { subMinutes } from 'date-fns';
import { I18n } from 'react-redux-i18n';
import { createAction } from 'redux-actions';
import apiInstance2 from '../../services/apiService';
import createToastNotification from '../../utils/createToastNotification';

export const deleteNormsStart = createAction('DELETE_NORM_START');
export const deleteNormsSuccess = createAction('DELETE_NORM_SUCCESS');
export const deleteNormsFailure = createAction('DELETE_NORM_FAILURE', (err) => ({ err }));

/**
 * Delete norms
 * @norms {Array[normId]}
 */
export const deleteNorms = (norms, cb) => async (dispatch) => {
  try {
    dispatch(deleteNormsStart());
    const response = await apiInstance2.delete('/api/v2/norms', { data: norms });
    if (response.status === 200) {
      dispatch(deleteNormsSuccess());
      if (cb) cb();
    }
  } catch (err) {
    dispatch(deleteNormsFailure(`Cannot delete norm(s).`));
    if (cb) cb();
  }
};

export const createNormsStart = createAction('CREATE_NORM_START');
export const createNormsSuccess = createAction('CREATE_NORM_SUCCESS');
export const createNormsFailure = createAction('CREATE_NORM_FAILURE', (err) => ({ err }));

/**
 * Add new norm
 * @data {Object}
 */
export const addNewNorm = (data, cb) => async (dispatch) => {
  try {
    dispatch(createNormsStart());
    const response = await apiInstance2.post('/api/v2/norms', {
      testID: data.testId,
      name: data.name,
      description: data.description,
      normType: data.normType,
      ...(data.sampleSize && { sampleSize: data.sampleSize }),
      ...(data.groupIds && { groupIds: data.groupIds }),
      ...(data.countryOrigin && { countryOrigin: data.countryOrigin }),
      ...(data.language && { language: data.language }),
    });
    if (response.status === 200) {
      dispatch(createNormsSuccess());
      createToastNotification({ message: I18n.t('normCreationToast') });
      if (cb) cb();
    }
  } catch (err) {
    dispatch(createNormsFailure('Unable to create norm. Please try again.'));
    createToastNotification({ message: `${I18n.t('normCreationErrorToast')}.`, type: 'error' });
    if (err?.response?.status === 422) cb(err.response.data);
    else cb(err.message || err.msg);
  }
};

export const fetchBriefGroupsStart = createAction('FETCH_BRIEF_GROUPS_START');
export const fetchBriefGroupsSuccess = createAction('FETCH_BRIEF_GROUPS_SUCCESS', (groups) => ({ groups }));
export const fetchBriefGroupsFailure = createAction('FETCH_BRIEF_GROUPS_FAILURE', (err) => ({ err }));

/**
 * Fetch groups ids
 */
export const fetchBriefGroups = () => async (dispatch) => {
  try {
    dispatch(fetchBriefGroupsStart());
    const response = await apiInstance2.get('api/v2/brief-groups');
    if (response.status === 200) {
      dispatch(fetchBriefGroupsSuccess(response.data));
    }
  } catch (err) {
    dispatch(fetchBriefGroupsFailure('Cannot fetch groups'));
  }
};

export const fetchGroupResults = ({ groupId, testId }, cb) => async () => {
  try {
    const response = await apiInstance2.get(`/api/v2/norms/${groupId}/${testId}`);
    if (response.status === 200) {
      if (cb) cb(true, response.data);
    }
  } catch (err) {
    if (cb) cb(false, err);
  }
};

export const markNormsNotificationAsViewed = (reportsIds) => async () => {
  try {
    const response = await apiInstance2.post(`/api/v2/Notifications/viewed`, reportsIds);
    if (response.status === 200) {
      return true;
    }
  } catch (e) {
    return false;
  }
};

export const fetchNormChanges = (navigate) => async (dispatch) => {
  try {
    const currentDate = new Date();
    const from = subMinutes(currentDate, 4).toISOString();
    const to = currentDate.toISOString();
    const response = await apiInstance2.get(
      `/api/v2/Notifications/norm-notifications?fromDateTime=${from}&toDateTime=${to}`,
    );
    if (response.status === 200) {
      if (response.data.length) {
        response.data.forEach((notification) => {
          const options =
            typeof notification.dissapear === 'number'
              ? {
                  autoClose: !notification.dissapear ? false : notification.dissapear,
                }
              : {};

          options.onClose = () => {
            dispatch(markNormsNotificationAsViewed([notification.notificationID]));
            if (notification.normID) {
              navigate(`/settings/norms/${notification.normID}`);
            }
          };
          createToastNotification({
            notification,
            message: I18n.t('notificationNormCreated', { name: notification.normName }),
            options,
          });
        });
      }
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
};
