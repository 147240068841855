import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { push } from 'redux-first-history';

import { I18n } from 'react-redux-i18n';

import PageWrapper from '../../PageWrapper';

import Logo from '../../reusable/Logo';

const About = () => {
  const dispatch = useDispatch();

  const goBackToSettings = () => dispatch(push('/settings'));

  return (
    <StyledPageWrapper title={I18n.t('About')} backButtonHandler={goBackToSettings}>
      <Logo />
      <PreviewTitle>
        <Column>
          <StyledText dangerouslySetInnerHTML={{ __html: I18n.t('ownershipTitle') }} />
          <StyledText dangerouslySetInnerHTML={{ __html: I18n.t('ownershipBody') }} />
        </Column>
      </PreviewTitle>
    </StyledPageWrapper>
  );
};

About.propTypes = {};

const StyledPageWrapper = styled(PageWrapper)`
  width: 90%;
`;

const PreviewTitle = styled.div`
  display: block;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.darkBlue};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;

  span:first-child {
    font-size: ${(props) => props.theme.fontSizes.small};
    color: ${(props) => props.theme.colors.darkBlue};
    font-weight: 600;
    margin-bottom: 1rem;
  }

  span {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.fontSizes.small};
    font-weight: 500;
  }
`;

const StyledText = styled.span`
  text-align: justify;
`;

export default About;
