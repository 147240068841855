import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CustomBadge from '../Badges/CustomBadge';
import InbuiltBadge from '../Badges/InbuiltBadge';
import addOrRemove from '../../../utils/addOrRemove';
import { ReactComponent as ArrowUp } from '../../../assets/icons/blue-arrow-up-big.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/blue-arrow-down-big.svg';
import AnimateHeightWrapper from '../AnimateHeight';
import ActionsDropdown from '../ActionsDropdown';
import AccordionNestedList from './AccordionPanelListDND';
import { PanelTitle } from './stylesDND';
import dragAdnDropUtils from './utils';

const getLanguages = (scale) => {
  const languages = scale?.languages;

  if (languages?.length < 2) {
    return '';
  }

  return `[${languages.map((language) => language.langID).join(', ')}]`;
};

const TitleIcon = ({ opened }) => {
  return <TitleIconButton>{opened ? <ArrowUp /> : <ArrowDown />}</TitleIconButton>;
};

const AccordionDND = ({ expandAll, data, isLoading }) => {
  const [accordionData, setAccordionData] = useState([...data]);
  const [openedPanels, setOpenedPanels] = useState([]);

  useEffect(() => {
    if (!isLoading && data.length) {
      // INFO: Implement ability to switch competency order
      setAccordionData([
        ...data.filter((competency) => competency.competencyType === 1),
        ...data.filter((competency) => competency.competencyType === 0),
      ]);
    } else {
      setAccordionData(data);
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (expandAll) {
      setOpenedPanels(accordionData.map((item) => item.competencyID));
    } else {
      setOpenedPanels([]);
    }
  }, [expandAll]);

  const onPanelClick = (id) => {
    setOpenedPanels(addOrRemove(openedPanels, id));
  };

  // Moves Competency from one list to another list
  const moveCompetency = (source, destination, droppableSource, droppableDestination) => {
    const movedCategory = { ...source.categories[droppableSource.index] };
    const sourceObj = { ...source };
    const newSourceCategories = sourceObj.categories.filter((el, index) => index !== droppableSource.index);
    const newSource = { ...sourceObj, categories: newSourceCategories };

    const destObj = { ...destination };
    const newDestCategories = [
      ...destObj.categories.slice(0, droppableDestination.index),
      movedCategory,
      ...destObj.categories.slice(droppableDestination.index),
    ];
    const newDest = { ...destObj, categories: newDestCategories };

    return {
      source: { ...newSource },
      destination: { ...newDest },
    };
  };

  const getCategoryByID = (id) => accordionData.find(({ competencyID }) => competencyID === +id);
  const getCategoryIndex = (id) => accordionData.findIndex((elem) => elem.competencyID === +id);
  const getSourceIndexes = (id) => {
    let sourceIndexes = {};
    accordionData.forEach((category, categoryIndex) => {
      category.categories.forEach((competency, competencyIndex) => {
        competency.competencyItems.forEach((item) => {
          if (item.itemID === +id) {
            sourceIndexes = {
              sourceCategoryIndex: categoryIndex,
              sourceCompetencyIndex: competencyIndex,
            };
          }
        });
      });
    });
    return sourceIndexes;
  };
  const getDestinationIndexes = (id) => {
    let destinationIndexes = {};
    accordionData.forEach((category, categoryIndex) => {
      category.categories.forEach((competency, competencyIndex) => {
        if (competency.competencyID === +id) {
          destinationIndexes = {
            destinationCategoryIndex: categoryIndex,
            destinationCompetencyIndex: competencyIndex,
          };
        }
      });
    });
    return destinationIndexes;
  };

  const onDragEnd = (result) => {
    const { source, destination, draggableId, type } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    // dropped on the same position
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    if (type === 'category') {
      if (accordionData[destination.index].competencyType !== accordionData[source.index].competencyType) {
        // Category was dropped to other type categories
        return;
      }
      const result = dragAdnDropUtils.reorder(accordionData, source.index, destination.index);
      setAccordionData([...result]);
    }

    if (type === 'competency') {
      const sourceId = dragAdnDropUtils.getIdByType(source.droppableId, 'category');
      const destinationId = dragAdnDropUtils.getIdByType(destination.droppableId, 'category');

      if (getCategoryByID(sourceId).competencyType && !getCategoryByID(destinationId).competencyType) {
        // Custom competency was dropped to INBUILT category
        return;
      }
      if (!getCategoryByID(sourceId).competencyType && destination.droppableId !== source.droppableId) {
        // INBUILT competency was dropped to another category
        return;
      }
      if (source.droppableId === destination.droppableId) {
        // Competency was dropped to the same Category
        const category = getCategoryByID(sourceId);
        const sourceIndex = getCategoryIndex(sourceId);

        const result = dragAdnDropUtils.reorder(category.categories, source.index, destination.index);

        const data = [...accordionData];
        data[sourceIndex].categories = [...result];

        setAccordionData([...data]);
      } else {
        // Competency was dropped to another Category
        const sourceIndex = getCategoryIndex(sourceId);
        const destIndex = getCategoryIndex(destinationId);

        const result = moveCompetency(getCategoryByID(sourceId), getCategoryByID(destinationId), source, destination);

        const data = [...accordionData];
        data[sourceIndex] = { ...result.source };
        data[destIndex] = { ...result.destination };

        setAccordionData([...data]);
      }
    }

    if (type === 'question') {
      const sourceId = dragAdnDropUtils.getIdByType(draggableId, 'question');
      const { sourceCategoryIndex, sourceCompetencyIndex } = getSourceIndexes(sourceId);
      const destinationId = dragAdnDropUtils.getIdByType(destination.droppableId, 'competency');
      const { destinationCategoryIndex, destinationCompetencyIndex } = getDestinationIndexes(destinationId);

      if (source.droppableId === destination.droppableId) {
        // Question was dropped to the same Competency
        const competency = { ...accordionData[sourceCategoryIndex].categories[sourceCompetencyIndex] };
        const result = dragAdnDropUtils.reorder(competency.competencyItems, source.index, destination.index);

        const data = [...accordionData];
        data[sourceCategoryIndex].categories[sourceCompetencyIndex] = {
          ...competency,
          competencyItems: [...result],
        };

        setAccordionData([...data]);
      } else if (
        accordionData[sourceCategoryIndex].competencyType &&
        accordionData[destinationCategoryIndex].competencyType
      ) {
        // Custom question was dropped to another custom Category/Competency
        const sourceCompetency = { ...accordionData[sourceCategoryIndex].categories[sourceCompetencyIndex] };
        const sourceCompetencyItems = [
          ...accordionData[sourceCategoryIndex].categories[sourceCompetencyIndex].competencyItems,
        ];
        const newSourceCompetencyItems = sourceCompetencyItems.filter((item) => item.itemID !== +sourceId);
        const movedItem = sourceCompetencyItems.find((item) => item.itemID === +sourceId);
        const destinationCompetency = {
          ...accordionData[destinationCategoryIndex].categories[destinationCompetencyIndex],
        };

        const newDestinationCompetencyItems = [
          ...destinationCompetency.competencyItems.slice(0, destination.index),
          movedItem,
          ...destinationCompetency.competencyItems.slice(destination.index),
        ];

        const data = [...accordionData];
        data[sourceCategoryIndex].categories[sourceCompetencyIndex] = {
          ...sourceCompetency,
          competencyItems: [...newSourceCompetencyItems],
        };
        data[destinationCategoryIndex].categories[destinationCompetencyIndex] = {
          ...destinationCompetency,
          competencyItems: [...newDestinationCompetencyItems],
        };

        setAccordionData([...data]);
      }
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="all-categories" type="category">
        {(provided) => (
          <AccordionWrapper {...provided.droppableProps} ref={provided.innerRef}>
            {accordionData.map((panel, index) => (
              <Draggable
                draggableId={`categoryDND${panel.competencyID}`}
                index={index}
                key={`categoryDND${panel.competencyID}`}
              >
                {(provided, snapshot) => (
                  <Panel {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                    <Droppable droppableId={`categoryDND${panel.competencyID}`} type="competency">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          <PanelTitleStyled
                            root
                            selectable
                            onClick={() => onPanelClick(panel.competencyID)}
                            draggable={snapshot.isDragging}
                          >
                            <TitleIconWrapper>
                              <TitleIcon opened={openedPanels.includes(panel.competencyID)} />
                            </TitleIconWrapper>
                            <AccordionTitleBody>
                              <AccordionTitleWrapper>
                                <AccordionTitle>
                                  {panel.name} {<span style={{ fontSize: '1rem' }}>{getLanguages(panel)}</span>}
                                </AccordionTitle>
                                {panel.competencyType ? <CustomBadge /> : <InbuiltBadge />}
                              </AccordionTitleWrapper>
                              <PanelDescription>{panel.description}</PanelDescription>
                            </AccordionTitleBody>
                            {!!panel.competencyType && (
                              <StyledActionsDropdown
                                smallSize
                                actions={panel.actions
                                  .filter((item) => (!panel.canEdit && item?.name === 'editCompetencyCategory') || true)
                                  .map((item) => {
                                    return {
                                      ...item,
                                      handler: () => item.handler && item.handler(panel),
                                    };
                                  })}
                                color="primary"
                              />
                            )}
                          </PanelTitleStyled>
                          <ChildWrapper isClosed={!openedPanels.includes(panel.competencyID)}>
                            {!!panel.categories.length &&
                              panel.categories.map((category, index) => (
                                <Draggable
                                  key={`competencyDND${category.competencyID}`}
                                  draggableId={`competencyDND${category.competencyID}`}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <PanelBody
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      draggable={snapshot.isDragging}
                                      height={openedPanels.includes(panel.competencyID) ? 'auto' : 1}
                                      isClosed={!openedPanels.includes(panel.competencyID)}
                                      key={category.competencyID}
                                    >
                                      <PanelTitle
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                        draggable={snapshot.isDragging}
                                        selectable
                                        pLeft={45}
                                        onClick={() => onPanelClick(category.competencyID)}
                                      >
                                        <TitleIconWrapper>
                                          <TitleIcon opened={openedPanels.includes(category.competencyID)} />
                                        </TitleIconWrapper>
                                        <CompetencyCategoryWrapper>
                                          <CategoryName>
                                            {category.name}{' '}
                                            {<span style={{ fontSize: '1rem' }}>{getLanguages(category)}</span>}
                                          </CategoryName>
                                          <CategoryDescription>{category.description}</CategoryDescription>
                                        </CompetencyCategoryWrapper>
                                        {!!panel.competencyType && (
                                          <StyledCompetencyActionsDropdown
                                            smallSize
                                            actions={category.actions
                                              .filter((item) =>
                                                // eslint-disable-next-line no-unneeded-ternary
                                                !category.canEdit && item?.name === 'editCompetencyItem' ? false : true,
                                              )
                                              .map((item) => {
                                                return {
                                                  ...item,
                                                  handler: () => item.handler && item.handler(category),
                                                };
                                              })}
                                            color="primary"
                                          />
                                        )}
                                      </PanelTitle>
                                      <Droppable droppableId={`competencyDND${category.competencyID}`} type="question">
                                        {(provided) => (
                                          <PanelBody
                                            {...provided.droppableProps}
                                            {...provided.dragHandleProps}
                                            opened={openedPanels.includes(category.competencyID)}
                                            height={openedPanels.includes(category.competencyID) ? 'auto' : 0}
                                            yOffset={0}
                                          >
                                            <div
                                              {...provided.droppableProps}
                                              {...provided.dragHandleProps}
                                              ref={provided.innerRef}
                                            >
                                              <AccordionNestedList panel={panel} category={category} />
                                              {provided.placeholder}
                                            </div>
                                          </PanelBody>
                                        )}
                                      </Droppable>
                                    </PanelBody>
                                  )}
                                </Draggable>
                              ))}
                          </ChildWrapper>
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Panel>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </AccordionWrapper>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const PanelTitleStyled = styled(PanelTitle)`
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background: ${(props) => props.theme.colors.grey1};

  ${(props) =>
    props.draggable &&
    css`
      border: 1px solid #e0e0e0 !important;
      border-top: 1px solid #e0e0e0 !important;
      background: ${(props) => props.theme.colors.grey1} !important;

      &:after {
        opacity: 1;
      }
    `}
`;

const PanelBody = styled(AnimateHeightWrapper)`
  height: 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey6};
  
  &:last-of-type {
    border-bottom: none;
  }
    
  & > div > ${PanelTitle}:last-child {
    border-bottom: none;
  }
 
  ${(props) => props.opened && `display: block;`}
  ${(props) => props.opened && `border-top: 1px solid ${props.theme.colors.grey6};`}
  
  ${(props) =>
    props.draggable &&
    css`
      & > div > ${PanelTitle} {
        border-bottom: 1px solid #40c4ff !important;
      }
    `}
}
`;

const ChildWrapper = styled.div`
  border: 1px solid #e0e0e0;
  border-top: 0;
  border-radius: 0 0 6px 6px;
  margin: 0 7px;

  ${(props) => props.isClosed && 'border: none; opacity: 0;'};
`;

const Panel = styled.div``;

const AccordionWrapper = styled.div`
  overflow: visible;
  width: 74.4rem;

  & > ${Panel} {
    margin-bottom: 10px;
  }
`;

const TitleIconWrapper = styled.span`
  position: absolute;
  top: 12px;
  left: 8px;
`;

const CategoryName = styled.span`
  margin-left: 0;
  margin-right: auto;
`;

const CategoryDescription = styled(CategoryName)`
  font-size: 10px;
`;

const TitleIconButton = styled.span`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};

  &:hover {
    background-color: ${(props) => props.theme.colors.lightBlue1};
  }
`;

const StyledActionsDropdown = styled(ActionsDropdown)`
  display: flex;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.white};
  position: absolute;
  right: 15px;
  top: 15px;
`;

const StyledCompetencyActionsDropdown = styled(StyledActionsDropdown)`
  right: 6px;
  top: 10px;
`;

const PanelDescription = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => props.theme.colors.darkBlue2};
  max-width: 65rem;
  margin-top: 8px;
`;

const AccordionTitleWrapper = styled.div`
  padding-right: 46px;
  display: flex;
  align-items: center;
`;

const AccordionTitle = styled.span`
  font-weight: 600;
  line-height: 17px;
  font-size: ${(props) => props.theme.fontSizes.normal};
  font-weight: 600;
  margin-left: 0;
  margin-right: auto;
`;

const AccordionTitleBody = styled.div`
  flex-grow: 1;
`;

const CompetencyCategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default AccordionDND;
