import React, { useState, useEffect } from 'react';
import { func } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { orderBy } from 'lodash';

import { I18n } from 'react-redux-i18n';
import Modal from '../reusable/NewModal';
import CustomSelect from '../reusable/Selects/Select';
import CustomButton from '../reusable/Buttons/Button';
import Spinner from '../reusable/Spinner';

import { addRespondentsToGroup } from '../../store/respondents/actions';
import { fetchAllGroups } from '../../store/groups/actions';

const AddToGroup = ({ onClose, onSuccess }) => {
  const [isLoading, setLoadingState] = useState(true);
  const groups = useSelector((state) =>
    state.groups.allGroups.map((item) => ({ ...item, value: item.groupID, label: item.name })),
  );

  const selectedRespondents = useSelector((state) => state.respondents.selectedRespondents);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchAllGroups(() => {
        setLoadingState(false);
      }),
    );
  }, []);

  const [selectValue, setSelectValue] = useState(null);
  const onModalClose = () => {
    onClose();
    setSelectValue(null);
  };

  const onSave = () => {
    setLoadingState(true);
    dispatch(
      addRespondentsToGroup(selectedRespondents, selectValue.groupID, () => {
        if (onSuccess) onSuccess();
        setLoadingState(false);
        onModalClose();
      }),
    );
  };

  const orderedGroups = orderBy(groups, 'groupID', 'desc');

  return (
    <StyledModal isVisible onClose={onModalClose} title={I18n.t('Add to group')}>
      <Spinner isLoading={isLoading} />
      <StyledBody>
        <CustomSelect
          options={orderedGroups}
          selectProps={{ value: selectValue, onChange: setSelectValue }}
          placeholder={selectValue ? I18n.t('Group name') : I18n.t('Add respondent(s) to group')}
          allowSearch
        />
      </StyledBody>
      <ButtonsWrapper>
        <StyledButton variant="secondary" onClick={onModalClose}>
          {I18n.t('Cancel')}
        </StyledButton>
        <StyledButton onClick={onSave} disabled={!selectValue}>
          {I18n.t('Save')}
        </StyledButton>
      </ButtonsWrapper>
    </StyledModal>
  );
};

AddToGroup.propTypes = {
  onClose: func.isRequired,
  onSuccess: func,
};

AddToGroup.defaultProps = {
  onSuccess: undefined,
};

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  min-height: 24rem;
`;

const StyledBody = styled.div`
  flex-grow: 1;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
`;

const StyledButton = styled(CustomButton)`
  min-width: 11rem;
  margin-left: 1rem;
`;

export default AddToGroup;
